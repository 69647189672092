import { useParams } from 'react-router-dom';

import { Page } from '../../../components/shared/page/Page';
import { MultipleChoiceAssessmentAttemptCard } from './MultipleChoiceAssessmentAttemptCard';
import { MultipleChoiceQuestionAttemptCard } from './MultipleChoiceQuestionAttemptCard';

function MultipleChoiceQuestionnairesPage() {
  const { subjectId } = useParams();

  return (
    <Page
      title={
        subjectId === 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737'
          ? 'Teoría'
          : subjectId === '63a0be75-65d1-4576-8925-191a546b3d34'
            ? 'Psicotécnicos'
            : subjectId === 'fad6092b-5a08-4542-bc9a-f3a118b99041'
              ? 'Inglés'
              : undefined
      }
    >
      <MultipleChoiceQuestionAttemptCard subjectId={subjectId!} />
      <MultipleChoiceAssessmentAttemptCard subjectId={subjectId!} />
    </Page>
  );
}

export { MultipleChoiceQuestionnairesPage };
