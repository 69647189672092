import { useState } from 'react';

import { IconButton, SlideDown } from '@sealfye/ui-components';
import {
  IoAddCircleOutline,
  IoOptions,
  IoOptionsOutline,
} from 'react-icons/io5';

import { Page } from '../../components/shared/page/Page';
import { DiagramEditor } from '../../features/diagrams/components/diagram-editor/DiagramEditor';
import {
  DiagramListFilter,
  DiagramListFilterForm,
} from '../../features/diagrams/components/diagram-list-filter/DiagramListFilter';
import { DiagramList } from '../../features/diagrams/components/diagram-list/DiagramList';

import styles from './DiagramListPage.module.scss';

function DiagramListPage() {
  const [filter, setFilter] = useState<DiagramListFilterForm>({
    fromTime: new Date('2020-01-01'),
    toTime: new Date(new Date().setHours(23, 59, 59, 999)),
    timeSort: 'createdAt',
    order: 'desc',
    subject: {
      id: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
      name: '',
    },
  });

  const [showFilters, setShowFilters] = useState(false);
  const [showNew, setShowNew] = useState(false);

  return (
    <Page className={styles['page']}>
      <div className={styles['header']}>
        <IconButton
          icon={<IoAddCircleOutline />}
          onClick={() => {
            setShowNew(true);
          }}
        />
        <IconButton
          icon={<IoOptionsOutline />}
          flipped={showFilters}
          flipIcon={<IoOptions />}
          onClick={() => {
            setShowFilters(!showFilters);
          }}
        />
      </div>
      <SlideDown className={styles['slide-down']}>
        {showFilters ? (
          <DiagramListFilter
            values={filter}
            onSubmit={(values) => {
              setFilter(values);
            }}
            onClear={() => {
              setFilter({
                fromTime: new Date('2020-01-01'),
                toTime: new Date(new Date().setHours(23, 59, 59, 999)),
                timeSort: 'createdAt',
                order: 'desc',
                subject: {
                  id: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
                  name: '',
                },
              });
            }}
          />
        ) : null}
      </SlideDown>
      <DiagramList filter={filter} />
      <DiagramEditor open={showNew} onClose={() => setShowNew(false)} />
    </Page>
  );
}

export { DiagramListPage };
