/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ActionDialogButtons,
  ActionDialogContent,
  Button,
  Dialog,
  DialogContent,
} from '@sealfye/ui-components';
import { IoArrowDownCircleOutline } from 'react-icons/io5';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { usePwa } from '../../../context/PwaContext';
import { BaseComponentProps } from '../../../types/base-component.types';

function PwaReloadPopup({
  className,
  testId = 'ui-pwa-reload-popup',
}: BaseComponentProps) {
  const { needUpdate, setNeedUpdate } = usePwa();

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(
      swScriptUrl: string,
      registration: ServiceWorkerRegistration | undefined,
    ) {
      console.log('Registered SW for scope:', registration?.scope);
    },
    onRegisterError(error: any) {
      console.error('SW registration error', error);
    },
  });

  const close = () => {
    setNeedRefresh(false);
  };

  return (
    <>
      <Dialog
        open={needUpdate && !needRefresh}
        openAnimation="bounceInUp"
        closeAnimation="bounceOutDown"
        testId={testId}
      >
        <DialogContent className={className}>
          <ActionDialogContent
            icon={<IoArrowDownCircleOutline />}
            title="Hay una nueva versión disponible, recarga la app para actualizar"
            subtitle="Actualiza la app para disfrutar de los últimos cambios, nueva funcionalidad, mejoras de rendimiento y correccion de errores."
          >
            <ActionDialogButtons>
              <Button
                variant="outline-premium"
                onClick={() => window.location.reload()}
              >
                Recargar
              </Button>
            </ActionDialogButtons>
          </ActionDialogContent>
        </DialogContent>
      </Dialog>
      <Dialog
        open={needRefresh}
        openAnimation="bounceInUp"
        closeAnimation="bounceOutDown"
      >
        <DialogContent className={className}>
          <ActionDialogContent
            icon={<IoArrowDownCircleOutline />}
            title="¡Nueva versión disponible!"
            subtitle="Actualiza la app para disfrutar de los últimos cambios, nueva funcionalidad, mejoras de rendimiento y correccion de errores."
          >
            <ActionDialogButtons>
              <Button
                variant="outline-premium"
                onClick={() => updateServiceWorker(true)}
              >
                Actualizar
              </Button>
            </ActionDialogButtons>
          </ActionDialogContent>
        </DialogContent>
      </Dialog>
    </>
  );
}

export { PwaReloadPopup };
