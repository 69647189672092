import { useEffect, useState } from 'react';

import { ImageViewer } from '@sealfye/ui-components';
import {
  FcAcceptDatabase,
  FcAddDatabase,
  FcDataBackup,
  FcDeleteDatabase,
} from 'react-icons/fc';

import { Loading } from '../../../../components/core/Loading';
import { AdminWidthResolution } from '../../../../constants/app';
import { useProfile } from '../../../../context/ProfileContext';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { convertToFileName } from '../../../../utils/files';
import { useGetDiagram } from '../../api/useDiagrams';
import { DiagramEditor } from '../diagram-editor/DiagramEditor';

export type ContainerProps = BaseComponentProps & {
  subjectId: string;
  diagramId: string;
  onClose: () => void;
};

function downloadImage(src: string, description: string) {
  const a = document.createElement('a');
  a.href = src;
  a.target = '_blank';
  a.download = `${convertToFileName(description)}.webp`;
  a.click();
}

function DiagramView({ subjectId, diagramId, onClose }: ContainerProps) {
  const { profile } = useProfile();
  const { isWidthGreaterThan } = useWindowSize();

  const [showEdit, setShowEdit] = useState(false);

  const {
    data: response,
    isLoading,
    isError,
  } = useGetDiagram({
    subjectId,
    id: diagramId,
  });

  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError]);

  if (isLoading || !response) {
    return <Loading />;
  }

  return (
    <>
      <ImageViewer
        id={response.id}
        heading={
          response.lesson?.name ?? response.unit?.name ?? response.subject.name
        }
        title={response.name}
        src={response.imageUrl}
        auditLog={
          profile.role === 'admin'
            ? response.auditLog.map((log) => ({
                icon:
                  log.action === 'created' ? (
                    <FcAddDatabase />
                  ) : log.action === 'updated' ? (
                    <FcDataBackup />
                  ) : log.action === 'deleted' ? (
                    <FcDeleteDatabase />
                  ) : (
                    <FcAcceptDatabase />
                  ),
                title:
                  log.action === 'created'
                    ? 'Esquema creado'
                    : log.action === 'updated'
                      ? 'Esquema actualizado'
                      : log.action === 'deleted'
                        ? 'Esquema eliminado'
                        : 'Esquema verificado',

                description: `Por ${log.username}`,
                date: new Date(log.date),
              }))
            : undefined
        }
        onClose={onClose}
        onEdit={
          profile.role === 'admin' && isWidthGreaterThan(AdminWidthResolution)
            ? () => {
                setShowEdit(true);
              }
            : undefined
        }
        onDownload={() => {
          downloadImage(response.downloadImageUrl, response.name);
        }}
      />
      {response && (
        <DiagramEditor
          open={showEdit}
          diagram={response}
          onClose={() => setShowEdit(false)}
        />
      )}
    </>
  );
}

export { DiagramView };
