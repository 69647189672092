import { useState } from 'react';

import { AssessmentAttemptResultPreview } from '@sealfye/ui-components';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Empty } from '../../../../components/core/Empty';
import { Loading } from '../../../../components/core/Loading';
import { OffsetPagination } from '../../../../components/shared/pagination/OffsetPagination';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { formatHourDate } from '../../../../utils/date';
import { useGetMultipleChoiceAssessmentAttempts } from '../../api/useMultipleChoiceAssessments';

import styles from './MultipleChoiceAssessmentAttemptList.module.scss';

type ContainerProps = BaseComponentProps & {
  subjectId: string;
};

function MultipleChoiceAssessmentAttemptList({
  className,
  subjectId,
  testId = 'ui-multiple-choice-assessment-attempt-list',
}: ContainerProps) {
  const navigate = useNavigate();

  const [dateRange] = useState<[Date, Date]>([
    new Date(new Date().setMonth(new Date().getMonth() - 12)),
    new Date(new Date().setHours(23, 59, 59, 999)),
  ]);
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 10 });

  const { data: response, isLoading } = useGetMultipleChoiceAssessmentAttempts({
    subjectId: subjectId,
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
    fromTime: dateRange[0],
    toTime: dateRange[1],
    timeSort: 'createdAt',
    order: 'desc',
    params: {
      statuses: ['submitted', 'overdue'],
    },
  });

  if (isLoading || !response) {
    return <Loading />;
  }

  if (response.totalItems === 0) {
    return <Empty title="No tienes simulacros completados" />;
  }

  return (
    <div className={classnames(styles['main'], className)} data-testid={testId}>
      {response.items.map((item) => (
        <AssessmentAttemptResultPreview
          className={styles['item']}
          key={item.id}
          id={item.id}
          title={item.name}
          score={item.score}
          elapsedTime={item.elapsedTime}
          description={`${item.questionAttemptsSummary.correct + item.questionAttemptsSummary.incorrect + item.questionAttemptsSummary.skipped} preguntas`}
          answerRate={{
            correct: item.questionAttemptsSummary.correct,
            incorrect: item.questionAttemptsSummary.incorrect,
            skipped: item.questionAttemptsSummary.skipped,
          }}
          submittedDate={formatHourDate(item.createdAt)}
          onClick={(id: string) => {
            navigate(`/materia/${subjectId}/simulacros/mc/resultados/${id}`);
          }}
        />
      ))}
      <OffsetPagination
        pageNumber={pagination.pageNumber}
        pageSize={pagination.pageSize}
        totalRecords={response.totalItems}
        onPageChange={(pageNumber: number) => {
          setPagination({ ...pagination, pageNumber });
        }}
        pageNeighbours={2}
      />
    </div>
  );
}

export { MultipleChoiceAssessmentAttemptList };
