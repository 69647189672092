import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import {
  ErrorResponse,
  PaginatedRequest,
  PaginatedResponse,
  ValidationErrorResponse,
} from '../../../types/api.types';
import {
  AuditViewModel,
  LessonViewModel,
  SubjectViewModel,
  TagViewModel,
  UnitViewModel,
  Visibility,
} from '../../questions/api/useMultipleChoiceQuestions';

export interface DiagramPreviewModel {
  id: string;
  name: string;
  subject: SubjectViewModel;
  unit?: UnitViewModel;
  lesson?: LessonViewModel;
  visibility: Visibility;
  createdAt: Date;
  updatedAt: Date;
}

export type GetDiagramsRequest = PaginatedRequest & {
  subjectId: string;
  params?: {
    text?: string;
    visibilities?: Visibility[];
    tagIds?: string[];
    unitId?: string;
    lessonId?: string;
  };
};

export const useGetDiagrams = (request: GetDiagramsRequest) => {
  const { instance } = useAxios();

  async function getDiagrams(): Promise<
    PaginatedResponse<DiagramPreviewModel>
  > {
    const response = await instance.get<PaginatedResponse<DiagramPreviewModel>>(
      `/v2/subjects/${request.subjectId}/diagrams`,
      {
        params: {
          fromTime: request.fromTime,
          toTime: request.toTime,
          pageNumber: request.pageNumber,
          pageSize: request.pageSize,
          timeSort: request.timeSort,
          order: request.order,
          text: request.params?.text,
          visibility: request.params?.visibilities,
          tagId: request.params?.tagIds,
          unitId: request.params?.unitId,
          lessonId: request.params?.lessonId,
        },
        paramsSerializer: {
          indexes: null,
        },
      },
    );

    return response.data;
  }

  return useQuery({
    queryKey: ['getDiagrams', request],
    queryFn: getDiagrams,
  });
};

export interface DiagramViewModel extends DiagramPreviewModel {
  imageUrl: string;
  downloadImageUrl: string;
  tags: TagViewModel[];
  auditLog: AuditViewModel[];
}

export type GetDiagramRequest = {
  subjectId: string;
  id: string;
};

export const useGetDiagram = (request: GetDiagramRequest) => {
  const { instance } = useAxios();

  async function getDiagram(): Promise<DiagramViewModel> {
    const response = await instance.get<DiagramViewModel>(
      `/v2/subjects/${request.subjectId}/diagrams/${request.id}`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: ['getDiagram', request.subjectId, request.id],
    queryFn: getDiagram,
  });
};

export interface CreateDiagramRequest {
  subjectId: string;
  unitId?: string;
  lessonId?: string;
  name: string;
  base64Image: string;
  visibility: Visibility;
  tagIds?: string[];
}

export interface CreateDiagramResponse {
  diagramId: string;
}

export const useCreateDiagram = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(
    request: CreateDiagramRequest,
  ): Promise<CreateDiagramResponse> {
    const response = await instance.post<CreateDiagramResponse>(
      `/v2/subjects/${request.subjectId}/diagrams`,
      request,
    );

    return response.data;
  }

  return useMutation<
    CreateDiagramResponse,
    AxiosError<ValidationErrorResponse>,
    CreateDiagramRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getDiagrams'],
      });
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'invalid_operation') {
        sendMessage(
          'Operación inválida',
          e.response?.data.message ?? '',
          'danger',
        );
      }
    },
  });
};

export interface UpdateDiagramRequest {
  id: string;
  subjectId: string;
  unitId?: string;
  lessonId?: string;
  name?: string;
  base64Image?: string;
  visibility?: Visibility;
  tagIds?: string[];
}

export const useUpdateDiagram = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(request: UpdateDiagramRequest) {
    const response = await instance.patch(
      `/v2/subjects/${request.subjectId}/diagrams/${request.id}`,
      request,
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    UpdateDiagramRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getDiagram'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getDiagrams'],
      });
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'invalid_operation') {
        sendMessage(
          'Operación inválida',
          e.response?.data.message ?? '',
          'danger',
        );
      }
    },
  });
};

export type DeleteDiagramRequest = {
  subjectId: string;
  id: string;
};

export const useDeleteDiagram = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();

  async function mutationFn(request: DeleteDiagramRequest) {
    const response = await instance.delete(
      `/v2/subjects/${request.subjectId}/diagrams/${request.id}`,
    );

    return response.data;
  }

  return useMutation<void, AxiosError<ErrorResponse>, DeleteDiagramRequest>({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getDiagram'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getDiagrams'],
      });
    },
  });
};
