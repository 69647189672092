import { useState } from 'react';

import {
  Account,
  ActionDialogButtons,
  ActionDialogContent,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SidebarMenuItem,
  ThemeToggleButton,
} from '@sealfye/ui-components';
import {
  IoInformationCircleOutline,
  IoLogOutOutline,
  IoMoonOutline,
  IoPersonCircleOutline,
  IoSettingsOutline,
} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../context/AuthContext';
import { useConfiguration } from '../../../context/ConfigurationContext';
import { useProfile } from '../../../context/ProfileContext';
import { useTheme } from '../../../context/ThemeContext';
import { useWindowSize } from '../../../hooks/useWindowSize';

import styles from './UserOptions.module.scss';

function UserOptions() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const windowSize = useWindowSize();
  const { profile } = useProfile();
  const { configuration } = useConfiguration();
  const { theme, setTheme } = useTheme();

  const [open, setOpen] = useState(false);
  const [logoutWarning, setLogoutWarning] = useState(false);

  async function logout() {
    auth.signOut();
    navigate('/');
  }

  return (
    <Popover
      placement="bottom-end"
      open={open}
      onOpenChange={setOpen}
      dismissOnAncestorScroll
    >
      <PopoverTrigger
        asChild={true}
        onClick={() => setOpen(true)}
        className={styles['trigger']}
      >
        <Avatar
          src={profile.avatarUrl ? profile.avatarUrl : undefined}
          size={windowSize.width > 576 ? 'sm' : 'xs'}
        />
      </PopoverTrigger>
      <PopoverContent className={styles['options']}>
        <Account
          className={styles['account']}
          username={profile.username ?? 'username'}
          email={profile.email ?? 'email'}
          src={profile.avatarUrl ? profile.avatarUrl : undefined}
          size="sm"
        />
        <SidebarMenuItem
          icon={<IoPersonCircleOutline />}
          onClick={() => {
            window.open(`${configuration.app?.pageUrl}/mi-cuenta`, '_blank');
          }}
        >
          Mi cuenta
        </SidebarMenuItem>
        <SidebarMenuItem
          icon={<IoSettingsOutline />}
          onClick={() => {
            window.open(
              `${configuration.app?.pageUrl}/mi-cuenta/ajustes`,
              '_blank',
            );
          }}
        >
          Ajustes
        </SidebarMenuItem>
        <Dialog open={logoutWarning} onOpenChange={setLogoutWarning}>
          <DialogTrigger asChild={true}>
            <SidebarMenuItem
              icon={<IoLogOutOutline />}
              onClick={() => setLogoutWarning((v) => !v)}
            >
              Cerrar sesión
            </SidebarMenuItem>
          </DialogTrigger>
          <DialogContent>
            <ActionDialogContent
              icon={<IoLogOutOutline />}
              title="¿Estás segurx de cerrar la sesión?"
            >
              <ActionDialogButtons>
                <Button
                  variant="outline-primary"
                  onClick={() => setLogoutWarning((v) => !v)}
                >
                  Cancelar
                </Button>
                <Button variant="danger" onClick={logout}>
                  Salir
                </Button>
              </ActionDialogButtons>
            </ActionDialogContent>
          </DialogContent>
        </Dialog>
        <SidebarMenuItem
          icon={<IoInformationCircleOutline />}
          onClick={() => {
            navigate('/info');
            setOpen(false);
          }}
        >
          Información
        </SidebarMenuItem>
        <SidebarMenuItem
          icon={<IoMoonOutline />}
          onClick={() => {
            setTheme(theme === 'dark' ? 'light' : 'dark');
          }}
          suffix={
            <div className={styles['suffix']}>
              <ThemeToggleButton
                className={styles['theme-toggle']}
                checked={theme === 'dark'}
                onToggle={() => {
                  setTheme(theme === 'dark' ? 'light' : 'dark');
                }}
              />
            </div>
          }
        >
          Modo oscuro
        </SidebarMenuItem>
        {profile.subscription?.isActive && profile.subscription?.isTrial && (
          <Button
            className={styles['button']}
            variant="outline-premium"
            onClick={() => {
              window.open(`${configuration.app?.pageUrl}/mi-cuenta`, '_blank');
            }}
          >
            Suscripción de prueba
          </Button>
        )}
        {!profile.subscription?.isActive && !profile.subscription?.isTrial && (
          <Button
            className={styles['button']}
            variant="premium"
            shadow={true}
            onClick={() => {
              window.open(
                `${configuration.app?.pageUrl}/mi-cuenta/premium`,
                '_blank',
              );
            }}
          >
            Hazte Premium
          </Button>
        )}
      </PopoverContent>
    </Popover>
  );
}

export { UserOptions };
