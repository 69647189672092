import { useEffect, useState } from 'react';

import {
  DifficultyLevel,
  MultipleChoiceAssessmentAnswers,
  MultipleChoiceQuestionAnswer,
  MultipleChoiceQuestionAnswerType,
} from '@sealfye/ui-components';
import classnames from 'classnames';
import {
  FcAcceptDatabase,
  FcAddDatabase,
  FcDataBackup,
  FcDeleteDatabase,
} from 'react-icons/fc';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

import { Loading } from '../../../../components/core/Loading';
import { ResourceDialog } from '../../../../components/shared/resource-dialog/ResourceDialog';
import { AdminWidthResolution } from '../../../../constants/app';
import { useProfile } from '../../../../context/ProfileContext';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { BaseComponentProps } from '../../../../types/base-component.types';
import {
  MultipleChoiceQuestionViewModel,
  useBookmarkMultipleChoiceQuestion,
  useCreateMultipleChoiceQuestionErrorReport,
  useUnbookmarkMultipleChoiceQuestion,
} from '../../../questions/api/useMultipleChoiceQuestions';
import { MultipleChoiceQuestionEditor } from '../../../questions/components/multiple-choice-question-editor/MultipleChoiceQuestionEditor';
import { ErrorReportForm } from '../../../reports/components/error-report-form/ErrorReportForm';
import {
  useGetMultipleChoiceAssessmentAttempt,
  useGetMultipleChoiceAssessmentMetrics,
} from '../../api/useMultipleChoiceAssessments';
import { MultipleChoiceAssessmentPreviewList } from '../multiple-choice-assessment-preview-list/MultipleChoiceAssessmentPreviewList';

import styles from './MultipleChoiceAssessmentAttemptResult.module.scss';

type ContainerProps = BaseComponentProps & {
  subjectId: string;
  assessmentAttemptId: string;
  onRetry?: (assessmentId: string) => void;
};

function MultipleChoiceAssessmentAttemptResult({
  className,
  subjectId,
  assessmentAttemptId,
  onRetry,
  testId = 'ui-multiple-choice-assessment-attempt-result',
}: ContainerProps) {
  const { profile } = useProfile();
  const { isWidthGreaterThan } = useWindowSize();

  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [questions, setQuestions] = useState<
    MultipleChoiceQuestionAnswerType[]
  >([]);
  const [question, setQuestion] = useState<MultipleChoiceQuestionAnswerType>(
    questions[index],
  );
  const [currentQuestion, setCurrentQuestion] =
    useState<MultipleChoiceQuestionViewModel>();
  const [showEdit, setShowEdit] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showAssessments, setShowAssessments] = useState(false);

  const { data: attemptResponse, isLoading: isAttemptLoading } =
    useGetMultipleChoiceAssessmentAttempt({
      subjectId,
      assessmentAttemptId,
    });

  const { data: metricsResponse, isLoading: isMetricsLoading } =
    useGetMultipleChoiceAssessmentMetrics(
      {
        subjectId,
        assessmentId: attemptResponse?.assessmentId as string,
      },
      attemptResponse?.assessmentId !== undefined,
    );

  const { mutateAsync: bookmarkMultipleChoiceQuestion } =
    useBookmarkMultipleChoiceQuestion();

  const { mutateAsync: unbookmarkMultipleChoiceQuestion } =
    useUnbookmarkMultipleChoiceQuestion();

  const { mutate: createMultipleChoiceQuestionErrorReport } =
    useCreateMultipleChoiceQuestionErrorReport();

  useEffect(() => {
    if (!attemptResponse) return;

    setQuestions(
      attemptResponse.questionAttempts.map((questionAttempt) => ({
        id: questionAttempt.question.id,
        text: questionAttempt.question.text,
        subject:
          questionAttempt.question.lesson?.name ??
          questionAttempt.question.unit?.name ??
          questionAttempt.question.subject.name,
        caption:
          questionAttempt.question.status === 'removed'
            ? '(Pregunta eliminada)'
            : undefined,
        imageUrl: questionAttempt.question.imageUrl,
        bookmarked: questionAttempt.question.bookmarked,
        tags: questionAttempt.question.tags.map((tag) => tag.name),
        difficulty:
          questionAttempt.question.difficultyLevel === 'easy'
            ? DifficultyLevel.EASY
            : questionAttempt.question.difficultyLevel === 'medium'
              ? DifficultyLevel.MEDIUM
              : DifficultyLevel.HARD,
        options: questionAttempt.question.choices.map((choice) => choice.text),
        selectedOptionIndex:
          questionAttempt.answer === ''
            ? 0
            : 1 +
              questionAttempt.question.choices.findIndex(
                (choice) => choice.id === questionAttempt.answer,
              ),
        correctOptionIndex:
          1 +
          questionAttempt.question.choices.findIndex(
            (choice) => choice.isCorrect,
          ),
        reason: questionAttempt.question.explanation,
        answerRate: questionAttempt.question.metrics && {
          correct: questionAttempt.question.metrics.correctPercentage,
          skipped: questionAttempt.question.metrics.skippedPercentage,
          incorrect: questionAttempt.question.metrics.incorrectPercentage,
        },
        auditLog:
          profile.role === 'admin'
            ? questionAttempt.question.auditLog.map((log) => ({
                icon:
                  log.action === 'created' ? (
                    <FcAddDatabase />
                  ) : log.action === 'updated' ? (
                    <FcDataBackup />
                  ) : log.action === 'deleted' ? (
                    <FcDeleteDatabase />
                  ) : (
                    <FcAcceptDatabase />
                  ),
                title:
                  log.action === 'created'
                    ? 'Pregunta creada'
                    : log.action === 'updated'
                      ? 'Pregunta actualizada'
                      : log.action === 'deleted'
                        ? 'Pregunta eliminada'
                        : 'Pregunta verificada',

                description: `Por ${log.username}`,
                date: new Date(log.date),
              }))
            : undefined,
        tip:
          questionAttempt.question.missesCount > 1 ? (
            <div className={styles['tooltip']}>
              Has fallado esta pregunta {questionAttempt.question.missesCount}{' '}
              veces
            </div>
          ) : undefined,
      })),
    );
  }, [attemptResponse]);

  useEffect(() => {
    setQuestion(questions[index]);
  }, [index]);

  useEffect(() => {
    setQuestion(questions[index]);
  }, [questions]);

  useEffect(() => {
    if (!question) return;
    if (!attemptResponse) return;

    setCurrentQuestion(
      attemptResponse.questionAttempts.find(
        (questionAttempt) => questionAttempt.question.id === question.id,
      )!.question,
    );
  }, [question, attemptResponse]);

  if (
    isAttemptLoading ||
    isMetricsLoading ||
    !attemptResponse ||
    !metricsResponse
  ) {
    return <Loading />;
  }

  return (
    <div className={classnames(styles['main'], className)} data-testid={testId}>
      <MultipleChoiceAssessmentAnswers
        className={styles['result']}
        description={attemptResponse.name}
        score={attemptResponse.score}
        elapsedTime={attemptResponse.elapsedTime}
        answerRate={{
          correct: attemptResponse.questionAttempts.filter(
            (questionAttempt) =>
              questionAttempt.answer ===
              questionAttempt.correction.correctAnswer,
          ).length,
          incorrect: attemptResponse.questionAttempts.filter(
            (questionAttempt) =>
              questionAttempt.answer !== '' &&
              questionAttempt.answer !==
                questionAttempt.correction.correctAnswer,
          ).length,
          skipped: attemptResponse.questionAttempts.filter(
            (questionAttempt) => questionAttempt.answer === '',
          ).length,
        }}
        questions={questions}
        official={attemptResponse.official}
        onSelect={(id: string) => {
          setIndex(questions.findIndex((q) => q.id === id));
          setIsOpen(true);
        }}
        onRetry={
          onRetry &&
          profile.subscription?.isActive &&
          !profile.subscription?.isTrial &&
          attemptResponse.status === 'published'
            ? () => {
                onRetry(attemptResponse.assessmentId);
              }
            : undefined
        }
        ranking={
          metricsResponse.scores.length > 1
            ? metricsResponse.scores.slice(0, 5).map((score) => ({
                score: score.grade,
                username: score.username ?? 'Desconocido',
                elapsedTime: score.elapsedTime,
              }))
            : undefined
        }
      />
      <ResourceDialog open={isOpen}>
        <MultipleChoiceQuestionAnswer
          question={question}
          onBookmark={
            profile.subscription?.isActive &&
            currentQuestion!.status !== 'removed'
              ? async (id) => {
                  await bookmarkMultipleChoiceQuestion({
                    subjectId,
                    questionId: id,
                  });
                }
              : undefined
          }
          onUnbookmark={
            profile.subscription?.isActive
              ? async (id) => {
                  await unbookmarkMultipleChoiceQuestion({
                    subjectId,
                    questionId: id,
                  });
                }
              : undefined
          }
          onFeedback={
            profile.subscription?.isActive &&
            !profile.subscription?.isTrial &&
            currentQuestion!.status !== 'removed'
              ? () => {
                  setShowFeedback(true);
                }
              : undefined
          }
          onEdit={
            profile.role === 'admin' &&
            currentQuestion!.status !== 'removed' &&
            isWidthGreaterThan(AdminWidthResolution)
              ? () => {
                  setShowEdit(true);
                }
              : undefined
          }
          onPush={
            profile.role === 'admin' &&
            currentQuestion!.status !== 'removed' &&
            currentQuestion!.status !== 'draft' &&
            isWidthGreaterThan(AdminWidthResolution)
              ? () => {
                  setShowAssessments(true);
                }
              : undefined
          }
          onClose={() => setIsOpen(false)}
        >
          <div className={styles['index']}>
            <IoChevronBackOutline
              style={{
                cursor: index > 0 ? 'pointer' : 'default',
                opacity: index > 0 ? 1 : 0.1,
              }}
              onClick={() => setIndex(index > 0 ? index - 1 : index)}
            />
            <span>{index + 1}</span>
            <IoChevronForwardOutline
              style={{
                cursor: index < questions.length - 1 ? 'pointer' : 'default',
                opacity: index < questions.length - 1 ? 1 : 0.1,
              }}
              onClick={() =>
                setIndex(index < questions.length - 1 ? index + 1 : index)
              }
            />
          </div>
        </MultipleChoiceQuestionAnswer>
        <MultipleChoiceQuestionEditor
          open={showEdit}
          question={currentQuestion!}
          onClose={() => setShowEdit(false)}
        />
        <MultipleChoiceAssessmentPreviewList
          open={showAssessments}
          question={currentQuestion!}
          onClose={() => setShowAssessments(false)}
        />
        <ErrorReportForm
          open={showFeedback}
          title="¿Has visto algún error en esta pregunta?"
          onFeedback={async (feedback) => {
            createMultipleChoiceQuestionErrorReport(
              {
                subjectId,
                questionId: question.id,
                message: feedback,
              },
              {
                onSuccess: () => {
                  setShowFeedback(false);
                },
              },
            );
          }}
          onClose={() => setShowFeedback(false)}
        />
      </ResourceDialog>
    </div>
  );
}

export { MultipleChoiceAssessmentAttemptResult };
