import { ComponentPropsWithoutRef, PropsWithChildren, forwardRef } from 'react';

import classnames from 'classnames';
import { Form } from 'formik';

import { BaseComponentProps } from '../../../../types/base-component.types';

import styles from './StepForm.module.scss';

type ContainerProps = ComponentPropsWithoutRef<'form'> & BaseComponentProps;

const StepForm = forwardRef<HTMLFormElement, PropsWithChildren<ContainerProps>>(
  function StepForm(
    { className, children, testId = 'ui-step-form', ...rest },
    ref,
  ) {
    return (
      <Form
        ref={ref}
        className={classnames(styles['form'], className)}
        data-testid={testId}
        {...rest}
      >
        {children}
      </Form>
    );
  },
);

export { StepForm };
