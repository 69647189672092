import { useState } from 'react';

import { Dialog, DialogContent, Step, Stepper } from '@sealfye/ui-components';
import classnames from 'classnames';
import {
  Formik,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import * as Yup from 'yup';

import { StepForm } from '../../../../components/shared/admin/layout/StepForm';
import { StepperHeader } from '../../../../components/shared/admin/layout/StepperHeader';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { FormEntity } from '../../../../types/form.types';
import { equal } from '../../../../utils/equality';
import {
  DifficultyLevel,
  MultipleChoiceQuestionViewModel,
  Status,
  Visibility,
  useCreateMultipleChoiceQuestion,
  useDeleteMultipleChoiceQuestion,
  useUpdateMultipleChoiceQuestion,
} from '../../api/useMultipleChoiceQuestions';
import { MultipleChoiceQuestionReviewStep } from './steps/MultipleChoiceQuestionReviewStep';
import { MultipleChoiceQuestionStep } from './steps/MultipleChoiceQuestionStep';

import styles from './MultipleChoiceQuestionEditor.module.scss';

export interface QuestionFormValues {
  id?: string;
  subject: FormEntity;
  unit?: FormEntity;
  lesson?: FormEntity;
  text: string;
  timeLimit: number;
  visibility: Visibility;
  difficultyLevel: DifficultyLevel;
  tags: FormEntity[];
  explanation?: string;
  base64Image?: string;
  status?: Status;
}

export interface MultipleChoiceQuestionFormValues extends QuestionFormValues {
  choices: {
    id?: string;
    text: string;
    isCorrect?: boolean;
  }[];
  maintainsChoiceOrder: boolean;
}

type ContainerProps = BaseComponentProps & {
  open?: boolean;
  question?: MultipleChoiceQuestionViewModel;
  onClose: () => void;
};

function MultipleChoiceQuestionEditor({
  className,
  open,
  question,
  onClose,
  testId = 'ui-multiple-choice-question-editor',
}: ContainerProps) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<Step[]>([
    { variant: 'active', label: 'Contenido' },
    { variant: 'disabled', label: 'Revisar y publicar' },
  ]);

  const { mutateAsync: createMultipleChoiceQuestion } =
    useCreateMultipleChoiceQuestion();

  const { mutateAsync: updateMultipleChoiceQuestion } =
    useUpdateMultipleChoiceQuestion();

  const { mutateAsync: deleteMultipleChoiceQuestion } =
    useDeleteMultipleChoiceQuestion();

  const onNext = () => {
    setActiveStep(activeStep + 1);
    setSteps(
      steps.map((step, index) => {
        if (index === activeStep) {
          return { ...step, variant: 'completed' };
        }

        if (index === activeStep + 1) {
          return { ...step, variant: 'active' };
        }

        return step;
      }),
    );
  };

  return (
    <Dialog open={open}>
      <DialogContent
        className={classnames(styles['main'], className)}
        data-testid={testId}
      >
        <div className={styles['edit']}>
          <StepperHeader>
            <Stepper
              className={styles['stepper']}
              activeStep={activeStep}
              showIcons={true}
              steps={steps}
              onClickStep={(index) => {
                setActiveStep(index);
                setSteps(
                  steps.map((step, stepIndex) => {
                    if (stepIndex === index) {
                      return { ...step, variant: 'active' };
                    }

                    if (stepIndex > index) {
                      return { ...step, variant: 'disabled' };
                    }

                    return step;
                  }),
                );
              }}
            />
          </StepperHeader>
          <Formik
            validate={async (values: MultipleChoiceQuestionFormValues) => {
              const errors: FormikErrors<MultipleChoiceQuestionFormValues> = {};

              const validationSchema = Yup.object().shape({
                subject: Yup.object().required('Selecciona una materia.'),
                unit: Yup.object().required('Selecciona una unidad.'),
                lesson: Yup.object().required('Selecciona una lección.'),
                text: Yup.string()
                  .min(10, 'La pregunta debe tener al menos 10 caracteres.')
                  .max(2000, 'La pregunta no debe superar los 2000 caracteres.')
                  .required('La pregunta no puede estar vacía.'),
                timeLimit: Yup.number()
                  .min(1, 'El tiempo límite debe ser mayor de cero.')
                  .max(
                    300,
                    'El tiempo límite no puede superar los 300 segundos.',
                  )
                  .required('El tiempo límite no puede estar vacío.'),
                choices: Yup.array()
                  .of(
                    Yup.object().shape({
                      text: Yup.string()
                        .min(1, 'La opción debe tener al menos 1 caracter.')
                        .max(
                          2000,
                          'La opción no debe superar los 2000 caracteres.',
                        )
                        .required('La opción no puede estar vacía.')
                        .test(
                          'is-unique',
                          'Las opciones deben ser únicas.',
                          function (value, context) {
                            const choices: {
                              text: string;
                            }[] = context.options.context?.choices;

                            const isRepeated = choices.some(
                              (choice, index) =>
                                choice.text === value &&
                                `choices[${index}].text` !== context.path,
                            );

                            return !isRepeated;
                          },
                        ),
                    }),
                  )
                  .test(
                    'have-correct',
                    'Selecciona una opción correcta.',
                    function (_value, context) {
                      const correctChoicesCount: number =
                        context.parent.choices.reduce(
                          (acc: number, choice: { isCorrect?: boolean }) =>
                            acc + (choice.isCorrect ? 1 : 0),
                          0,
                        );

                      if (correctChoicesCount < 1 || correctChoicesCount > 1) {
                        return false;
                      }

                      return true;
                    },
                  ),
                maintainsChoiceOrder: Yup.boolean().required(),
                difficultyLevel: Yup.string().oneOf(
                  ['easy', 'medium', 'hard'],
                  'Selecciona un nivel de dificultad válido.',
                ),
                tags: Yup.array().of(
                  Yup.object().shape({
                    id: Yup.string().required(),
                    name: Yup.string().required(),
                  }),
                ),
                explanation: Yup.string().max(
                  5000,
                  'La retroalimentación no debe superar los 5000 caracteres.',
                ),
                base64Image: Yup.string(),
                status: Yup.string().oneOf(
                  ['draft', 'published', 'archived'],
                  'Selecciona un estado válido.',
                ),
              });

              try {
                validateYupSchema<MultipleChoiceQuestionFormValues>(
                  values,
                  validationSchema,
                  true,
                );
              } catch (err) {
                return yupToFormErrors(err);
              }

              return errors;
            }}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              setSubmitting(true);

              if (question) {
                await updateMultipleChoiceQuestion(
                  {
                    id: question.id,
                    subjectId: values.subject.id,
                    unitId:
                      values.unit?.id !== question.unit?.id
                        ? values.unit?.id
                        : undefined,
                    lessonId:
                      values.lesson?.id !== question.lesson?.id
                        ? values.lesson?.id
                        : undefined,
                    text:
                      values.text !== question.text ? values.text : undefined,
                    timeLimit:
                      values.timeLimit !== question.timeLimit
                        ? values.timeLimit
                        : undefined,
                    choices:
                      values.choices.filter(
                        (choice) =>
                          choice.text !==
                            question.choices.find((c) => c.id === choice.id)
                              ?.text ||
                          choice.isCorrect !==
                            question.choices.find((c) => c.id === choice.id)
                              ?.isCorrect,
                      ).length > 0
                        ? values.choices
                            .filter(
                              (choice) =>
                                choice.text !==
                                  question.choices.find(
                                    (c) => c.id === choice.id,
                                  )?.text ||
                                choice.isCorrect !==
                                  question.choices.find(
                                    (c) => c.id === choice.id,
                                  )?.isCorrect,
                            )
                            .map((choice) => ({
                              id: choice.id ?? '',
                              text: choice.text,
                              isCorrect: choice.isCorrect,
                            }))
                        : undefined,
                    maintainsChoiceOrder:
                      values.maintainsChoiceOrder !==
                      question.maintainsChoiceOrder
                        ? values.maintainsChoiceOrder
                        : undefined,
                    visibility:
                      values.visibility !== question.visibility
                        ? values.visibility
                        : undefined,
                    difficultyLevel:
                      values.difficultyLevel !== question.difficultyLevel
                        ? values.difficultyLevel
                        : undefined,
                    tagIds: !equal(values.tags, question.tags)
                      ? values.tags.map((tag) => tag.id)
                      : undefined,
                    explanation:
                      values.explanation !== question.explanation
                        ? values.explanation
                        : undefined,
                    base64Image:
                      values.base64Image !== question.imageUrl
                        ? values.base64Image
                        : undefined,
                    status:
                      values.status !== question.status
                        ? values.status
                        : undefined,
                  },
                  {
                    onSuccess: () => {
                      onClose();
                      resetForm();
                      setActiveStep(0);
                    },
                  },
                );
                return;
              }

              await createMultipleChoiceQuestion(
                {
                  subjectId: values.subject.id,
                  unitId: values.unit?.id,
                  lessonId: values.lesson?.id,
                  text: values.text,
                  timeLimit: values.timeLimit,
                  choices: values.choices.map((choice) => ({
                    text: choice.text,
                    isCorrect: choice.isCorrect,
                  })),
                  maintainsChoiceOrder: values.maintainsChoiceOrder,
                  visibility: values.visibility,
                  difficultyLevel: values.difficultyLevel,
                  tagIds: values.tags.map((tag) => tag.id),
                  explanation: values.explanation,
                  base64Image: values.base64Image,
                  status: values.status,
                },
                {
                  onSuccess: () => {
                    onClose();
                    resetForm();
                    setActiveStep(0);
                  },
                },
              );

              setSubmitting(false);
            }}
            initialValues={{
              id: question?.id,
              subject: {
                id: question?.subject.id ?? '',
                name: question?.subject.name ?? '',
              },
              unit: question?.unit
                ? {
                    id: question.unit.id,
                    name: question.unit.name,
                  }
                : undefined,
              lesson: question?.lesson
                ? {
                    id: question.lesson.id,
                    name: question.lesson.name,
                  }
                : undefined,
              text: question?.text ?? '',
              timeLimit: question?.timeLimit ?? 30,
              visibility: question?.visibility ?? 'private',
              difficultyLevel: question?.difficultyLevel ?? 'medium',
              tags: question?.tags ?? [],
              choices: question?.choices.map((choice) => ({
                id: choice.id,
                text: choice.text,
                isCorrect: choice.isCorrect,
              })) ?? [
                {
                  text: '',
                  isCorrect: false,
                },
                {
                  text: '',
                  isCorrect: false,
                },
                {
                  text: '',
                  isCorrect: false,
                },
              ],
              maintainsChoiceOrder: question?.maintainsChoiceOrder ?? false,
              explanation: question?.explanation,
              base64Image: question?.imageUrl,
              status: question?.status ?? 'draft',
            }}
          >
            {({ resetForm, setSubmitting }) => (
              <StepForm>
                {activeStep === 0 && (
                  <MultipleChoiceQuestionStep
                    onNext={onNext}
                    onCancel={() => {
                      onClose();
                      resetForm();
                      setActiveStep(0);
                    }}
                    onDelete={async () => {
                      if (question) {
                        setSubmitting(true);

                        await deleteMultipleChoiceQuestion(
                          {
                            subjectId: question.subject.id,
                            id: question.id,
                          },
                          {
                            onSuccess: () => {
                              onClose();
                              resetForm();
                              setActiveStep(0);
                            },
                          },
                        );

                        setSubmitting(false);
                      }
                    }}
                  />
                )}

                {activeStep === 1 && (
                  <MultipleChoiceQuestionReviewStep
                    onCancel={() => {
                      onClose();
                      resetForm();
                      setActiveStep(0);
                    }}
                  />
                )}
              </StepForm>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export { MultipleChoiceQuestionEditor };
