import { useState } from 'react';

import {
  Dialog,
  DialogContent,
  IconButton,
  SortableList,
} from '@sealfye/ui-components';
import classnames from 'classnames';
import { IoReaderOutline } from 'react-icons/io5';

import { ResourceDialog } from '../../../../components/shared/resource-dialog/ResourceDialog';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { MultipleChoiceQuestionViewModel } from '../../../questions/api/useMultipleChoiceQuestions';
import { MultipleChoiceQuestionView } from '../../../questions/components/multiple-choice-question-view/MultipleChoiceQuestionView';
import { useUpdateMultipleChoiceAssessmentQuestion } from '../../api/useMultipleChoiceAssessments';

import styles from './MultipleChoiceAssessmentQuestionsPreviewList.module.scss';

type ContainerProps = BaseComponentProps & {
  subjectId: string;
  assessmentId: string;
  questions: MultipleChoiceQuestionViewModel[];
  open?: boolean;
  onClose: () => void;
};

function MultipleChoiceAssessmentQuestionsPreviewList({
  className,
  subjectId,
  assessmentId,
  questions,
  open,
  onClose,
  testId = 'ui-multiple-choice-assessment-questions-preview-list',
}: ContainerProps) {
  const [current, setCurrent] = useState<string>();

  const [items, setItems] = useState(
    questions.map((question) => ({
      id: question.id,
    })),
  );

  const { mutate: updateAssessmentQuestion } =
    useUpdateMultipleChoiceAssessmentQuestion();

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className={classnames(styles['card-popup'], className)}
        data-testid={testId}
      >
        <h4>Listado de preguntas</h4>
        <div className={styles['list']}>
          <SortableList
            className={styles['sortable-list']}
            items={items}
            onChange={(id, _from, to, newItems) => {
              setItems(newItems);
              updateAssessmentQuestion({
                subjectId,
                assessmentId,
                questionId: id.toString(),
                index: to,
              });
            }}
            renderItem={(item) => (
              <SortableList.Item
                className={styles['sortable-list__item']}
                id={item.id}
              >
                <div className={styles['sortable-item-content']}>
                  {questions.find((question) => question.id === item.id)?.text}
                  <IconButton
                    icon={<IoReaderOutline />}
                    size="sm"
                    onClick={() => {
                      setCurrent(item.id);
                    }}
                  />
                </div>
              </SortableList.Item>
            )}
          />
        </div>
        <ResourceDialog open={current !== undefined}>
          {current && (
            <MultipleChoiceQuestionView
              subjectId={subjectId}
              questionId={current}
              onClose={() => {
                setCurrent(undefined);
              }}
            />
          )}
        </ResourceDialog>
      </DialogContent>
    </Dialog>
  );
}

export { MultipleChoiceAssessmentQuestionsPreviewList };
