import { Button, Ranking } from '@sealfye/ui-components';
import classnames from 'classnames';

import { BaseComponentProps } from '../../../../types/base-component.types';
import { PlayerViewModel } from '../../../challenges/api/useChallenges';
import { ChallengeResults } from '../layout/ChallengeResults';

import styles from './Result.module.scss';

export type ContainerProps = BaseComponentProps & {
  players: PlayerViewModel[];
  onReady: () => void;
};

function Result({
  className,
  players,
  onReady,
  testId = 'ui-challenge-result',
}: ContainerProps) {
  return (
    <div
      className={classnames(styles['main'], className)}
      data-test-id={testId}
    >
      <ChallengeResults players={players} />
      <Ranking
        className={styles['ranking']}
        columns={['Usuario', 'Puntuación']}
        rows={players
          .sort((a, b) => (a.score < b.score ? 1 : -1))
          .map((item) => ({
            cells: [
              item.username.length > 20
                ? item.username.substring(0, 20) + '…'
                : item.username,
              item.score.toString(),
            ],
          }))}
      />
      <Button
        variant="outline-primary"
        className={styles['button']}
        onClick={onReady}
      >
        Volver al inicio
      </Button>
    </div>
  );
}

export { Result };
