import { useEffect } from 'react';

import {
  FormFeedback,
  FormGroup,
  FormLabel,
  FormRow,
  SelectDropdown,
} from '@sealfye/ui-components';
import { useFormikContext } from 'formik';

import { BaseComponentProps } from '../../../../types/base-component.types';
import { FormEntity } from '../../../../types/form.types';
import { LessonDto, UnitDto, useGetSubjects } from '../../api/useSubjects';

interface FormValues {
  subject: FormEntity;
  unit?: FormEntity;
  lesson?: FormEntity;
}

type ContainerProps = BaseComponentProps & {
  unitEnabled?: boolean;
  lessonEnabled?: boolean;
};

function SubjectsForm({
  unitEnabled = true,
  lessonEnabled = true,
}: ContainerProps) {
  const { handleChange, values, setValues, touched, errors, setFieldValue } =
    useFormikContext<FormValues>();

  const { data: response, isLoading } = useGetSubjects();

  useEffect(() => {
    if (response && values.subject.id === '') {
      setValues((prevValues) => ({
        ...prevValues,
        subject: {
          id: response.subjects[0].id,
          name: response.subjects[0].name,
        },
      }));
    }
  }, [response, values]);

  const index = (lessonEntity: FormEntity) => {
    const lessons: LessonDto[] =
      response?.subjects
        .find((subject) => subject.id === values.subject.id)
        ?.units?.flatMap((unit: UnitDto) => unit.lessons) || [];

    if (lessons) {
      const index = lessons.indexOf(
        lessons.find((lesson) => lesson.id === lessonEntity.id)!,
      );

      return `0${index + 1}`.slice(-2);
    }
  };

  return (
    <FormRow>
      <FormGroup>
        <FormLabel>Materia</FormLabel>
        <SelectDropdown
          disabled={isLoading}
          inputLabel="Selecciona una materia..."
          onSelect={(value) => {
            setFieldValue('unit', undefined, false);
            setFieldValue('lesson', undefined, false);

            handleChange({
              target: {
                name: 'subject',
                value: {
                  id: value,
                  name: response?.subjects.find(
                    (subject) => subject.id === value,
                  )?.name,
                },
              },
            });
          }}
          options={
            response?.subjects.map((subject) => ({
              value: subject.id,
              label: subject.name,
              selected: values.subject?.id === subject.id,
            })) || []
          }
          error={touched.subject && !!errors.subject}
        />
        {touched.subject &&
          errors.subject &&
          typeof errors.subject === 'string' && (
            <FormFeedback variant="danger">{errors.subject}</FormFeedback>
          )}
        <FormFeedback>La materia a la que pertenece.</FormFeedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Unidad</FormLabel>
        <SelectDropdown
          disabled={!values.subject.id || isLoading || !unitEnabled}
          inputLabel="Selecciona una unidad..."
          onSelect={(value) => {
            setFieldValue('lesson', undefined, false);

            handleChange({
              target: {
                name: 'unit',
                value: {
                  id: value,
                  name: response?.subjects
                    .find((subject) => subject.id === values.subject.id)
                    ?.units.find((unit) => unit.id === value)?.name,
                },
              },
            });
          }}
          options={
            response?.subjects
              .find((subject) => subject.id === values.subject.id)
              ?.units.map((unit) => ({
                value: unit.id,
                label: unit.name,
                selected: values.unit?.id === unit.id,
              })) || []
          }
          error={touched.subject && !!errors.unit}
        />
        {touched.subject && errors.unit && typeof errors.unit === 'string' && (
          <FormFeedback variant="danger">{errors.unit}</FormFeedback>
        )}
        <FormFeedback>La unidad a la que pertenece.</FormFeedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Lección</FormLabel>
        <SelectDropdown
          disabled={!values.unit?.id || isLoading || !lessonEnabled}
          inputLabel="Selecciona una lección..."
          onSelect={(value) => {
            handleChange({
              target: {
                name: 'lesson',
                value: {
                  id: value,
                  name: response?.subjects
                    .find((subject) => subject.id === values.subject.id)
                    ?.units.find((unit) => unit.id === values.unit?.id)
                    ?.lessons.find((lesson) => lesson.id === value)?.name,
                },
              },
            });
          }}
          options={
            response?.subjects
              .find((subject) => subject.id === values.subject.id)
              ?.units.find((unit) => unit.id === values.unit?.id)
              ?.lessons.map((lesson) => ({
                value: lesson.id,
                label: `${index(lesson)} - ${lesson.name}`,
                selected: values.lesson?.id === lesson.id,
              })) || []
          }
          error={touched.subject && !!errors.lesson}
        />
        {touched.subject &&
          errors.lesson &&
          typeof errors.lesson === 'string' && (
            <FormFeedback variant="danger">{errors.lesson}</FormFeedback>
          )}
        <FormFeedback>La lección a la que pertenece.</FormFeedback>
      </FormGroup>
    </FormRow>
  );
}

export { SubjectsForm };
