import { useState } from 'react';

import {
  ActionDialogButtons,
  ActionDialogContent,
  Badge,
  Button,
  Card,
  CopyToClipboard,
  Dialog,
  DialogContent,
  DialogTrigger,
  IconButton,
  List,
  ListSkeleton,
} from '@sealfye/ui-components';
import classnames from 'classnames';
import {
  IoCheckboxOutline,
  IoReaderOutline,
  IoTrashBinOutline,
} from 'react-icons/io5';

import { OffsetPagination } from '../../../../components/shared/pagination/OffsetPagination';
import { ResourceDialog } from '../../../../components/shared/resource-dialog/ResourceDialog';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { formatLongHourDate } from '../../../../utils/date';
import { MultipleChoiceQuestionView } from '../../../questions/components/multiple-choice-question-view/MultipleChoiceQuestionView';
import {
  ErrorReportViewModel,
  useGetErrorReports,
  useUpdateErrorReport,
} from '../../api/useErrorReports';
import { ErrorReportListFilterForm } from '../error-report-list-filter/ErrorReportListFilter';

import styles from './ErrorReportList.module.scss';

type ContainerProps = BaseComponentProps & {
  filter: ErrorReportListFilterForm;
};

function ErrorReportList({
  className,
  filter,
  testId = 'ui-error-report-list',
}: ContainerProps) {
  const [current, setCurrent] = useState<ErrorReportViewModel>();
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });

  const { data: response, isLoading } = useGetErrorReports({
    subjectId: filter.subject.id,
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
    timeSort: filter.timeSort,
    order: filter.order,
    params: {
      fromTime: filter.fromTime,
      toTime: filter.toTime,
      text: filter.text,
      statuses: filter.statuses,
    },
  });

  const { mutate: updateErrorReport } = useUpdateErrorReport();

  return (
    <>
      <Card
        title="Reportes"
        caption={
          response?.totalItems ? `Total: ${response?.totalItems}` : undefined
        }
        className={className}
        testId={testId}
      >
        {isLoading || !response ? (
          <ListSkeleton items={pagination.pageSize} />
        ) : (
          <>
            <List
              items={response.items.map((item) => ({
                text: item.message,
                info: `Reportado el ${formatLongHourDate(item.createdAt)}`,
                tag:
                  item.status === 'closed' ? (
                    <Badge bg="warning">Cerrado</Badge>
                  ) : item.status === 'resolved' ? (
                    <Badge bg="success">Resuelto</Badge>
                  ) : undefined,
                icon: (
                  <>
                    <CopyToClipboard label="Copiado">
                      {item.user.email}
                    </CopyToClipboard>
                    <IconButton
                      size="sm"
                      icon={<IoReaderOutline />}
                      onClick={() => {
                        setCurrent(item);
                      }}
                    />
                    {item.status == 'open' && (
                      <>
                        <Dialog>
                          <DialogTrigger asChild={true}>
                            <IconButton
                              size="sm"
                              icon={<IoCheckboxOutline />}
                              className={classnames(
                                styles['icon'],
                                styles['icon--success'],
                              )}
                            />
                          </DialogTrigger>
                          <DialogContent>
                            <ActionDialogContent
                              icon={<IoCheckboxOutline />}
                              title="¿Quieres marcar este reporte como resuelto?"
                            >
                              <ActionDialogButtons>
                                <Button
                                  variant="outline-primary"
                                  onClick={() => {
                                    updateErrorReport(
                                      {
                                        id: item.id,
                                        subjectId: item.subject.id,
                                        status: 'resolved',
                                      },
                                      {
                                        onSuccess: () => {
                                          document.dispatchEvent(
                                            new KeyboardEvent('keydown', {
                                              key: 'Escape',
                                              keyCode: 27,
                                              which: 27,
                                              code: 'Escape',
                                              bubbles: true,
                                            }),
                                          );
                                        },
                                      },
                                    );
                                  }}
                                >
                                  Marcar como resuelto
                                </Button>
                              </ActionDialogButtons>
                            </ActionDialogContent>
                          </DialogContent>
                        </Dialog>
                        <Dialog>
                          <DialogTrigger asChild={true}>
                            <IconButton
                              size="sm"
                              icon={<IoTrashBinOutline />}
                              className={classnames(
                                styles['icon'],
                                styles['icon--danger'],
                              )}
                            />
                          </DialogTrigger>
                          <DialogContent>
                            <ActionDialogContent
                              icon={<IoTrashBinOutline />}
                              title="¿Quieres marcar este reporte como falso positivo?"
                            >
                              <ActionDialogButtons>
                                <Button
                                  variant="outline-danger"
                                  onClick={() => {
                                    updateErrorReport(
                                      {
                                        id: item.id,
                                        subjectId: item.subject.id,
                                        status: 'closed',
                                      },
                                      {
                                        onSuccess: () => {
                                          document.dispatchEvent(
                                            new KeyboardEvent('keydown', {
                                              key: 'Escape',
                                              keyCode: 27,
                                              which: 27,
                                              code: 'Escape',
                                              bubbles: true,
                                            }),
                                          );
                                        },
                                      },
                                    );
                                  }}
                                >
                                  Cerrar reporte
                                </Button>
                              </ActionDialogButtons>
                            </ActionDialogContent>
                          </DialogContent>
                        </Dialog>
                      </>
                    )}
                  </>
                ),
              }))}
            />
            <OffsetPagination
              pageNumber={pagination.pageNumber}
              pageSize={pagination.pageSize}
              totalRecords={response.totalItems}
              onPageChange={(pageNumber: number) => {
                setPagination({ ...pagination, pageNumber });
              }}
              pageNeighbours={2}
            />
          </>
        )}
      </Card>
      <ResourceDialog open={current !== undefined}>
        {current && current.format === 'multiple_choice' && (
          <MultipleChoiceQuestionView
            subjectId={filter.subject.id}
            questionId={current.questionId}
            onClose={() => {
              setCurrent(undefined);
            }}
          />
        )}
      </ResourceDialog>
    </>
  );
}

export { ErrorReportList };
