import { useEffect, useState } from 'react';

import { Button, Card, Logo } from '@sealfye/ui-components';
import classnames from 'classnames';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import MainImg from '../../../assets/images/_b5589ff9-1210-4204-bb9e-a39ae20d0f44.png';
import { BackdropDialog } from '../../../components/shared/backdrop/BackdropDialog';
import { MarksRanking } from '../../../components/shared/marks-ranking/MarksRanking';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { BaseComponentProps } from '../../../types/base-component.types';
import { formatFromSeconds } from '../../../utils/date';
import {
  AssessmentAttemptPreviewModel,
  AssessmentViewModel,
  CreateMultipleChoiceAssessmentAttemptCommandResult,
  useCreateMultipleChoiceAssessmentAttempt,
  useGetMultipleChoiceAssessmentAttempts,
  useGetMultipleChoiceAssessmentMetrics,
  useGetMultipleChoiceAssessments,
} from '../../assessments/api/useMultipleChoiceAssessments';
import { MultipleChoiceAssessmentAttempt } from '../../assessments/components/multiple-choice-assessment-attempt/MultipleChoiceAssessmentAttempt';

import styles from './CompetitiveQuestionnaireCard.module.scss';

function CompetitiveQuestionnaireCard({ className }: BaseComponentProps) {
  const navigate = useNavigate();
  const { isWidthGreaterThan, isWidthLessThan } = useWindowSize();

  const [showAttempt, setShowAttempt] = useState(false);
  const [attempt, setAttempt] =
    useState<CreateMultipleChoiceAssessmentAttemptCommandResult>();

  const [currentAssessment, setCurrentAssessment] =
    useState<AssessmentViewModel<string>>();

  const [currentAssessmentAttempt, setCurrentAssessmentAttempt] =
    useState<AssessmentAttemptPreviewModel>();

  const { mutate: createMultipleChoiceAssessmentAttempt } =
    useCreateMultipleChoiceAssessmentAttempt();

  const [dates] = useState({
    fromTime: new Date(new Date().setMonth(new Date().getMonth() - 12)),
    toTime: new Date(),
  });

  const { data: response, isLoading } = useGetMultipleChoiceAssessments({
    subjectId: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
    pageNumber: 1,
    pageSize: 1,
    fromTime: dates.fromTime,
    toTime: dates.toTime,
    timeSort: 'releaseDate',
    order: 'desc',
    params: {
      perspectives: ['instructor'],
      statuses: ['published'],
      purposes: ['summative'],
      referenceStandards: ['norm-referenced'],
      methodologies: ['quantitative'],
      settings: ['standardized'],
      official: false,
    },
  });

  useEffect(() => {
    if (response?.items && response.items.length > 0) {
      setCurrentAssessment(response.items[0]);
    }
  }, [response]);

  const { data: attemptsResponse, isLoading: isAttemptsLoading } =
    useGetMultipleChoiceAssessmentAttempts(
      {
        subjectId: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
        pageNumber: 1,
        pageSize: 1,
        fromTime: dates.fromTime,
        toTime: dates.toTime,
        timeSort: 'createdAt',
        order: 'desc',
        params: {
          assessmentId: currentAssessment?.id,
          statuses: ['submitted', 'overdue'],
        },
      },
      currentAssessment?.id !== undefined,
    );

  useEffect(() => {
    if (attemptsResponse?.items && attemptsResponse.items.length > 0) {
      setCurrentAssessmentAttempt(attemptsResponse.items[0]);
    }
  }, [attemptsResponse]);

  const { data: metricsResponse, isLoading: isMetricsLoading } =
    useGetMultipleChoiceAssessmentMetrics(
      {
        subjectId: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
        assessmentId: currentAssessment?.id as string,
      },
      currentAssessment?.id !== undefined,
    );

  return (
    <Card
      className={classnames(styles['card'], className)}
      loading={isLoading || isAttemptsLoading || isMetricsLoading}
    >
      <div className={classnames(styles['main'])}>
        {!isAttemptsLoading && currentAssessmentAttempt ? (
          <>
            <div className={styles['description']}>
              <Logo className={styles['logo']} variant="icon" />
              <h1>{currentAssessmentAttempt.name}</h1>
              <div className={styles['description__title']}>
                <span>
                  Nota obtenida:{' '}
                  <strong>{currentAssessmentAttempt.score}</strong>
                </span>
                {metricsResponse?.position !== undefined &&
                  metricsResponse?.position !== 0 &&
                  metricsResponse?.total !== undefined &&
                  metricsResponse?.total !== 0 && (
                    <span>
                      Puesto: <strong>{metricsResponse?.position}</strong> de{' '}
                      <strong>{metricsResponse?.total}</strong>
                    </span>
                  )}
                <hr />
                <span>
                  Nota media: <strong>{metricsResponse?.averageGrade}</strong>
                </span>
                {metricsResponse && metricsResponse?.averageTime > 0 && (
                  <span>
                    Tiempo medio:{' '}
                    <strong>
                      {formatFromSeconds(
                        metricsResponse.averageTime > 3000
                          ? 3000
                          : metricsResponse.averageTime,
                      )}
                    </strong>
                  </span>
                )}
              </div>
            </div>
            {metricsResponse?.scores && metricsResponse?.scores.length > 0 && (
              <div className={styles['payload']}>
                <MarksRanking
                  loading={isMetricsLoading}
                  className={styles['marks-ranking']}
                  columns={['Usuario', 'Nota', 'Tiempo']}
                  rows={metricsResponse?.scores?.map((userScore) => {
                    return {
                      cells: [
                        userScore.username === undefined ||
                        userScore.username === '' ||
                        userScore.username === 'unknown'
                          ? 'Desconocido'
                          : userScore.username.length > 20
                            ? userScore.username.substring(0, 20) + '…'
                            : userScore.username,
                        userScore.grade.toString(),
                        formatFromSeconds(
                          userScore.elapsedTime > 3000
                            ? 3000
                            : userScore.elapsedTime,
                        ),
                      ],
                    };
                  })}
                />
              </div>
            )}
          </>
        ) : currentAssessment !== undefined ? (
          <>
            <div className={styles['description']}>
              <Logo className={styles['logo']} variant="icon" />
              <h1>{currentAssessment.name}</h1>
              <p className={styles['description__title']}>
                Realiza el simulacro y posiciónate en el ranking
              </p>
              {isWidthLessThan(768) && (
                <div className={styles['payload']}>
                  <img className={styles['image']} src={MainImg} />
                </div>
              )}
              <Button
                className={styles['button']}
                variant="primary"
                shadow={true}
                suffixIcon={<IoArrowForwardCircleOutline />}
                onClick={() => {
                  setShowAttempt(true);
                  createMultipleChoiceAssessmentAttempt(
                    {
                      subjectId: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
                      assessmentId: currentAssessment.id as string,
                    },
                    {
                      onSuccess: (response) => {
                        setAttempt(response);
                      },
                      onError: () => {
                        setShowAttempt(false);
                        setAttempt(undefined);
                      },
                    },
                  );
                }}
              >
                Empezar el simulacro
              </Button>
            </div>
            {isWidthGreaterThan(768) && (
              <div className={styles['payload']}>
                <img className={styles['image']} src={MainImg} />
              </div>
            )}
          </>
        ) : (
          <div />
        )}
      </div>
      <BackdropDialog open={showAttempt}>
        <MultipleChoiceAssessmentAttempt
          attempt={attempt}
          onFinish={() => {
            setShowAttempt(false);
            setAttempt(undefined);
            navigate(
              `/materia/cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737/simulacros/mc/resultados/${attempt!.id}`,
            );
          }}
          onClose={() => {
            setShowAttempt(false);
            setAttempt(undefined);
          }}
        />
      </BackdropDialog>
    </Card>
  );
}

export { CompetitiveQuestionnaireCard };
