import { ReactNode } from 'react';

import classnames from 'classnames';

import { BaseComponentProps } from '../../../types/base-component.types';
import { GetPremiumPopup } from '../popups/GetPremiumPopup';
import { Navbar } from './Navbar';
import { TabNavbar } from './TabNavbar';

import styles from './Layout.module.scss';

type ContainerProps = BaseComponentProps & {
  children: ReactNode;
};

function Layout({ className, children }: ContainerProps) {
  return (
    <div className={classnames(styles['wrapper'], className)}>
      <Navbar />
      <main className={styles['main']}>{children}</main>
      <TabNavbar />
      <GetPremiumPopup />
    </div>
  );
}

export { Layout };
