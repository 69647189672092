import { PropsWithChildren } from 'react';

import classnames from 'classnames';

import { BaseComponentProps } from '../../../../types/base-component.types';

import styles from './StepperHeader.module.scss';

function StepperHeader({
  className,
  children,
  testId = 'ui-stepper-header',
}: PropsWithChildren<BaseComponentProps>) {
  return (
    <div
      className={classnames(styles['header'], className)}
      data-testid={testId}
    >
      {children}
    </div>
  );
}

export { StepperHeader };
