import { Page } from '../components/shared/page/Page';
import { ChallengeCard } from '../features/home/challenges/ChallengeCard';
import { CompetitiveQuestionnaireCard } from '../features/home/competitive-questionnaire/CompetitiveQuestionnaireCard';

function HomePage() {
  return (
    <Page title="Inicio">
      <CompetitiveQuestionnaireCard />
      <ChallengeCard />
    </Page>
  );
}

export { HomePage };
