import { useQuery } from '@tanstack/react-query';

import { useAxios } from '../../../context/AxiosContext';

export interface GetSubscriptionStatusRequest {
  userId: string;
}

export interface SubscriptionStatusViewModel {
  id: string;
  status:
    | 'unknown'
    | 'expired'
    | 'incomplete'
    | 'active'
    | 'canceled'
    | 'uncollectible';
  creationDate: string;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  isTrial: boolean;
  cancelAtPeriodEnd: boolean;
  external: boolean;
}

export interface SubscriptionStatusViewModel {
  isActive: boolean;
  isTrial: boolean;
  subscriptions: SubscriptionStatusViewModel[];
}

export const useGetSubscriptionStatus = (
  request: GetSubscriptionStatusRequest,
  enabled: boolean,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<SubscriptionStatusViewModel> {
    const response = await instance.get<SubscriptionStatusViewModel>(
      `v2/subscriptions/status?userId=${request.userId}`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: ['getSubscriptionStatus', request.userId],
    queryFn,
    staleTime: 0,
    enabled,
  });
};
