/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
} from '@microsoft/signalr';
import {
  ActionDialogButtons,
  ActionDialogContent,
  Button,
  Dialog,
  DialogContent,
  Ranking,
} from '@sealfye/ui-components';
import { useMachine } from '@xstate/react';
import { assign, createMachine } from 'xstate';

import { useAuth } from '../../../../context/AuthContext';
import { useConfiguration } from '../../../../context/ConfigurationContext';
import { useToasterActions } from '../../../../state/toasterStore';
import { BaseComponentProps } from '../../../../types/base-component.types';
import {
  PlayerViewModel,
  SessionViewModel,
} from '../../../challenges/api/useChallenges';
import { CreateMultipleChoiceQuestionAttemptCommandResult } from '../../../questions/api/useMultipleChoiceQuestions';
import { MultipleChoiceQuestionChallengeAttempt } from '../../../questions/components/multiple-choice-question-challenge-attempt/MultipleChoiceQuestionChallengeAttempt';
import { Countdown } from '../layout/Countdown';
import { Presentation } from './Presentation';
import { Result } from './Result';
import { Searching } from './Searching';

import styles from './Game.module.scss';

const getTimeoutSeconds = () => {
  const now = new Date();
  const time = now.getHours() * 60 + now.getMinutes();

  if (time >= 8 * 60 + 30 && time < 24 * 60) {
    return Math.floor(Math.random() * 20) + 10;
  } else if (time >= 0 && time < 1 * 60 + 30) {
    return Math.floor(Math.random() * 40) + 20;
  }

  return Math.floor(Math.random() * 30) * 60;
};

export type ContainerProps = BaseComponentProps & {
  onQuit: () => void;
};

function Game({ onQuit }: ContainerProps) {
  const { user } = useAuth();
  const { configuration } = useConfiguration();
  const { sendMessage } = useToasterActions();

  const [connection, setConnection] = useState<HubConnection>();

  const [showDisconnectedPopup, setShowDisconnectedPopup] = useState(false);

  const [questionIndex, setQuestionIndex] = useState(1);
  const [session, setSession] = useState<SessionViewModel>();
  const [attempt, setAttempt] =
    useState<CreateMultipleChoiceQuestionAttemptCommandResult>();
  const [players, setPlayers] = useState<PlayerViewModel[]>();

  const [current, send] = useMachine(() =>
    createMachine(
      {
        predictableActionArguments: true,
        id: 'challenge',
        initial: 'searching',
        context: {
          retries: 0,
        },
        states: {
          searching: {
            on: {
              READY: 'success',
              REJECT: 'failure',
            },
          },
          success: {
            initial: 'presentation',
            states: {
              presentation: {
                on: {
                  PLAYERS_READY: { target: 'countdown' },
                },
              },
              countdown: {
                on: {
                  NEXT: { target: 'question' },
                },
              },
              question: {
                on: {
                  RESULT: { target: 'result' },
                  END: { target: 'end' },
                },
              },
              result: {
                on: {
                  NEXT: [
                    { target: 'question', cond: 'isNextQuestion' },
                    { target: 'end' },
                  ],
                  END: { target: 'end' },
                },
              },
              end: {
                type: 'final',
              },
            },
          },
          failure: {
            on: {
              RETRY: {
                target: 'searching',
                actions: assign({
                  retries: (context: any) => context.retries + 1,
                }),
              },
            },
          },
        },
      },
      {
        guards: {
          isNextQuestion: () => true,
        },
      },
    ),
  );

  useEffect(() => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${configuration.app?.apiUrl}/hubs/challenge`, {
        accessTokenFactory: async () => await user?.getIdToken(),
        withCredentials: false,
        transport: HttpTransportType.WebSockets,
      } as IHttpConnectionOptions)
      .withAutomaticReconnect()
      .build();

    hubConnection.serverTimeoutInMilliseconds = 100000; // 100 second

    hubConnection
      .start()
      .then(() => {
        if (
          hubConnection &&
          hubConnection.state == HubConnectionState.Connected
        ) {
          return hubConnection.send('JoinChallenge');
        }
      })
      .catch((error) => {
        if (
          error
            .toString()
            .includes('The connection was stopped during negotiation')
        ) {
          return;
        }

        sendMessage(
          '¡Ups!',
          'Parece que hay algún error con los desafíos, intentalo más tarde.',
          'danger',
        );
      });

    setConnection(hubConnection);

    return () => {
      hubConnection.stop();
    };
  }, [configuration.app?.apiUrl, sendMessage, user]);

  useEffect(() => {
    if (connection != null) {
      connection.on('ChallengeReady', (session: SessionViewModel) => {
        setSession(session);
        send('READY');
      });

      connection.on('WaitingForOpponent', (session: SessionViewModel) => {
        setSession(session);
      });

      connection.on('PlayersReady', () => {
        send('PLAYERS_READY');
      });

      connection.on(
        'ReceiveQuestionAttempt',
        (questionAttempt: CreateMultipleChoiceQuestionAttemptCommandResult) => {
          setQuestionIndex((prev) => prev + 1);
          setAttempt(questionAttempt);
          send('NEXT');
        },
      );

      connection.on('ChallengeStatus', (result: PlayerViewModel[]) => {
        setAttempt(undefined);
        setPlayers(result);
        send('RESULT');
      });

      connection.on('ChallengeFinished', (result: PlayerViewModel[]) => {
        setAttempt(undefined);
        setPlayers(result);
        send('END');
      });

      connection.on('OpponentDisconnected', () => {
        setShowDisconnectedPopup(true);
      });
    }
  }, [connection, send]);

  const onReadyForChallenge = async () => {
    if (connection && connection.state == HubConnectionState.Connected) {
      await connection.send('ReadyForChallenge', session?.id);
    }
  };

  const onGetCurrentQuestionAttempt = async () => {
    if (connection && connection.state == HubConnectionState.Connected) {
      await connection.send('GetCurrentQuestionAttempt', session?.id);
    }
  };

  const onAddQuestionAttempt = async (questionAttemptId: string) => {
    if (connection && connection.state == HubConnectionState.Connected) {
      await connection.send(
        'AddQuestionAttempt',
        session?.id,
        questionAttemptId,
      );
    }
  };

  const onTimeout = async () => {
    if (connection && connection.state == HubConnectionState.Connected) {
      await connection.send('SimulateChallenge', session?.id);
    }
  };

  return (
    <>
      <Dialog
        open={showDisconnectedPopup}
        onOpenChange={(open) => {
          if (!open) {
            setShowDisconnectedPopup(false);
          }
        }}
      >
        <DialogContent>
          <ActionDialogContent
            title="¡Reto interrumpido!"
            subtitle="Parece que tu oponente se ha desconectado..."
          >
            <ActionDialogButtons>
              <Button
                variant="outline-danger"
                onClick={() => {
                  setShowDisconnectedPopup(false);
                  onQuit();
                }}
              >
                Salir
              </Button>
            </ActionDialogButtons>
          </ActionDialogContent>
        </DialogContent>
      </Dialog>

      {current.matches('searching') && (
        <Searching
          seconds={getTimeoutSeconds()}
          onTimeout={onTimeout}
          onQuit={onQuit}
        />
      )}

      {current.matches('success.presentation') && session && (
        <Presentation session={session} onReady={onReadyForChallenge} />
      )}

      {current.matches('success.countdown') && session && (
        <Countdown
          index={questionIndex}
          seconds={3}
          onTimeout={() => {
            onGetCurrentQuestionAttempt();
          }}
        />
      )}

      {current.matches('success.question') && session && attempt && (
        <MultipleChoiceQuestionChallengeAttempt
          attempt={attempt}
          onNext={() => {
            onAddQuestionAttempt(attempt.id);
          }}
        />
      )}

      {current.matches('success.result') && players && (
        <>
          <Countdown
            index={questionIndex}
            seconds={3}
            onTimeout={() => {
              onGetCurrentQuestionAttempt();
            }}
          />
          <Ranking
            className={styles['ranking']}
            columns={['Jugador', 'Puntuación']}
            rows={players
              .sort((a, b) => (a.score < b.score ? 1 : -1))
              .map((item) => ({
                cells: [
                  item.username.length > 20
                    ? item.username.substring(0, 20) + '…'
                    : item.username,
                  item.score.toString(),
                ],
              }))}
          />
        </>
      )}

      {current.matches('success.end') && players && (
        <Result
          players={players}
          onReady={() => {
            onQuit();
          }}
        />
      )}
    </>
  );
}

export { Game };
