import { useEffect, useState } from 'react';

import { Button, ButtonGroup } from '@sealfye/ui-components';
import { IoClipboardOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import TestImg from '../../../assets/images/IA31.png';
import { BackdropDialog } from '../../../components/shared/backdrop/BackdropDialog';
import { SectionCard } from '../../../components/shared/section-card/SectionCard';
import {
  CreateMultipleChoiceAssessmentAttemptCommandResult,
  CreateRandomMultipleChoiceAssessmentAttemptRequest,
  useCreateRandomMultipleChoiceAssessmentAttempt,
} from '../../../features/assessments/api/useMultipleChoiceAssessments';
import { MultipleChoiceAssessmentAttempt } from '../../../features/assessments/components/multiple-choice-assessment-attempt/MultipleChoiceAssessmentAttempt';
import { DifficultyLevel } from '../../../features/questions/api/useMultipleChoiceQuestions';
import { SubjectSelectableList } from '../../../features/subjects/components/subject-selectable-list/SubjectSelectableList';
import { BaseComponentProps } from '../../../types/base-component.types';

type ContainerProps = BaseComponentProps & {
  subjectId: string;
};

function MultipleChoiceAssessmentAttemptCard({
  className,
  subjectId,
  testId,
}: ContainerProps) {
  const navigate = useNavigate();
  const [showLessons, setShowLessons] = useState(false);

  const [attempt, setAttempt] =
    useState<CreateMultipleChoiceAssessmentAttemptCommandResult>();
  const [attemptFilter, setAttemptFilter] = useState<{
    subjectId: string;
    numberOfQuestions?: number;
    numberOfChoices?: number;
    unitIds?: string[];
    lessonIds?: string[];
    difficultyLevels?: DifficultyLevel[];
  }>({
    subjectId: subjectId,
    numberOfQuestions: 15,
  });

  useEffect(() => {
    setAttemptFilter((prev) => ({
      ...prev,
      subjectId: subjectId,
      numberOfChoices:
        subjectId === 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737' ? 3 : 4,
    }));
  }, [subjectId]);

  const { mutate: createRandomMultipleChoiceAssessmentAttempt } =
    useCreateRandomMultipleChoiceAssessmentAttempt();

  const createAttempt = (
    request: CreateRandomMultipleChoiceAssessmentAttemptRequest,
  ) => {
    createRandomMultipleChoiceAssessmentAttempt(request, {
      onSuccess: (response) => {
        setAttempt(response);
      },
      onError: () => {
        setAttempt(undefined);
      },
    });
  };

  return (
    <>
      <SectionCard
        title="Simulacro"
        image={TestImg}
        button={
          <Button
            variant="outline-primary"
            onClick={() => {
              setShowLessons(true);
            }}
            suffixIcon={<IoClipboardOutline />}
          >
            Crear simulacro
          </Button>
        }
        className={className}
        testId={testId}
      >
        {subjectId === 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737' ? (
          <>
            <p>
              El temario para el examen oficial de teoría de Policía Nacional
              Escala Básica consta de 45 temas que se dividen en 3 partes:
              ciencias jurídicas, ciencias sociales y ciencias técnico
              científicas. La duración y el número de preguntas oficial es de
              100 preguntas a responder en un máximo de 50 minutos.
            </p>
            <p>
              Cada pregunta tiene tres alternativas de respuesta de las cuales
              solo una es correcta.
            </p>
          </>
        ) : subjectId === '63a0be75-65d1-4576-8925-191a546b3d34' ? (
          <>
            <p>
              Simulacro para el ejercicio número uno de la prueba de aptitudes
              psicotécnicas. En este simulacro de tipo ómnibus se evaluará la
              capacidad de razonamiento, capacidad de comprensión, la aptitud
              numérica, verbal y espacial.
            </p>
            <p>
              El número de preguntas totales y tiempo asignado al psicotécnico
              de policía nacional ha ido variando con cada edición. Cada
              pregunta tiene cuatro alternativas de respuesta de las cuales solo
              una es correcta.
            </p>
          </>
        ) : subjectId === 'fad6092b-5a08-4542-bc9a-f3a118b99041' ? (
          <>
            <p>
              Simulacro para la prueba de inglés, los aspirantes a Escala Básica
              deberán acreditar un nivel B1 de inglés. En este test se evaluará
              la comprensión de texto, relación de ideas entre textos, tiempos
              verbales, derivación de palabras, corrección de errores
              gramaticales.
            </p>
            <p>
              Cada pregunta tiene cuatro alternativas de respuesta de las cuales
              solo una es correcta.
            </p>
          </>
        ) : null}
        <p>
          Si seleccionas menos preguntas el tiempo se ajustará
          proporcionalmente.
        </p>
        <ButtonGroup
          key={subjectId}
          onSelect={(value) =>
            setAttemptFilter((prev) => ({
              ...prev,
              numberOfQuestions: +value,
            }))
          }
          options={
            subjectId === 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737'
              ? [
                  {
                    value: 15,
                    label: '15',
                    selected: attemptFilter.numberOfQuestions === 15,
                  },
                  {
                    value: 25,
                    label: '25',
                    selected: attemptFilter.numberOfQuestions === 25,
                  },
                  {
                    value: 50,
                    label: '50',
                    selected: attemptFilter.numberOfQuestions === 50,
                  },
                  {
                    value: 100,
                    label: '100',
                    selected: attemptFilter.numberOfQuestions === 100,
                  },
                ]
              : [
                  {
                    value: 15,
                    label: '15',
                    selected: attemptFilter.numberOfQuestions === 15,
                  },
                  {
                    value: 25,
                    label: '25',
                    selected: attemptFilter.numberOfQuestions === 25,
                  },
                  {
                    value: 50,
                    label: '50',
                    selected: attemptFilter.numberOfQuestions === 50,
                  },
                ]
          }
        />
      </SectionCard>
      <SubjectSelectableList
        open={showLessons}
        subjectId={subjectId}
        onSelect={(ids) => {
          setShowLessons(false);
          createAttempt({
            ...attemptFilter,
            lessonIds: ids.map((id) => id.toString()),
          });
        }}
        onClose={() => {
          setShowLessons(false);
        }}
      />
      <BackdropDialog open={attempt !== undefined}>
        <MultipleChoiceAssessmentAttempt
          attempt={attempt}
          onFinish={() => {
            navigate(
              `/materia/${subjectId}/simulacros/mc/resultados/${attempt!.id}`,
            );
          }}
          onClose={() => {
            setAttempt(undefined);
          }}
        />
      </BackdropDialog>
    </>
  );
}

export { MultipleChoiceAssessmentAttemptCard };
