import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import {
  PaginatedRequest,
  PaginatedResponse,
  ValidationErrorResponse,
} from '../../../types/api.types';
import { SubjectViewModel } from '../../questions/api/useMultipleChoiceQuestions';

export type Status = 'open' | 'closed' | 'resolved';

export type Format =
  | 'multiple_choice'
  | 'true_false'
  | 'likert'
  | 'answer'
  | 'fill_the_gap';

export interface UserViewModel {
  username: string;
  email: string;
}

export interface ErrorReportViewModel {
  id: string;
  createdAt: Date;
  subject: SubjectViewModel;
  questionId: string;
  format: Format;
  message: string;
  status: Status;
  user: UserViewModel;
}

export type GetErrorReportsRequest = PaginatedRequest & {
  subjectId: string;
  params?: {
    fromTime: Date;
    toTime: Date;
    text?: string;
    statuses?: Status[];
  };
};

export const useGetErrorReports = (request: GetErrorReportsRequest) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<PaginatedResponse<ErrorReportViewModel>> {
    const response = await instance.get<
      PaginatedResponse<ErrorReportViewModel>
    >(`/v2/subjects/${request.subjectId}/reports`, {
      params: {
        fromTime: request.params?.fromTime,
        toTime: request.params?.toTime,
        pageNumber: request.pageNumber,
        pageSize: request.pageSize,
        timeSort: request.timeSort,
        order: request.order,
        text: request.params?.text,
        status: request.params?.statuses,
      },
      paramsSerializer: {
        indexes: null,
      },
    });

    return response.data;
  }

  return useQuery({
    queryKey: ['getErrorReports', request],
    queryFn,
  });
};

export interface UpdateErrorReportRequest {
  id: string;
  subjectId: string;
  status: Status;
}

export const useUpdateErrorReport = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(request: UpdateErrorReportRequest) {
    const response = await instance.patch(
      `/v2/subjects/${request.subjectId}/reports/${request.id}`,
      {
        status: request.status,
      },
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    UpdateErrorReportRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getErrorReport'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getErrorReports'],
      });

      sendMessage('¡Hecho!', 'Reporte actualizado', 'success');
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'invalid_operation') {
        sendMessage(
          'Operación inválida',
          e.response?.data.message ?? '',
          'danger',
        );
      }
    },
  });
};
