import { useParams } from 'react-router-dom';

import { Page } from '../../../components/shared/page/Page';
import { SubjectResourcesList } from '../../../features/subjects/components/subject-resources-list/SubjectResourcesList';

function ResourcesPage() {
  const { subjectId } = useParams();

  return (
    <Page title="Normativa Escala Básica">
      <SubjectResourcesList subjectId={subjectId!} />
    </Page>
  );
}

export { ResourcesPage };
