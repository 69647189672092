import { useEffect, useMemo, useState } from 'react';

import {
  Dialog,
  DialogContent,
  RadioButton,
  SelectableList,
  SelectableListItem,
  SelectableListSkeleton,
} from '@sealfye/ui-components';
import classnames from 'classnames';

import { useProfile } from '../../../../context/ProfileContext';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { useGetSubject } from '../../api/useSubjects';
import { Footer } from './layout/Footer';
import { Header } from './layout/Header';

import styles from './SubjectSelectableList.module.scss';

export const colorPalette = [
  '#fabc99',
  '#9a8ea7',
  '#61abc3',
  '#8cc1b2',
  '#f7d88e',
  '#F1A66A',
  '#c37769',
  '#F26157',
  '#e5879e',
];

export type ContainerProps = BaseComponentProps & {
  open: boolean;
  subjectId: string;
  onSelect: (id: (string | number)[]) => void;
  onClose: () => void;
};

function SubjectSelectableList({
  className,
  open,
  subjectId,
  onSelect,
  onClose,
}: ContainerProps) {
  const { profile } = useProfile();

  const [blocks, setBlocks] = useState<
    {
      title: string;
      items: SelectableListItem[];
    }[]
  >([]);

  const { data: response, isLoading } = useGetSubject({
    subjectId,
  });

  useEffect(() => {
    if (response) {
      let count = 1;

      setBlocks(
        response?.units.map((unit, index) => ({
          title: unit.name,
          items: unit.lessons.map((lesson) => ({
            id: lesson.id,
            text: lesson.name,
            info: lesson.outline,
            active: true,
            disabled: !(
              profile.subscription?.isActive && !profile.subscription?.isTrial
            ),
            label: {
              value: count++,
              backgroundColor: colorPalette[index % colorPalette.length],
            },
          })),
        })),
      );
    }
  }, [response]);

  const handleSelect = (id: string | number, active: boolean) => {
    const array = [...blocks];
    array.forEach((block) => {
      block.items.forEach((lesson) => {
        if (lesson.id === id) {
          lesson.active = active;
        }
      });
    });

    setBlocks(array);
  };

  const handleSelectAll = (active: boolean) => {
    const array = [...blocks];
    array.forEach((block) => {
      block.items.forEach((lesson) => {
        lesson.active = active;
      });
    });
    setBlocks(array);
  };

  const memoList = useMemo(
    () =>
      blocks.map((block) => {
        return (
          <SelectableList
            className={styles['block']}
            key={block.title}
            title={block.title}
            items={block.items}
            onSelect={handleSelect}
          />
        );
      }),
    [blocks],
  );

  return (
    <Dialog
      open={open}
      openAnimation="bounceInUp"
      closeAnimation="bounceOutDown"
      onOpenChange={onClose}
    >
      <DialogContent className={classnames(styles['main'], className)}>
        <Header title="Selecciona los temas para las preguntas" />
        {isLoading ? (
          <SelectableListSkeleton items={45} className={styles['body']} />
        ) : (
          <div className={styles['body']}>
            {blocks.length > 0 && (
              <div className={styles['header']}>
                <span className={styles['header__title']}>Todos los temas</span>
                <RadioButton
                  className={styles['button']}
                  checked={
                    blocks
                      .map((block) => block.items)
                      .flat()
                      .find((item) => item.active === false)
                      ? false
                      : true
                  }
                  onChange={() => {
                    handleSelectAll(
                      blocks
                        .map((block) => block.items)
                        .flat()
                        .find((item) => item.active === true)
                        ? false
                        : true,
                    );
                  }}
                  disabled={
                    !(
                      profile.subscription?.isActive &&
                      !profile.subscription?.isTrial
                    )
                  }
                />
              </div>
            )}
            {memoList}
          </div>
        )}
        <Footer
          onClick={() => {
            onSelect(
              blocks
                .map((block) => block.items)
                .flat()
                .filter((item) => item.active === true)
                .map((item) => item.id),
            );
          }}
          loading={isLoading}
          disabled={
            blocks
              .map((block) => block.items)
              .flat()
              .find((item) => item.active === true)
              ? false
              : true
          }
        />
      </DialogContent>
    </Dialog>
  );
}

export { SubjectSelectableList };
