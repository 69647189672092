import { useEffect, useState } from 'react';

import {
  BarChart,
  BarChartSkeleton,
  Card,
  LineChart,
  SelectDropdown,
} from '@sealfye/ui-components';
import {
  IoAnalyticsOutline,
  IoClipboardOutline,
  IoFileTrayFullOutline,
  IoStatsChartOutline,
  IoTimerOutline,
} from 'react-icons/io5';

import { Page } from '../../components/shared/page/Page';
import { ValueCard } from '../../components/shared/value-card/ValueCard';
import { useProfile } from '../../context/ProfileContext';
import {
  AssessmentAttemptMetricsViewModel,
  useGetUserMetrics,
} from '../../features/users/api/useUsers';
import { formatSortDayDate, timeFromSeconds } from '../../utils/date';

import styles from './StatisticsPage.module.scss';

function StatisticsPage() {
  const { profile } = useProfile();

  const [selectedSubject, setSelectedSubject] = useState(
    'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
  );

  const [metrics, setMetrics] = useState<AssessmentAttemptMetricsViewModel[]>(
    [],
  );

  const { data: response, isLoading } = useGetUserMetrics({
    userId: profile!.id!,
    subjectId: selectedSubject,
  });

  const [, minutes, seconds] = timeFromSeconds(
    response?.questionAverageTime ?? 0,
  );

  useEffect(() => {
    if (response) {
      setMetrics(
        response.metrics.sort(
          (a, b) => a.submittedAt.getTime() - b.submittedAt.getTime(),
        ),
      );
    }
  }, [response]);

  return (
    <Page title="Progreso">
      <SelectDropdown
        className={styles['dropdown']}
        onSelect={(value) => setSelectedSubject(value as string)}
        options={[
          {
            value: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
            label: 'Teoría',
            selected: true,
          },
          {
            value: '63a0be75-65d1-4576-8925-191a546b3d34',
            label: 'Psicotécnicos',
          },
          {
            value: 'fad6092b-5a08-4542-bc9a-f3a118b99041',
            label: 'Inglés',
          },
        ]}
      />
      <div className={styles['row']}>
        <ValueCard
          className={styles['value-card']}
          icon={<IoFileTrayFullOutline />}
          title="Total simulacros"
          value={response?.total.toString() ?? ''}
          loading={isLoading}
        />
        <ValueCard
          className={styles['value-card']}
          icon={<IoClipboardOutline />}
          title="Nota media"
          value={response?.averageScore.toString() ?? ''}
          loading={isLoading}
        />
        <ValueCard
          className={styles['value-card']}
          icon={<IoTimerOutline />}
          title="Velocidad por pregunta"
          value={minutes ? `${minutes}m ${seconds}s` : `${seconds}s`}
          loading={isLoading}
        />
      </div>
      <div className={styles['row']}>
        <Card
          className={styles['chart-card']}
          icon={<IoAnalyticsOutline />}
          title="Resultados en simulacros"
          caption="Últimos 20 simulacros"
        >
          {isLoading ? (
            <BarChartSkeleton labels={2} />
          ) : (
            <LineChart
              className={styles['chart']}
              labels={metrics.map((metric) =>
                formatSortDayDate(new Date(metric.submittedAt)),
              )}
              dataset={[
                {
                  label: 'Calificación',
                  values: metrics.map((result) => result.grade),
                },
              ]}
            />
          )}
        </Card>
      </div>
      <div className={styles['row']}>
        <Card
          className={styles['chart-card']}
          icon={<IoStatsChartOutline />}
          title="Respuestas en simulacros"
          caption="Últimos 20 simulacros"
        >
          {isLoading && metrics.length > 0 ? (
            <BarChartSkeleton labels={2} />
          ) : (
            <BarChart
              className={styles['chart']}
              labels={metrics.map((metric) =>
                formatSortDayDate(new Date(metric.submittedAt)),
              )}
              dataset={[
                {
                  label: 'Correctas',
                  values: metrics.map((result) => result.correctAnswers),
                  color: '#50b06d',
                },
                {
                  label: 'Erróneas',
                  values: metrics.map((result) => result.incorrectAnswers),
                  color: '#e86767',
                },
                {
                  label: 'Sin contestar',
                  values: metrics.map(
                    (result) =>
                      result.totalQuestions -
                      (result.correctAnswers + result.incorrectAnswers),
                  ),
                  color: '#a8d8e9',
                },
              ]}
              stacked
            />
          )}
        </Card>
      </div>
    </Page>
  );
}

export { StatisticsPage };
