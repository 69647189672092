import classnames from 'classnames';

import { useProfile } from '../../../../context/ProfileContext';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { PlayerViewModel } from '../../../challenges/api/useChallenges';

import styles from './ChallengeResults.module.scss';

export type ContainerProps = BaseComponentProps & {
  players: PlayerViewModel[];
};

function ChallengeResults({
  className,
  players,
  testId = 'ui-challenge-result',
}: ContainerProps) {
  const { profile } = useProfile();

  return (
    <div
      className={classnames(styles['main'], className)}
      data-test-id={testId}
    >
      {players[0].score === players[1].score ? (
        <>
          <span className={styles['title']}>¡Ha estado reñido!</span>
          <span className={styles['subtitle']}>
            Habéis empatado y os lleváis
          </span>
          <span className={styles['award']}>+ 1 puntos</span>
        </>
      ) : players.sort((a, b) => (a.score < b.score ? 1 : -1))[0].username ===
        profile.username ? (
        <>
          <span className={styles['title']}>¡Enhorabuena!</span>
          <span className={styles['subtitle']}>Has ganado y te llevas</span>
          <span className={styles['award']}>+ 3 puntos</span>
        </>
      ) : (
        <>
          <span className={styles['title']}>Vaya...</span>
          <span className={styles['subtitle']}>
            Has perdido contra{' '}
            {players.sort((a, b) => (a.score < b.score ? 1 : -1))[0].username}
          </span>
          <span className={styles['award']}>+ 0 puntos</span>
        </>
      )}
    </div>
  );
}

export { ChallengeResults };
