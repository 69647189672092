import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { BackdropDialog } from '../../../../components/shared/backdrop/BackdropDialog';
import { Page } from '../../../../components/shared/page/Page';
import {
  CreateMultipleChoiceAssessmentAttemptCommandResult,
  useCreateMultipleChoiceAssessmentAttempt,
} from '../../../../features/assessments/api/useMultipleChoiceAssessments';
import { MultipleChoiceAssessmentAttemptResult } from '../../../../features/assessments/components/multiple-choice-assessment-attempt-result/MultipleChoiceAssessmentAttemptResult';
import { MultipleChoiceAssessmentAttempt } from '../../../../features/assessments/components/multiple-choice-assessment-attempt/MultipleChoiceAssessmentAttempt';

function MultipleChoiceAssessmentAttemptResultPage() {
  const { subjectId, id } = useParams();
  const navigate = useNavigate();

  const [showAttempt, setShowAttempt] = useState(false);
  const [attempt, setAttempt] =
    useState<CreateMultipleChoiceAssessmentAttemptCommandResult>();

  const { mutate: createMultipleChoiceAssessmentAttempt } =
    useCreateMultipleChoiceAssessmentAttempt();

  return (
    <Page>
      <MultipleChoiceAssessmentAttemptResult
        subjectId={subjectId!}
        assessmentAttemptId={id as string}
        onRetry={(assessmentId: string) => {
          setShowAttempt(true);
          createMultipleChoiceAssessmentAttempt(
            {
              subjectId: subjectId!,
              assessmentId: assessmentId,
            },
            {
              onSuccess: (response) => {
                setAttempt(response);
              },
              onError: () => {
                setShowAttempt(false);
                setAttempt(undefined);
              },
            },
          );
        }}
      />
      <BackdropDialog open={showAttempt}>
        <MultipleChoiceAssessmentAttempt
          attempt={attempt}
          onFinish={() => {
            setShowAttempt(false);
            setAttempt(undefined);
            navigate(
              `/materia/${subjectId}/simulacros/mc/resultados/${attempt!.id}`,
            );
          }}
          onClose={() => {
            setShowAttempt(false);
            setAttempt(undefined);
          }}
        />
      </BackdropDialog>
    </Page>
  );
}

export { MultipleChoiceAssessmentAttemptResultPage };
