import { PropsWithChildren } from 'react';

import { Dialog, DialogContent, DialogProps } from '@sealfye/ui-components';

import { BaseComponentProps } from '../../../types/base-component.types';

import styles from './BackdropDialog.module.scss';

export type ContainerProps = BaseComponentProps & {
  open: boolean;
  onClose: () => void;
};

function BackdropDialog(props: PropsWithChildren<DialogProps>) {
  return (
    <Dialog {...props}>
      <DialogContent className={styles['backdrop']}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
}

export { BackdropDialog };
