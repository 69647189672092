import {
  Button,
  DatePicker,
  FormFeedback,
  FormGroup,
  FormLabel,
  FormRow,
  MultiselectDropdown,
  SwitchCheck,
  Textarea,
} from '@sealfye/ui-components';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import { IoSearch } from 'react-icons/io5';
import * as Yup from 'yup';

import { BaseComponentProps } from '../../../../types/base-component.types';
import { FormEntity } from '../../../../types/form.types';
import { SubjectsForm } from '../../../subjects/components/subjects-form/SubjectsForm';
import { TagsForm } from '../../../tags/components/tags-form/TagsForm';
import {
  DifficultyLevel,
  Status,
  Visibility,
} from '../../api/useMultipleChoiceQuestions';

import styles from './QuestionListFilter.module.scss';

export interface QuestionListFilterForm {
  fromTime: Date;
  toTime: Date;
  timeSort: string;
  order: 'asc' | 'desc';
  subject: FormEntity;
  unit?: FormEntity;
  lesson?: FormEntity;
  text?: string;
  tags?: FormEntity[];
  statuses?: Status[];
  visibilities?: Visibility[];
  difficultyLevels?: DifficultyLevel[];
  selfCreated?: boolean;
}

type ContainerProps = BaseComponentProps & {
  values: QuestionListFilterForm;
  onSubmit: (values: QuestionListFilterForm) => void;
  onClear: () => void;
};

function QuestionListFilter({
  className,
  values,
  onSubmit,
  onClear,
  testId = 'ui-question-list-filter',
}: ContainerProps) {
  return (
    <Formik
      enableReinitialize
      validationSchema={Yup.object().shape({
        subject: Yup.object().required(),
        text: Yup.string().max(
          2000,
          'El texto no debe superar los 2000 caracteres.',
        ),
        tags: Yup.array().of(
          Yup.object().shape({
            id: Yup.string().required(),
            name: Yup.string().required(),
          }),
        ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        onSubmit(values);

        setSubmitting(false);
      }}
      initialValues={{
        fromTime: values.fromTime ?? new Date(),
        toTime: values.toTime ?? new Date(),
        timeSort: values.timeSort ?? 'createdAt',
        order: values.order ?? 'desc',
        subject: values.subject ?? {
          id: '',
          name: '',
        },
        unit: values.unit ?? undefined,
        lesson: values.lesson ?? undefined,
        text: values.text ?? undefined,
        tags: values.tags ?? [],
        statuses: values.statuses ?? undefined,
        visibilities: values.visibilities ?? undefined,
        difficultyLevels: values.difficultyLevels ?? undefined,
        selfCreated: values.selfCreated ?? false,
      }}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit,
      }) => (
        <Form
          className={classnames(styles['form'], className)}
          noValidate
          onSubmit={handleSubmit}
          data-testid={testId}
        >
          <FormRow>
            <FormGroup>
              <FormLabel
                info={
                  <>
                    <p>
                      El contenido de este campo puede ser cualquier texto que
                      pueda contener la pregunta.
                    </p>
                    <p>Ejemplos:</p>
                    <ul>
                      <li>Cualquier campo con valor de tipo texto.</li>
                      <li>
                        Ids (de tipo GUID) que pueda contener la pregunta.
                      </li>
                    </ul>
                    <p>
                      Ten en cuenta que estas búsquedas son sensibles a
                      caracteres especiales como las tildes.
                    </p>
                  </>
                }
              >
                Texto libre
              </FormLabel>
              <Textarea
                placeholder="Escribe cualquier texto que pueda contener la pregunta..."
                id="text"
                value={values.text ?? ''}
                onChange={handleChange}
                rows={2}
                error={touched.text && !!errors.text}
              />
              {touched.text && errors.text && (
                <FormFeedback variant="danger">{errors.text}</FormFeedback>
              )}
              <FormFeedback>Texto que puede contener la pregunta.</FormFeedback>
            </FormGroup>
            <TagsForm />
            <FormGroup>
              <FormLabel>Fecha de creación</FormLabel>
              <DatePicker
                className={styles['date-picker']}
                startDate={values.fromTime}
                endDate={values.toTime}
                onChange={(fromTime, toTime) => {
                  handleChange({
                    target: {
                      name: 'fromTime',
                      value: fromTime,
                    },
                  });

                  handleChange({
                    target: {
                      name: 'toTime',
                      value: toTime,
                    },
                  });
                }}
                size="lg"
                shortcuts={[
                  'today',
                  'yesterday',
                  'lastSevenDays',
                  'monthToDate',
                  'yearToDate',
                ]}
                locale="es"
                labels={{
                  today: 'Hoy',
                  yesterday: 'Ayer',
                  lastSevenDays: 'Últimos 7 días',
                  monthToDate: 'Mes en curso',
                  monthFromDate: 'Fin de mes en curso',
                  nextMonthFromDate: 'Siguiente mes',
                  yearToDate: 'Año en curso',
                  yearFromDate: 'Fin de año en curso',
                  nextYearFromDate: 'Siguiente año',
                  clear: 'Limpiar',
                  submit: 'Aceptar',
                }}
              />
              <FormFeedback>Fecha en la que se creó la pregunta.</FormFeedback>
            </FormGroup>
          </FormRow>
          <SubjectsForm />

          <FormRow>
            <FormGroup>
              <FormLabel
                info={
                  <>
                    <p>
                      El estado de la pregunta se refiere a la situación actual
                      de la pregunta.
                    </p>
                    <p>Tipos:</p>
                    <ul>
                      <li>
                        El estado <strong>borrador</strong> indica que la
                        pregunta aún no ha sido publicada.
                      </li>
                      <li>
                        El estado <strong>publicado</strong> indica que la
                        pregunta ha sido publicada y es visible para los
                        participantes.
                      </li>
                      <li>
                        El estado <strong>eliminado</strong> indica que la
                        pregunta ha sido eliminada y no es visible para los
                        participantes.
                      </li>
                    </ul>
                  </>
                }
              >
                Estado de la pregunta
              </FormLabel>
              <MultiselectDropdown
                inputLabel="Selecciona uno o varios..."
                onSelect={(values) => {
                  handleChange({
                    target: {
                      name: 'statuses',
                      value: values,
                    },
                  });
                }}
                options={[
                  {
                    value: 'draft',
                    label: 'Borrador',
                    selected: values.statuses?.includes('draft'),
                  },
                  {
                    value: 'published',
                    label: 'Publicada',
                    selected: values.statuses?.includes('published'),
                  },
                  {
                    value: 'removed',
                    label: 'Eliminada',
                    selected: values.statuses?.includes('removed'),
                  },
                ]}
              />
              <FormFeedback></FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel info="El nivel de dificultad se refiere a la complejidad de la pregunta. Es independiente de la tasa de aciertos y fallos.">
                Nivel de dificultad
              </FormLabel>
              <MultiselectDropdown
                inputLabel="Selecciona uno o varios..."
                onSelect={(values) => {
                  handleChange({
                    target: {
                      name: 'difficultyLevels',
                      value: values,
                    },
                  });
                }}
                options={[
                  {
                    value: 'easy',
                    label: 'Fácil',
                    selected: values.difficultyLevels?.includes('easy'),
                  },
                  {
                    value: 'medium',
                    label: 'Medio',
                    selected: values.difficultyLevels?.includes('medium'),
                  },
                  {
                    value: 'hard',
                    label: 'Difícil',
                    selected: values.difficultyLevels?.includes('hard'),
                  },
                ]}
              />
              <FormFeedback></FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel
                info={
                  <>
                    <p>
                      La visibilidad de la pregunta se refiere a quién puede ver
                      la pregunta.
                    </p>
                    <p>Tipos:</p>
                    <ul>
                      <li>
                        La visibilidad <strong>pública</strong> indica que la
                        pregunta es visible para todos los usuarios, tanto los
                        Premium como los gratuitos.
                      </li>
                      <li>
                        La visibilidad <strong>privada</strong> indica que la
                        pregunta es visible solo para los usuarios Premium.
                      </li>
                    </ul>
                  </>
                }
              >
                Visibilidad de la pregunta
              </FormLabel>
              <MultiselectDropdown
                inputLabel="Selecciona uno o varios..."
                onSelect={(values) => {
                  handleChange({
                    target: {
                      name: 'visibilities',
                      value: values,
                    },
                  });
                }}
                options={[
                  {
                    value: 'public',
                    label: 'Pública',
                    selected: values.visibilities?.includes('public'),
                  },
                  {
                    value: 'private',
                    label: 'Privada',
                    selected: values.visibilities?.includes('private'),
                  },
                ]}
              />
              <FormFeedback></FormFeedback>
            </FormGroup>
          </FormRow>

          <FormGroup>
            <FormLabel>Autor de la pregunta</FormLabel>
            <SwitchCheck
              label="Creadas por mi"
              checked={values.selfCreated}
              onChange={() => {
                handleChange({
                  target: {
                    name: 'selfCreated',
                    value: !values.selfCreated,
                  },
                });
              }}
            />
            {touched.selfCreated && errors.selfCreated && (
              <FormFeedback variant="danger">{errors.selfCreated}</FormFeedback>
            )}
          </FormGroup>

          <div className={styles['footer']}>
            <Button
              className={styles['button']}
              variant="outline-primary"
              onClick={onClear}
            >
              Limpiar
            </Button>
            <Button
              className={styles['button']}
              loading={isSubmitting}
              disabled={isSubmitting}
              variant="primary"
              type="submit"
              suffixIcon={<IoSearch />}
            >
              Buscar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export { QuestionListFilter };
