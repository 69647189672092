import { useParams } from 'react-router-dom';

import { Page } from '../../../components/shared/page/Page';
import { MultipleChoiceMissedQuestionsList } from '../../../features/questions/components/multiple-choice-missed-questions-list/MultipleChoiceMissedQuestionsList';

function MultipleChoiceMissedQuestionsPage() {
  const { subjectId } = useParams();

  return (
    <Page title="Mis preguntas falladas">
      <MultipleChoiceMissedQuestionsList subjectId={subjectId!} />
    </Page>
  );
}

export { MultipleChoiceMissedQuestionsPage };
