import { useParams } from 'react-router-dom';

import { Page } from '../../../../components/shared/page/Page';
import { MultipleChoiceAssessmentAttemptList } from '../../../../features/assessments/components/multiple-choice-assessment-attempt-list/MultipleChoiceAssessmentAttemptList';

function MultipleChoiceAssessmentAttemptListPage() {
  const { subjectId } = useParams();

  return (
    <Page>
      <MultipleChoiceAssessmentAttemptList subjectId={subjectId!} />
    </Page>
  );
}

export { MultipleChoiceAssessmentAttemptListPage };
