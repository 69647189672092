import { useEffect, useState } from 'react';

import { Card, IconButton, List, ListSkeleton } from '@sealfye/ui-components';
import { IoEaselOutline } from 'react-icons/io5';

import { OffsetPagination } from '../../../../components/shared/pagination/OffsetPagination';
import { ResourceDialog } from '../../../../components/shared/resource-dialog/ResourceDialog';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { useGetDiagrams } from '../../api/useDiagrams';
import { DiagramListFilterForm } from '../diagram-list-filter/DiagramListFilter';
import { DiagramView } from '../diagram-view/DiagramView';

import styles from './DiagramList.module.scss';

type ContainerProps = BaseComponentProps & {
  filter: DiagramListFilterForm;
};

function DiagramList({
  className,
  filter,
  testId = 'ui-diagram-list',
}: ContainerProps) {
  const [current, setCurrent] = useState<string>();
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });

  const { data: response, isLoading } = useGetDiagrams({
    subjectId: filter.subject.id,
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
    fromTime: filter.fromTime,
    toTime: filter.toTime,
    timeSort: filter.timeSort,
    order: filter.order,
    params: {
      unitId: filter.unit?.id,
      lessonId: filter.lesson?.id,
      tagIds: filter.tags?.map((tag) => tag.id),
      text: filter.text,
      visibilities: filter.visibilities,
    },
  });

  useEffect(() => {
    setPagination({ ...pagination, pageNumber: 1 });
  }, [filter]);

  return (
    <>
      <Card
        title="Esquemas"
        caption={
          response?.totalItems ? `Total: ${response?.totalItems}` : undefined
        }
        className={className}
        testId={testId}
      >
        {isLoading || !response ? (
          <ListSkeleton items={pagination.pageSize} />
        ) : (
          <>
            <List
              items={response.items.map((item) => ({
                text:
                  item.name.length > 120
                    ? item.name.substring(0, 120) + '…'
                    : item.name,
                info: item.name,
                icon: (
                  <IconButton
                    size="sm"
                    icon={<IoEaselOutline />}
                    onClick={() => {
                      setCurrent(item.id);
                    }}
                  />
                ),
              }))}
            />
            <OffsetPagination
              pageNumber={pagination.pageNumber}
              pageSize={pagination.pageSize}
              totalRecords={response.totalItems}
              onPageChange={(pageNumber: number) => {
                setPagination({ ...pagination, pageNumber });
              }}
              pageNeighbours={2}
            />
          </>
        )}
      </Card>
      <ResourceDialog
        open={current !== undefined}
        className={styles['image-viewer']}
      >
        {current && (
          <DiagramView
            subjectId={filter.subject.id}
            diagramId={current}
            onClose={() => {
              setCurrent(undefined);
            }}
          />
        )}
      </ResourceDialog>
    </>
  );
}

export { DiagramList };
