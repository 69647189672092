import { PlayerCard as PlayerCardComponent } from '@sealfye/ui-components';
import classnames from 'classnames';

import { BaseComponentProps } from '../../../../types/base-component.types';
import { PlayerViewModel } from '../../../challenges/api/useChallenges';

import styles from './PlayerCard.module.scss';

export type ContainerProps = BaseComponentProps & {
  active?: boolean;
  player: PlayerViewModel;
};

function PlayerCard({
  className,
  active,
  player,
  testId = 'ui-challenge-player-card',
}: ContainerProps) {
  return (
    <PlayerCardComponent
      className={classnames(styles['main'], className)}
      testId={testId}
      username={player.username}
      points={player.credits ?? 0}
      type={
        (player.credits ?? 0) > 1000
          ? 'gold'
          : (player.credits ?? 0) > 500
            ? 'silver'
            : 'bronze'
      }
      image={player.avatarUrl ? player.avatarUrl : undefined}
      active={active}
    />
  );
}

export { PlayerCard };
