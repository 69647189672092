import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import {
  PaginatedRequest,
  PaginatedResponse,
  ValidationErrorResponse,
} from '../../../types/api.types';

export interface AssessmentSurveyPreviewModel {
  id: string;
  name: string;
  releaseDate: Date;
  dueDate?: Date;
}

export type GetAssessmentSurveysRequest = PaginatedRequest & {
  subjectId: string;
};

export const useGetAssessmentSurveys = (
  request: GetAssessmentSurveysRequest,
  enabled?: boolean,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<
    PaginatedResponse<AssessmentSurveyPreviewModel>
  > {
    const response = await instance.get<
      PaginatedResponse<AssessmentSurveyPreviewModel>
    >(`/v2/subjects/${request.subjectId}/surveys`, {
      params: {
        fromTime: request.fromTime,
        toTime: request.toTime,
        pageNumber: request.pageNumber,
        pageSize: request.pageSize,
        timeSort: request.timeSort,
        order: request.order,
      },
      paramsSerializer: {
        indexes: null,
      },
    });

    return response.data;
  }

  return useQuery({
    queryKey: ['getAssessmentSurveys', request],
    queryFn,
    enabled: enabled ?? true,
  });
};

export interface ResponseViewModel {
  id: string;
  username: string;
  estimatedGrade: number;
  position: number;
  createdAt?: Date;
}

export interface AssessmentSurveyViewModel {
  id: string;
  name: string;
  releaseDate: Date;
  dueDate?: Date;
  totalResponses: number;
  averageEstimatedGrade: number;
  cutoffEstimatedGrade: number;
  topResponses: ResponseViewModel[];
}

export interface GetAssessmentSurveyRequest {
  subjectId: string;
  surveyId: string;
}

export const useGetAssessmentSurvey = (
  request: GetAssessmentSurveyRequest,
  enabled?: boolean,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<AssessmentSurveyViewModel> {
    const response = await instance.get<AssessmentSurveyViewModel>(
      `/v2/subjects/${request.subjectId}/surveys/${request.surveyId}`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: ['getAssessmentSurvey', request.subjectId, request.surveyId],
    queryFn,
    enabled,
  });
};

export interface GetAssessmentSurveyResponseRequest {
  subjectId: string;
  surveyId: string;
}

export const useGetAssessmentSurveyResponse = (
  request: GetAssessmentSurveyResponseRequest,
  enabled?: boolean,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<ResponseViewModel> {
    const response = await instance.get<ResponseViewModel>(
      `/v2/subjects/${request.subjectId}/surveys/${request.surveyId}/responses`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: [
      'getAssessmentSurveyResponse',
      request.subjectId,
      request.surveyId,
    ],
    queryFn,
    enabled,
  });
};

export type AddResponseToAssessmentSurveyRequest = {
  subjectId: string;
  surveyId: string;
  estimatedGrade: number;
};

export const useAddResponseToAssessmentSurvey = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(request: AddResponseToAssessmentSurveyRequest) {
    const response = await instance.post(
      `/v2/subjects/${request.subjectId}/surveys/${request.surveyId}/responses`,
      {
        estimatedGrade: request.estimatedGrade,
      },
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    AddResponseToAssessmentSurveyRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getAssessmentSurvey'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getAssessmentSurveyResponse'],
      });
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'deadline_exceeded') {
        sendMessage(
          'Operación inválida',
          'Fecha de submisión inválida',
          'danger',
        );
      }
    },
  });
};

export type UpdateResponseFromAssessmentSurveyRequest = {
  subjectId: string;
  surveyId: string;
  responseId: string;
  estimatedGrade: number;
};

export const useUpdateResponseFromAssessmentSurvey = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(
    request: UpdateResponseFromAssessmentSurveyRequest,
  ) {
    const response = await instance.patch(
      `/v2/subjects/${request.subjectId}/surveys/${request.surveyId}/responses/${request.responseId}`,
      {
        estimatedGrade: request.estimatedGrade,
      },
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    UpdateResponseFromAssessmentSurveyRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getAssessmentSurvey'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getAssessmentSurveyResponse'],
      });
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'deadline_exceeded') {
        sendMessage(
          'Operación inválida',
          'Fecha de submisión inválida',
          'danger',
        );
      }
    },
  });
};
