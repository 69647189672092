import { useQuery } from '@tanstack/react-query';

import { useAxios } from '../../../context/AxiosContext';

export interface SchoolViewModel {
  id: string;
  name: string;
}

export interface UserViewModel {
  id: string;
  identityProviderId: string;
  email: string;
  active: boolean;
  banned: boolean;
  username?: string;
  school?: SchoolViewModel;
}

export const useGetSelf = (enabled: boolean) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<UserViewModel> {
    const response = await instance.get<UserViewModel>(`v2/users/me`);

    return response.data;
  }

  return useQuery({
    queryKey: ['getSelf'],
    queryFn,
    enabled,
  });
};

export interface AssessmentAttemptMetricsViewModel {
  grade: number;
  elapsedTime: number;
  submittedAt: Date;
  totalQuestions: number;
  correctAnswers: number;
  incorrectAnswers: number;
}

export interface UserMetricsViewModel {
  questionAverageTime: number;
  averageScore: number;
  total: number;
  metrics: AssessmentAttemptMetricsViewModel[];
}

export type GetUserMetricsRequest = {
  userId: string;
  subjectId: string;
};

export const useGetUserMetrics = (request: GetUserMetricsRequest) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<UserMetricsViewModel> {
    const response = await instance.get<UserMetricsViewModel>(
      `/v2/users/${request.userId}/metrics/mc?subjectId=${request.subjectId}`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: ['getUserMetrics', request.userId, request.subjectId],
    queryFn,
  });
};
