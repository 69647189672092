import { useEffect, useState } from 'react';

import {
  ActionDialogButtons,
  ActionDialogContent,
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  ErrorsType,
  FormFeedback,
  FormGroup,
  FormLabel,
  FormSection,
  IconButton,
  ImageUpload,
  Input,
} from '@sealfye/ui-components';
import { useFormikContext } from 'formik';
import { IoTrashOutline } from 'react-icons/io5';

import { StepperBody } from '../../../../../components/shared/admin/layout/StepperBody';
import { StepperFooter } from '../../../../../components/shared/admin/layout/StepperFooter';
import { BaseComponentProps } from '../../../../../types/base-component.types';
import { SubjectsForm } from '../../../../subjects/components/subjects-form/SubjectsForm';
import { TagsForm } from '../../../../tags/components/tags-form/TagsForm';
import { DiagramFormValues } from '../DiagramEditor';

import styles from './DiagramStep.module.scss';

type ContainerProps = BaseComponentProps & {
  onNext: () => void;
  onCancel: () => void;
  onDelete: () => void;
};

function DiagramStep({ onNext, onCancel, onDelete }: ContainerProps) {
  const {
    setTouched,
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    setFieldTouched,
    setErrors,
  } = useFormikContext<DiagramFormValues>();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (values.id) {
      setTouched(
        Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
      );
    }
  }, [values]);

  return (
    <>
      <StepperBody>
        <FormSection title={values.id ? 'Editar esquema' : 'Nuevo esquema'}>
          <SubjectsForm />
          <FormGroup>
            <FormLabel>Nombre</FormLabel>
            <Input
              id="name"
              value={values.name}
              onChange={handleChange}
              error={touched.name && !!errors.name}
            />
            {touched.name && errors.name && (
              <FormFeedback variant="danger">{errors.name}</FormFeedback>
            )}
          </FormGroup>
          <TagsForm />
          <FormGroup>
            <FormLabel>Imagen del esquema</FormLabel>
            <ImageUpload
              dataURL={values.base64Image}
              onChange={(image?: string) => {
                handleChange({
                  target: {
                    name: 'base64Image',
                    value: image ?? '',
                  },
                });
              }}
              onError={(error?: ErrorsType) => {
                setFieldTouched('base64Image', true, false);

                if (error?.maxFileSize) {
                  setErrors({
                    ...errors,
                    base64Image: 'La imagen no puede superar los 5MB',
                  });
                }

                if (error?.maxNumber) {
                  setErrors({
                    ...errors,
                    base64Image: 'No puedes subir más de 1 imagen',
                  });
                }

                if (error?.acceptType) {
                  setErrors({
                    ...errors,
                    base64Image: 'El formato de la imagen no es válido',
                  });
                }

                if (error?.resolution) {
                  setErrors({
                    ...errors,
                    base64Image: 'La resolución de la imagen es muy alta',
                  });
                }
              }}
            />
            {touched.base64Image && errors.base64Image && (
              <FormFeedback variant="danger">{errors.base64Image}</FormFeedback>
            )}
            <FormFeedback>
              La imagen no puede superar los 5MB, y debe estar en un formato
              válido (jpg, jpeg, png).
            </FormFeedback>
          </FormGroup>
        </FormSection>
      </StepperBody>

      <StepperFooter className={styles['footer']}>
        <div className={styles['footer__left']}>
          {values.id && (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger asChild={true}>
                <IconButton
                  icon={<IoTrashOutline />}
                  className={styles['trash']}
                  onClick={() => setOpen((v) => !v)}
                  disabled={isSubmitting}
                />
              </DialogTrigger>
              <DialogContent>
                <ActionDialogContent title="¿Estás segurx de que deseas eliminar este esquema?">
                  <ActionDialogButtons>
                    <Button
                      variant="outline-primary"
                      onClick={() => setOpen((v) => !v)}
                    >
                      Cancelar
                    </Button>
                    <Button variant="danger" onClick={onDelete}>
                      Eliminar
                    </Button>
                  </ActionDialogButtons>
                </ActionDialogContent>
              </DialogContent>
            </Dialog>
          )}
        </div>
        <div className={styles['footer__right']}>
          <Button
            type="button"
            variant="outline-danger"
            disabled={isSubmitting}
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            variant="outline-primary"
            disabled={isSubmitting}
            onClick={() => {
              setTouched(
                Object.keys(values).reduce(
                  (acc, key) => ({ ...acc, [key]: true }),
                  {},
                ),
              );

              if (Object.keys(errors).length === 0) {
                onNext();
              }
            }}
          >
            Siguiente
          </Button>
        </div>
      </StepperFooter>
    </>
  );
}

export { DiagramStep };
