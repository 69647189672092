import { Pagination } from '@sealfye/ui-components';
import classnames from 'classnames';

import { OffsetPaginationProps } from './OffsetPagination.types';

import styles from './OffsetPagination.module.scss';

function OffsetPagination({
  className,
  pageNumber,
  pageSize,
  totalRecords,
  onPageChange,
  testId = 'ui-offset-pagination',
}: OffsetPaginationProps) {
  if (totalRecords <= pageSize) {
    return null;
  }

  return (
    <Pagination
      testId={testId}
      className={classnames(styles['main'], className)}
      pageNumber={pageNumber}
      pageSize={pageSize}
      totalRecords={totalRecords}
      onPageChange={onPageChange}
      pageNeighbours={1}
    />
  );
}

export { OffsetPagination };
