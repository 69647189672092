import { useEffect, useState } from 'react';

import {
  FormFeedback,
  FormGroup,
  FormLabel,
  MultiselectAutocomplete,
  SelectOption,
} from '@sealfye/ui-components';
import { useFormikContext } from 'formik';

import { FormEntity } from '../../../../types/form.types';
import { CreateTagResponse, useCreateTag, useGetTags } from '../../api/useTags';

interface FormValues {
  tags: FormEntity[];
}

function TagsForm() {
  const { setFieldValue, handleChange, values } =
    useFormikContext<FormValues>();

  const [options, setOptions] = useState<SelectOption[]>([]);

  const { data: response, isLoading } = useGetTags();
  const { mutate: createTag } = useCreateTag();

  useEffect(() => {
    if (response) {
      setOptions(
        response.tags.map((tag) => ({
          value: tag.id,
          label: tag.name,
          selected: values.tags.some((value) => value.id === tag.id),
        })),
      );
    }
  }, [response, values]);

  return (
    <FormGroup>
      <FormLabel>Tags</FormLabel>
      <MultiselectAutocomplete
        disabled={isLoading}
        inputLabel="Selecciona uno o varios..."
        optionLabels={{
          empty: 'No existe ningún tag',
          add: '+ Crear nuevo tag',
        }}
        onSelect={(values) => {
          handleChange({
            target: {
              name: 'tags',
              value: values.map((value) => ({
                id: value,
                name: response?.tags.find((tag) => tag.id === value)?.name,
              })),
            },
          });
        }}
        options={options}
        onAddOption={(value) => {
          createTag(
            { name: value },
            {
              onSuccess: (data: CreateTagResponse) => {
                setFieldValue('tags', [
                  ...values.tags,
                  {
                    id: data.tagId,
                    name: value,
                  },
                ]);
              },
            },
          );
        }}
      />
      <FormFeedback>
        Los tags son utilizados para clasificar todos los recursos de la
        plataforma. Recuerda que son visibles para los usuarios.
      </FormFeedback>
    </FormGroup>
  );
}

export { TagsForm };
