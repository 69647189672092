import { PropsWithChildren, useEffect, useState } from 'react';

import { BottomTabMenuItem } from '@sealfye/ui-components';
import classnames from 'classnames';
import { IoPodiumOutline } from 'react-icons/io5';

import { BaseComponentProps } from '../../../../types/base-component.types';
import {
  AssessmentSurveyPreviewModel,
  GetAssessmentSurveysRequest,
  useGetAssessmentSurveys,
} from '../../api/useSurveys';

import styles from './AssessmentSurveyBottomTabItem.module.scss';

export interface AssessmentSurveyFormValues {
  estimatedGrade: number;
}

type ContainerProps = BaseComponentProps & {
  subjectId: string;
  onClick: (id: string) => void;
};

function AssessmentSurveyBottomTabItem({
  className,
  subjectId,
  onClick,
  children,
}: PropsWithChildren<ContainerProps>) {
  const [currenSurvey, setCurrentSurvey] = useState<
    AssessmentSurveyPreviewModel | undefined
  >();

  const [filter, setFilter] = useState<GetAssessmentSurveysRequest>({
    subjectId,
    pageNumber: 1,
    pageSize: 1,
    fromTime: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    toTime: new Date(),
    timeSort: 'releaseDate',
    order: 'desc',
  });

  const { data: response } = useGetAssessmentSurveys(filter);

  useEffect(() => {
    if (response && response.totalItems > 0) {
      setCurrentSurvey(response.items[0]);
    }
  }, [response]);

  useEffect(() => {
    setFilter({
      ...filter,
      subjectId: subjectId,
    });
  }, [subjectId]);

  if (
    currenSurvey &&
    currenSurvey.releaseDate < new Date() &&
    currenSurvey.dueDate &&
    currenSurvey.dueDate > new Date()
  ) {
    return (
      <>
        <BottomTabMenuItem
          icon={<IoPodiumOutline />}
          className={classnames(styles['warning'], className)}
          onClick={() => onClick(currenSurvey.id)}
        >
          {children}
        </BottomTabMenuItem>
      </>
    );
  }

  return null;
}

export { AssessmentSurveyBottomTabItem };
