import { PropsWithChildren } from 'react';

import classnames from 'classnames';

import { BaseComponentProps } from '../../../../types/base-component.types';

import styles from './StepperFooter.module.scss';

function StepperFooter({
  className,
  children,
  testId = 'ui-stepper-footer',
}: PropsWithChildren<BaseComponentProps>) {
  return (
    <div
      className={classnames(styles['footer'], className)}
      data-testid={testId}
    >
      {children}
    </div>
  );
}

export { StepperFooter };
