import { useState } from 'react';

import { Dialog, DialogContent, Step, Stepper } from '@sealfye/ui-components';
import classnames from 'classnames';
import {
  Formik,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import * as Yup from 'yup';

import { StepForm } from '../../../../components/shared/admin/layout/StepForm';
import { StepperHeader } from '../../../../components/shared/admin/layout/StepperHeader';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { FormEntity } from '../../../../types/form.types';
import { equal } from '../../../../utils/equality';
import {
  Status,
  Visibility,
} from '../../../questions/api/useMultipleChoiceQuestions';
import {
  Methodology,
  MultipleChoiceAssessmentViewModel,
  Perspective,
  Purpose,
  ReferenceStandard,
  Setting,
  useCreateMultipleChoiceAssessment,
  useDeleteMultipleChoiceAssessment,
  useUpdateMultipleChoiceAssessment,
} from '../../api/useMultipleChoiceAssessments';
import { MultipleChoiceAssessmentReviewStep } from './steps/MultipleChoiceAssessmentReviewStep';
import { MultipleChoiceAssessmentStep } from './steps/MultipleChoiceAssessmentStep';

import styles from './MultipleChoiceAssessmentEditor.module.scss';

export interface AssessmentFormValues {
  id?: string;
  subject: FormEntity;
  name: string;
  description: string;
  timeLimit: number;
  visibility: Visibility;
  purpose: Purpose;
  referenceStandard: ReferenceStandard;
  methodology: Methodology;
  perspective: Perspective;
  setting: Setting;
  official: boolean;
  releaseDate: Date;
  tags: FormEntity[];
  base64Image?: string;
  maintainsQuestionOrder: boolean;
  status?: Status;
}

export interface MultipleChoiceAssessmentFormValues
  extends AssessmentFormValues {
  numberOfChoices: number;
}

type ContainerProps = BaseComponentProps & {
  open?: boolean;
  assessment?: MultipleChoiceAssessmentViewModel;
  onClose: () => void;
};

function MultipleChoiceAssessmentEditor({
  className,
  open,
  assessment,
  onClose,
  testId = 'ui-multiple-choice-assessment-editor',
}: ContainerProps) {
  const [activeStep, setActiveStep] = useState<number>(0);

  const [steps, setSteps] = useState<Step[]>([
    { variant: 'active', label: 'Contenido' },
    { variant: 'disabled', label: 'Revisar y publicar' },
  ]);

  const { mutateAsync: createMultipleChoiceAssessment } =
    useCreateMultipleChoiceAssessment();

  const { mutateAsync: updateMultipleChoiceAssessment } =
    useUpdateMultipleChoiceAssessment();

  const { mutateAsync: deleteMultipleChoiceAssessment } =
    useDeleteMultipleChoiceAssessment();

  const onNext = () => {
    setActiveStep(activeStep + 1);
    setSteps(
      steps.map((step, index) => {
        if (index === activeStep) {
          return { ...step, variant: 'completed' };
        }

        if (index === activeStep + 1) {
          return { ...step, variant: 'active' };
        }

        return step;
      }),
    );
  };

  return (
    <Dialog open={open}>
      <DialogContent
        className={classnames(styles['main'], className)}
        data-testid={testId}
      >
        <div className={styles['edit']}>
          <StepperHeader>
            <Stepper
              className={styles['stepper']}
              activeStep={activeStep}
              showIcons={true}
              steps={steps}
              onClickStep={(index) => {
                setActiveStep(index);
                setSteps(
                  steps.map((step, stepIndex) => {
                    if (stepIndex === index) {
                      return { ...step, variant: 'active' };
                    }

                    if (stepIndex > index) {
                      return { ...step, variant: 'disabled' };
                    }

                    return step;
                  }),
                );
              }}
            />
          </StepperHeader>
          <Formik
            validate={async (values: MultipleChoiceAssessmentFormValues) => {
              const errors: FormikErrors<MultipleChoiceAssessmentFormValues> =
                {};

              const validationSchema = Yup.object().shape({
                subject: Yup.object().required('Selecciona una materia.'),
                name: Yup.string()
                  .min(10, 'El nombre debe tener al menos 10 caracteres.')
                  .max(2000, 'El nombre no debe superar los 2000 caracteres.')
                  .required('El nombre no puede estar vacío.'),
                description: Yup.string()
                  .max(
                    2000,
                    'La descripción no debe superar los 2000 caracteres.',
                  )
                  .required('La descripción no puede estar vacía.'),
                timeLimit: Yup.number()
                  .min(1, 'El tiempo límite debe ser mayor de cero.')
                  .max(
                    3000,
                    'El tiempo límite no puede superar los 50 minutos.',
                  )
                  .required('El tiempo límite no puede estar vacío.'),
                purpose: Yup.string().oneOf(
                  ['formative', 'summative', 'diagnostic'],
                  'Selecciona un propósito válido.',
                ),
                referenceStandard: Yup.string().oneOf(
                  ['criterion-referenced', 'norm-referenced'],
                  'Selecciona un estándar de referencia válido.',
                ),
                methodology: Yup.string().oneOf(
                  ['qualitative', 'quantitative', 'blended'],
                  'Selecciona un enfoque válido.',
                ),
                perspective: Yup.string().oneOf(
                  [
                    'self-assessment',
                    'peer-assessment',
                    'instructor',
                    'automated',
                  ],
                  'Selecciona una perspectiva válida.',
                ),
                setting: Yup.string().oneOf(
                  ['standardized', 'informal', 'formal'],
                  'Selecciona un entorno válido.',
                ),
                releaseDate: Yup.date().required(
                  'Selecciona una fecha de lanzamiento.',
                ),
                official: Yup.boolean().required(
                  'Selecciona si es oficial o no.',
                ),
                tags: Yup.array().of(
                  Yup.object().shape({
                    id: Yup.string().required(),
                    name: Yup.string().required(),
                  }),
                ),
                base64Image: Yup.string(),
                maintainsQuestionOrder: Yup.boolean().required(),
                status: Yup.string().oneOf(
                  ['draft', 'published', 'archived'],
                  'Selecciona un estado válido.',
                ),
              });

              try {
                validateYupSchema<MultipleChoiceAssessmentFormValues>(
                  values,
                  validationSchema,
                  true,
                );
              } catch (err) {
                return yupToFormErrors(err);
              }

              return errors;
            }}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              setSubmitting(true);

              if (assessment) {
                await updateMultipleChoiceAssessment(
                  {
                    id: assessment.id,
                    subjectId: values.subject.id,
                    name:
                      values.name !== assessment.name ? values.name : undefined,
                    description:
                      values.description !== assessment.description
                        ? values.description
                        : undefined,
                    timeLimit:
                      values.timeLimit !== assessment.timeLimit
                        ? values.timeLimit
                        : undefined,
                    visibility:
                      values.visibility !== assessment.visibility
                        ? values.visibility
                        : undefined,
                    purpose:
                      values.purpose !== assessment.purpose
                        ? values.purpose
                        : undefined,
                    referenceStandard:
                      values.referenceStandard !== assessment.referenceStandard
                        ? values.referenceStandard
                        : undefined,
                    methodology:
                      values.methodology !== assessment.methodology
                        ? values.methodology
                        : undefined,
                    perspective:
                      values.perspective !== assessment.perspective
                        ? values.perspective
                        : undefined,
                    setting:
                      values.setting !== assessment.setting
                        ? values.setting
                        : undefined,
                    official:
                      values.official !== assessment.official
                        ? values.official
                        : undefined,
                    releaseDate:
                      values.releaseDate !== assessment.releaseDate
                        ? values.releaseDate
                        : undefined,
                    tagIds: !equal(values.tags, assessment.tags)
                      ? values.tags.map((tag) => tag.id)
                      : undefined,
                    base64Image:
                      values.base64Image !== assessment.imageUrl
                        ? values.base64Image
                        : undefined,
                    maintainsQuestionOrder:
                      values.maintainsQuestionOrder !==
                      assessment.maintainsQuestionOrder
                        ? values.maintainsQuestionOrder
                        : undefined,
                    status:
                      values.status !== assessment.status
                        ? values.status
                        : undefined,
                  },
                  {
                    onSuccess: () => {
                      onClose();
                      resetForm();
                      setActiveStep(0);
                    },
                  },
                );

                return;
              }

              await createMultipleChoiceAssessment(
                {
                  subjectId: values.subject.id,
                  name: values.name,
                  description: values.description,
                  timeLimit: values.timeLimit,
                  visibility: values.visibility,
                  purpose: values.purpose,
                  referenceStandard: values.referenceStandard,
                  methodology: values.methodology,
                  perspective: values.perspective,
                  setting: values.setting,
                  numberOfChoices: values.numberOfChoices,
                  official: values.official,
                  releaseDate: values.releaseDate,
                  tagIds: values.tags.map((tag) => tag.id),
                  base64Image: values.base64Image,
                  maintainsQuestionOrder: values.maintainsQuestionOrder,
                },
                {
                  onSuccess: () => {
                    onClose();
                    resetForm();
                    setActiveStep(0);
                  },
                },
              );

              setSubmitting(false);
            }}
            initialValues={{
              id: assessment?.id,
              subject: {
                id: assessment?.subject.id ?? '',
                name: assessment?.subject.name ?? '',
              },
              name: assessment?.name ?? '',
              description: assessment?.description ?? '',
              timeLimit: assessment?.timeLimit ?? 3000,
              visibility: assessment?.visibility ?? 'private',
              purpose: assessment?.purpose ?? 'formative',
              referenceStandard:
                assessment?.referenceStandard ?? 'criterion-referenced',
              methodology: assessment?.methodology ?? 'quantitative',
              perspective: assessment?.perspective ?? 'instructor',
              setting: assessment?.setting ?? 'formal',
              official: assessment?.official ?? false,
              releaseDate: assessment?.releaseDate ?? new Date(),
              tags: assessment?.tags ?? [],
              base64Image: assessment?.imageUrl,
              maintainsQuestionOrder:
                assessment?.maintainsQuestionOrder ?? false,
              numberOfChoices: assessment?.numberOfChoices ?? 3,
              status: assessment?.status ?? 'draft',
            }}
          >
            {({ resetForm, setSubmitting }) => (
              <StepForm>
                {activeStep === 0 && (
                  <MultipleChoiceAssessmentStep
                    onNext={onNext}
                    onCancel={() => {
                      onClose();
                      resetForm();
                      setActiveStep(0);
                    }}
                    onDelete={async () => {
                      if (assessment) {
                        setSubmitting(true);

                        await deleteMultipleChoiceAssessment(
                          {
                            subjectId: assessment.subject.id,
                            assessmentId: assessment.id,
                          },
                          {
                            onSuccess: () => {
                              onClose();
                              resetForm();
                              setActiveStep(0);
                            },
                          },
                        );

                        setSubmitting(false);
                      }
                    }}
                  />
                )}

                {activeStep === 1 && (
                  <MultipleChoiceAssessmentReviewStep
                    numberOfQuestions={assessment?.questions.length ?? 0}
                    onCancel={() => {
                      onClose();
                      resetForm();
                      setActiveStep(0);
                    }}
                  />
                )}
              </StepForm>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export { MultipleChoiceAssessmentEditor };
