import { useState } from 'react';

import { Dialog, DialogContent, Step, Stepper } from '@sealfye/ui-components';
import classnames from 'classnames';
import {
  Formik,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import * as Yup from 'yup';

import { StepForm } from '../../../../components/shared/admin/layout/StepForm';
import { StepperHeader } from '../../../../components/shared/admin/layout/StepperHeader';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { FormEntity } from '../../../../types/form.types';
import { equal } from '../../../../utils/equality';
import { Visibility } from '../../../questions/api/useMultipleChoiceQuestions';
import {
  DiagramViewModel,
  useCreateDiagram,
  useDeleteDiagram,
  useUpdateDiagram,
} from '../../api/useDiagrams';
import { DiagramReviewStep } from './steps/DiagramReviewStep';
import { DiagramStep } from './steps/DiagramStep';

import styles from './DiagramEditor.module.scss';

export interface DiagramFormValues {
  id?: string;
  subject: FormEntity;
  unit?: FormEntity;
  lesson?: FormEntity;
  name: string;
  visibility: Visibility;
  tags: FormEntity[];
  base64Image: string;
}

type ContainerProps = BaseComponentProps & {
  open?: boolean;
  diagram?: DiagramViewModel;
  onClose: () => void;
};

function DiagramEditor({
  className,
  open,
  diagram,
  onClose,
  testId = 'ui-diagram-editor',
}: ContainerProps) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<Step[]>([
    { variant: 'active', label: 'Contenido' },
    { variant: 'disabled', label: 'Revisar y publicar' },
  ]);

  const { mutateAsync: createDiagram } = useCreateDiagram();

  const { mutateAsync: updateDiagram } = useUpdateDiagram();

  const { mutateAsync: deleteDiagram } = useDeleteDiagram();

  const onNext = () => {
    setActiveStep(activeStep + 1);
    setSteps(
      steps.map((step, index) => {
        if (index === activeStep) {
          return { ...step, variant: 'completed' };
        }

        if (index === activeStep + 1) {
          return { ...step, variant: 'active' };
        }

        return step;
      }),
    );
  };

  return (
    <Dialog open={open}>
      <DialogContent
        className={classnames(styles['main'], className)}
        data-testid={testId}
      >
        <div className={styles['edit']}>
          <StepperHeader>
            <Stepper
              className={styles['stepper']}
              activeStep={activeStep}
              showIcons={true}
              steps={steps}
              onClickStep={(index) => {
                setActiveStep(index);
                setSteps(
                  steps.map((step, stepIndex) => {
                    if (stepIndex === index) {
                      return { ...step, variant: 'active' };
                    }

                    if (stepIndex > index) {
                      return { ...step, variant: 'disabled' };
                    }

                    return step;
                  }),
                );
              }}
            />
          </StepperHeader>
          <Formik
            validate={async (values: DiagramFormValues) => {
              const errors: FormikErrors<DiagramFormValues> = {};

              const validationSchema = Yup.object().shape({
                subject: Yup.object().required('Selecciona una materia.'),
                unit: Yup.object().required('Selecciona una unidad.'),
                lesson: Yup.object().required('Selecciona una lección.'),
                name: Yup.string()
                  .min(
                    5,
                    'El nombre del esquema debe tener al menos 5 caracteres.',
                  )
                  .max(
                    150,
                    'El nombre del esquema no debe superar los 150 caracteres.',
                  )
                  .required('El nombre del esquema no puede estar vacío.'),
                tags: Yup.array().of(
                  Yup.object().shape({
                    id: Yup.string().required(),
                    name: Yup.string().required(),
                  }),
                ),
                base64Image: Yup.string().required(
                  'La imagen del esquema no puede estar vacía.',
                ),
              });

              try {
                validateYupSchema<DiagramFormValues>(
                  values,
                  validationSchema,
                  true,
                );
              } catch (err) {
                return yupToFormErrors(err);
              }

              return errors;
            }}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              setSubmitting(true);

              if (diagram) {
                await updateDiagram(
                  {
                    id: diagram.id,
                    subjectId: values.subject.id,
                    unitId:
                      values.unit?.id !== diagram.unit?.id
                        ? values.unit?.id
                        : undefined,
                    lessonId:
                      values.lesson?.id !== diagram.lesson?.id
                        ? values.lesson?.id
                        : undefined,
                    name:
                      values.name !== diagram.name ? values.name : undefined,
                    visibility:
                      values.visibility !== diagram.visibility
                        ? values.visibility
                        : undefined,
                    tagIds: !equal(values.tags, diagram.tags)
                      ? values.tags.map((tag) => tag.id)
                      : undefined,
                    base64Image:
                      values.base64Image !== diagram.imageUrl
                        ? values.base64Image
                        : undefined,
                  },
                  {
                    onSuccess: () => {
                      onClose();
                      resetForm();
                      setActiveStep(0);
                    },
                  },
                );
                return;
              }

              await createDiagram(
                {
                  subjectId: values.subject.id,
                  unitId: values.unit?.id,
                  lessonId: values.lesson?.id,
                  name: values.name,
                  visibility: values.visibility,
                  tagIds: values.tags.map((tag) => tag.id),
                  base64Image: values.base64Image,
                },
                {
                  onSuccess: () => {
                    onClose();
                    resetForm();
                    setActiveStep(0);
                  },
                },
              );

              setSubmitting(false);
            }}
            initialValues={{
              id: diagram?.id,
              subject: {
                id: diagram?.subject.id ?? '',
                name: diagram?.subject.name ?? '',
              },
              unit: diagram?.unit
                ? {
                    id: diagram.unit.id,
                    name: diagram.unit.name,
                  }
                : undefined,
              lesson: diagram?.lesson
                ? {
                    id: diagram.lesson.id,
                    name: diagram.lesson.name,
                  }
                : undefined,
              name: diagram?.name ?? '',
              visibility: diagram?.visibility ?? 'private',
              tags: diagram?.tags ?? [],
              base64Image: diagram?.imageUrl ?? '',
            }}
          >
            {({ resetForm, setSubmitting }) => (
              <StepForm>
                {activeStep === 0 && (
                  <DiagramStep
                    onNext={onNext}
                    onCancel={() => {
                      onClose();
                      resetForm();
                      setActiveStep(0);
                    }}
                    onDelete={async () => {
                      if (diagram) {
                        setSubmitting(true);

                        await deleteDiagram(
                          {
                            subjectId: diagram.subject.id,
                            id: diagram.id,
                          },
                          {
                            onSuccess: () => {
                              onClose();
                              resetForm();
                              setActiveStep(0);
                            },
                          },
                        );

                        setSubmitting(false);
                      }
                    }}
                  />
                )}

                {activeStep === 1 && (
                  <DiagramReviewStep
                    onCancel={() => {
                      onClose();
                      resetForm();
                      setActiveStep(0);
                    }}
                  />
                )}
              </StepForm>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export { DiagramEditor };
