import { Accordion, AccordionSkeleton, Card } from '@sealfye/ui-components';
import { IoLinkOutline } from 'react-icons/io5';

import { BaseComponentProps } from '../../../../types/base-component.types';
import { useGetSubject } from '../../api/useSubjects';
import { colorPalette } from '../subject-selectable-list/SubjectSelectableList';

import styles from './SubjectResourcesList.module.scss';

export type ContainerProps = BaseComponentProps & {
  subjectId: string;
};

function SubjectResourcesList({ subjectId }: ContainerProps) {
  const { data: response, isLoading } = useGetSubject({
    subjectId,
  });

  return (
    <Card>
      {isLoading || !response ? (
        <AccordionSkeleton items={25} />
      ) : (
        <>
          {response.units.map((unit, index) => (
            <div key={unit.id}>
              <div className={styles['title']}>{unit.name}</div>
              <Accordion
                items={unit.lessons
                  .filter((lesson) => lesson.resources.length > 0)
                  .map((item) => ({
                    label: {
                      value: (
                        '0' +
                        (response.units
                          .filter((unit) => unit.index <= index)
                          .flatMap((unit) => unit.lessons).length +
                          item.index)
                      ).slice(-2),
                      backgroundColor:
                        colorPalette[index % colorPalette.length],
                    },
                    text: item.name,
                    info: item.outline,
                    items: item.resources.map((resource) => ({
                      text: resource.name,
                      icon: (
                        <IoLinkOutline
                          style={{
                            cursor: 'pointer',
                            fontSize: '20px',
                            minWidth: '20px',
                            margin: '0px',
                          }}
                          onClick={() => window.open(resource.url, '_blank')}
                        />
                      ),
                    })),
                  }))}
              />
            </div>
          ))}
        </>
      )}
    </Card>
  );
}

export { SubjectResourcesList };
