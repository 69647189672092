import { useState } from 'react';

import { Button } from '@sealfye/ui-components';
import { IoFlashOutline, IoShieldHalfOutline } from 'react-icons/io5';

import CompetitionImg from '../../../assets/images/_66fee480-5f6c-4c82-9f82-8a4a12a01e73.png';
import { BackdropDialog } from '../../../components/shared/backdrop/BackdropDialog';
import { SectionCard } from '../../../components/shared/section-card/SectionCard';
import { useProfile } from '../../../context/ProfileContext';
import { useAppActions } from '../../../state/appStore';
import { BaseComponentProps } from '../../../types/base-component.types';
import { Game } from './one-to-one/Game';

function ChallengeCard({ className }: BaseComponentProps) {
  const { profile } = useProfile();
  const { setShowGetPremiumPopup } = useAppActions();

  const [showGame, setShowGame] = useState(false);

  const onSelect = () => {
    if (profile.subscription?.isActive) {
      setShowGame(true);
    } else {
      setShowGetPremiumPopup(true);
    }
  };

  return (
    <>
      <SectionCard
        className={className}
        title="Reta a otro opositor en un duelo y gana puntos"
        image={CompetitionImg}
        button={
          <Button
            variant="outline-primary"
            onClick={onSelect}
            suffixIcon={<IoFlashOutline />}
          >
            Buscar oponente
          </Button>
        }
        icon={<IoShieldHalfOutline />}
      >
        <p>
          Tendréis que responder a 10 preguntas de cualquier tema. Ganarás 3
          puntos si consigues más puntuación que el oponente, 1 si quedáis
          empate y ningún punto si pierdes.
        </p>
      </SectionCard>
      <BackdropDialog open={showGame}>
        <Game onQuit={() => setShowGame(false)} />
      </BackdropDialog>
    </>
  );
}

export { ChallengeCard };
