import { useState } from 'react';

import { IconButton, SelectDropdown, SlideDown } from '@sealfye/ui-components';
import {
  IoAddCircleOutline,
  IoOptions,
  IoOptionsOutline,
} from 'react-icons/io5';

import { Page } from '../../components/shared/page/Page';
import { MultipleChoiceQuestionEditor } from '../../features/questions/components/multiple-choice-question-editor/MultipleChoiceQuestionEditor';
import { MultipleChoiceQuestionList } from '../../features/questions/components/multiple-choice-question-list/MultipleChoiceQuestionList';
import {
  QuestionListFilter,
  QuestionListFilterForm,
} from '../../features/questions/components/question-list-filter/QuestionListFilter';
import { Format } from '../../features/reports/api/useErrorReports';

import styles from './QuestionListPage.module.scss';

function QuestionListPage() {
  const [filter, setFilter] = useState<QuestionListFilterForm>({
    fromTime: new Date('2020-01-01'),
    toTime: new Date(new Date().setHours(23, 59, 59, 999)),
    timeSort: 'createdAt',
    order: 'desc',
    subject: {
      id: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
      name: '',
    },
  });

  const [questionType, setQuestionType] = useState<Format>('multiple_choice');
  const [showFilters, setShowFilters] = useState(false);
  const [showNew, setShowNew] = useState(false);

  return (
    <Page className={styles['page']}>
      <div className={styles['header']}>
        <IconButton
          icon={<IoAddCircleOutline />}
          onClick={() => {
            setShowNew(true);
          }}
        />
        <SelectDropdown
          className={styles['dropdown']}
          onSelect={(value) => {
            setQuestionType(value as Format);
          }}
          options={[
            {
              value: 'multiple_choice',
              label: 'Opción múltiple',
              selected: true,
            },
            {
              value: 'likert',
              label: 'Escala de Likert',
              disabled: true,
            },
            {
              value: 'answer',
              label: 'Respuesta escrita',
              disabled: true,
            },
            {
              value: 'fill_the_gap',
              label: 'Rellenar el espacio',
              disabled: true,
            },
            {
              value: 'true_false',
              label: 'Verdadero o Falso',
              disabled: true,
            },
          ]}
        />
        <IconButton
          icon={<IoOptionsOutline />}
          flipped={showFilters}
          flipIcon={<IoOptions />}
          onClick={() => {
            setShowFilters(!showFilters);
          }}
        />
      </div>
      <SlideDown className={styles['slide-down']}>
        {showFilters ? (
          <QuestionListFilter
            values={filter}
            onSubmit={(values) => {
              setFilter(values);
            }}
            onClear={() => {
              setFilter({
                fromTime: new Date('2020-01-01'),
                toTime: new Date(new Date().setHours(23, 59, 59, 999)),
                timeSort: 'createdAt',
                order: 'desc',
                subject: {
                  id: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
                  name: '',
                },
              });
            }}
          />
        ) : null}
      </SlideDown>
      {questionType === 'multiple_choice' && (
        <MultipleChoiceQuestionList filter={filter} />
      )}
      {questionType === 'multiple_choice' && (
        <MultipleChoiceQuestionEditor
          open={showNew}
          onClose={() => setShowNew(false)}
        />
      )}
    </Page>
  );
}

export { QuestionListPage };
