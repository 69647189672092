import { useEffect, useState } from 'react';

import {
  AssessmentAttemptResult,
  Button,
  MultipleChoiceAssessment,
  MultipleChoiceQuestionType,
} from '@sealfye/ui-components';

import { Loading } from '../../../../components/core/Loading';
import { useProfile } from '../../../../context/ProfileContext';
import { BaseComponentProps } from '../../../../types/base-component.types';
import {
  MultipleChoiceQuestionViewModel,
  useBookmarkMultipleChoiceQuestion,
  useUnbookmarkMultipleChoiceQuestion,
} from '../../../questions/api/useMultipleChoiceQuestions';
import {
  AssessmentAttemptViewModel,
  CreateMultipleChoiceAssessmentAttemptCommandResult,
  useSubmitMultipleChoiceAssessmentAttempt,
} from '../../api/useMultipleChoiceAssessments';

import styles from './MultipleChoiceAssessmentAttempt.module.scss';

export type ContainerProps = BaseComponentProps & {
  attempt?: CreateMultipleChoiceAssessmentAttemptCommandResult;
  onFinish: () => void;
  onClose: () => void;
};

function MultipleChoiceAssessmentAttempt({
  attempt,
  onFinish,
  onClose,
}: ContainerProps) {
  const { profile } = useProfile();

  const [attemptQuestions, setAttemptQuestions] =
    useState<MultipleChoiceQuestionType[]>();

  const [attemptResult, setAttemptResult] =
    useState<AssessmentAttemptViewModel<MultipleChoiceQuestionViewModel>>();

  const { mutate: submitMultipleChoiceAssessmentAttempt, isPending } =
    useSubmitMultipleChoiceAssessmentAttempt();

  const { mutateAsync: bookmarkMultipleChoiceQuestion } =
    useBookmarkMultipleChoiceQuestion();

  const { mutateAsync: unbookmarkMultipleChoiceQuestion } =
    useUnbookmarkMultipleChoiceQuestion();

  useEffect(() => {
    if (attempt) {
      setAttemptQuestions(
        attempt.questionAttempts.map((question) => ({
          id: question.questionId,
          text: question.text,
          options: question.choices.map((choice) => choice.text),
          selectedOptionIndex: 0,
          bookmarked: question.bookmarked ?? false,
          imageUrl: question.imageUrl,
        })),
      );
    }
  }, [attempt]);

  return (
    <>
      {attemptResult ? (
        <AssessmentAttemptResult
          className={styles['result']}
          score={attemptResult.score}
          description={attempt?.name ?? 'Simulacro'}
          elapsedTime={attemptResult.elapsedTime}
          answerRate={{
            correct: attemptResult.questionAttempts.filter(
              (questionAttempt) =>
                questionAttempt.answer ===
                questionAttempt.correction.correctAnswer,
            ).length,
            incorrect: attemptResult.questionAttempts.filter(
              (questionAttempt) =>
                questionAttempt.answer !== '' &&
                questionAttempt.answer !==
                  questionAttempt.correction.correctAnswer,
            ).length,
            skipped: attemptResult.questionAttempts.filter(
              (questionAttempt) => questionAttempt.answer === '',
            ).length,
          }}
        >
          <Button
            className={styles['button']}
            variant="outline-primary"
            onClick={onFinish}
          >
            Ver respuestas
          </Button>
        </AssessmentAttemptResult>
      ) : attempt && attemptQuestions ? (
        <MultipleChoiceAssessment
          className={styles['questionnaire']}
          questions={attemptQuestions}
          timeInSeconds={attempt.timeLimit}
          instructions={
            attempt.description
              ? {
                  text: attempt.description,
                  imageUrl: attempt.imageUrl,
                }
              : undefined
          }
          isSubmitting={isPending}
          onSubmit={(questionAnswers) => {
            submitMultipleChoiceAssessmentAttempt(
              {
                subjectId: attempt.subject.id,
                assessmentAttemptId: attempt.id,
                questionAttempts: questionAnswers.map((questionAnswer) => ({
                  id: attempt.questionAttempts.find(
                    (question) => question.questionId === questionAnswer.id,
                  )!.id,
                  answer:
                    questionAnswer.answerIndex === 0
                      ? ''
                      : attempt.questionAttempts.find(
                          (question) =>
                            question.questionId === questionAnswer.id,
                        )!.choices[questionAnswer.answerIndex - 1].id,
                })),
              },
              {
                onSuccess: (
                  data: AssessmentAttemptViewModel<MultipleChoiceQuestionViewModel>,
                ) => {
                  setAttemptResult(data);
                },
              },
            );
          }}
          onClose={onClose}
          onBookmark={
            profile.subscription?.isActive
              ? async (id) => {
                  await bookmarkMultipleChoiceQuestion({
                    subjectId: attempt.subject.id,
                    questionId: id,
                  });
                }
              : undefined
          }
          onUnbookmark={
            profile.subscription?.isActive
              ? async (id) => {
                  await unbookmarkMultipleChoiceQuestion({
                    subjectId: attempt.subject.id,
                    questionId: id,
                  });
                }
              : undefined
          }
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

export { MultipleChoiceAssessmentAttempt };
