import { ComponentRef, ComponentType, forwardRef } from 'react';

import { IconButton } from '@sealfye/ui-components';
import classnames from 'classnames';
import { TbCheck, TbX } from 'react-icons/tb';

import styles from './withSwitchButton.module.scss';

export interface InjectedProps {
  selected: boolean;
  onSwitch: () => void;
}

function withSwitchButton<T extends object>(
  WrappedComponent: ComponentType<T>,
) {
  const Wrapper = forwardRef<
    ComponentRef<typeof WrappedComponent>,
    T & InjectedProps
  >((props, ref) => {
    return (
      <div className={styles['main']}>
        <WrappedComponent
          {...(props as T)}
          ref={ref}
          className={styles['component']}
        />
        {props.selected ? (
          <IconButton
            size="sm"
            className={classnames(styles['button'], {
              [styles[`button--success`]]: true,
            })}
            icon={<TbCheck />}
            onClick={props.onSwitch}
          />
        ) : (
          <IconButton
            size="sm"
            className={classnames(styles['button'], {
              [styles[`button--danger`]]: true,
            })}
            icon={<TbX />}
            onClick={props.onSwitch}
          />
        )}
      </div>
    );
  });

  Wrapper.displayName = `WithWrapper(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return Wrapper;
}

export default withSwitchButton;
