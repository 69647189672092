import { Button, Dialog, DialogContent } from '@sealfye/ui-components';
import classnames from 'classnames';

import AppImg from '../../../assets/images/enroll.png';
import { useConfiguration } from '../../../context/ConfigurationContext';
import { useApp, useAppActions } from '../../../state/appStore';
import { BaseComponentProps } from '../../../types/base-component.types';

import styles from './GetPremiumPopup.module.scss';

function GetPremiumPopup({
  className,
  testId = 'ui-get-premium-popup',
}: BaseComponentProps) {
  const { configuration } = useConfiguration();
  const { showGetPremiumPopup } = useApp();
  const { setShowGetPremiumPopup } = useAppActions();

  return (
    <Dialog
      open={showGetPremiumPopup}
      onOpenChange={(open) => {
        if (!open) {
          setShowGetPremiumPopup(false);
        }
      }}
    >
      <DialogContent
        className={classnames(styles['main'], className)}
        testId={testId}
      >
        <div className={styles['modal']}>
          <div className={styles['text']}>
            <h2>¡Hazte Premium y prepárate para cumplir tu sueño!</h2>
            <span className={styles['description']}>
              Con <strong>Premium</strong> podrás acceder a todo el contenido y
              funcionalidad de la plataforma de <strong>OpositandoPN</strong>
            </span>
          </div>
          <img className={styles['image']} src={AppImg} />
          <div className={styles['actions']}>
            <Button
              className={styles['button']}
              variant="outline-danger"
              shadow
              onClick={() => {
                setShowGetPremiumPopup(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              className={styles['button']}
              variant="premium"
              shadow
              onClick={() => {
                window.open(
                  `${configuration.app?.pageUrl}/mi-cuenta/premium`,
                  '_blank',
                );
                setShowGetPremiumPopup(false);
              }}
            >
              Hazte Premium
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export { GetPremiumPopup };
