import { useEffect, useState } from 'react';

import {
  Badge,
  Card,
  IconButton,
  List,
  ListSkeleton,
  Text,
} from '@sealfye/ui-components';
import {
  IoArrowForwardCircleOutline,
  IoClipboardOutline,
} from 'react-icons/io5';

import { OffsetPagination } from '../../../../components/shared/pagination/OffsetPagination';
import { ResourceDialog } from '../../../../components/shared/resource-dialog/ResourceDialog';
import { useProfile } from '../../../../context/ProfileContext';
import { useAppActions } from '../../../../state/appStore';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { useGetMultipleChoiceAssessments } from '../../api/useMultipleChoiceAssessments';
import { AssessmentListFilterForm } from '../assessment-list-filter/AssessmentListFilter';
import { MultipleChoiceAssessmentPreview } from '../multiple-choice-assessment-preview/MultipleChoiceAssessmentPreview';

type ContainerProps = BaseComponentProps & {
  filter: AssessmentListFilterForm;
  onSelected?: (assessmentId: string) => void;
};

function MultipleChoiceAssessmentList({
  className,
  filter,
  onSelected,
  testId = 'ui-multiple-choice-assessment-list',
}: ContainerProps) {
  const { profile } = useProfile();
  const { setShowGetPremiumPopup } = useAppActions();

  const [current, setCurrent] = useState<string>();
  const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });

  const { data: response, isLoading } = useGetMultipleChoiceAssessments({
    subjectId: filter.subject.id,
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
    fromTime: filter.fromTime,
    toTime: filter.toTime,
    timeSort: filter.timeSort,
    order: filter.order,
    params: {
      text: filter.text,
      statuses: filter.statuses,
      visibilities: filter.visibilities,
      purposes: filter.purposes,
      referenceStandards: filter.referenceStandards,
      methodologies: filter.methodologies,
      perspectives: filter.perspectives,
      settings: filter.settings,
      difficultyLevels: filter.difficultyLevels,
      tagIds: filter.tags?.map((tag) => tag.id),
      questionIds: filter.questionIds,
      numberOfChoices: filter.numberOfChoices,
      official: filter.official,
    },
  });

  useEffect(() => {
    setPagination({ ...pagination, pageNumber: 1 });
  }, [filter]);

  return (
    <>
      <Card
        title="Simulacros"
        caption={
          response?.totalItems ? `Total: ${response?.totalItems}` : undefined
        }
        className={className}
        testId={testId}
      >
        {isLoading || !response ? (
          <ListSkeleton items={pagination.pageSize} />
        ) : (
          <>
            <List
              items={response.items.map((item) => ({
                text:
                  item.name.length > 120
                    ? item.name.substring(0, 120) + '…'
                    : item.name,
                info: item.name,
                tag:
                  item.status === 'draft' ? (
                    <Badge bg="warning">Borrador</Badge>
                  ) : item.status === 'removed' ? (
                    <Badge bg="danger">Eliminado</Badge>
                  ) : undefined,
                icon: (
                  <>
                    <Text
                      variety="annotation"
                      font="monospace"
                      style={{ marginRight: '5px' }}
                    >
                      {item.questions.length} preguntas
                    </Text>
                    <IconButton
                      style={{
                        opacity:
                          !(
                            profile.subscription?.isActive &&
                            !profile.subscription?.isTrial
                          ) && item.visibility === 'private'
                            ? 0.3
                            : 1,
                      }}
                      size="sm"
                      icon={<IoClipboardOutline />}
                      onClick={() => {
                        if (
                          !(
                            profile.subscription?.isActive &&
                            !profile.subscription?.isTrial
                          ) &&
                          item.visibility === 'private'
                        ) {
                          setShowGetPremiumPopup(true);
                          return;
                        }

                        setCurrent(item.id);
                      }}
                    />
                  </>
                ),
              }))}
            />
            <OffsetPagination
              pageNumber={pagination.pageNumber}
              pageSize={pagination.pageSize}
              totalRecords={response.totalItems}
              onPageChange={(pageNumber: number) => {
                setPagination({ ...pagination, pageNumber });
              }}
              pageNeighbours={2}
            />
          </>
        )}
      </Card>
      <ResourceDialog open={current !== undefined}>
        {current && (
          <MultipleChoiceAssessmentPreview
            subjectId={filter.subject.id}
            assessmentId={current}
            onClose={() => {
              setCurrent(undefined);
            }}
          >
            {onSelected && (
              <IconButton
                icon={<IoArrowForwardCircleOutline />}
                onClick={() => {
                  onSelected(current);
                }}
              />
            )}
          </MultipleChoiceAssessmentPreview>
        )}
      </ResourceDialog>
    </>
  );
}

export { MultipleChoiceAssessmentList };
