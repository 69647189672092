import { PropsWithChildren } from 'react';

import classnames from 'classnames';

import { BaseComponentProps } from '../../../../types/base-component.types';

import styles from './StepperBody.module.scss';

function StepperBody({
  className,
  children,
  testId = 'ui-stepper-body',
}: PropsWithChildren<BaseComponentProps>) {
  return (
    <div className={styles['body']} data-testid={testId}>
      <div className={classnames(styles['content'], className)}>{children}</div>
    </div>
  );
}

export { StepperBody };
