import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { BackdropDialog } from '../../../components/shared/backdrop/BackdropDialog';
import { Page } from '../../../components/shared/page/Page';
import {
  CreateMultipleChoiceAssessmentAttemptCommandResult,
  useCreateMultipleChoiceAssessmentAttempt,
} from '../../../features/assessments/api/useMultipleChoiceAssessments';
import { AssessmentListFilterForm } from '../../../features/assessments/components/assessment-list-filter/AssessmentListFilter';
import { MultipleChoiceAssessmentAttempt } from '../../../features/assessments/components/multiple-choice-assessment-attempt/MultipleChoiceAssessmentAttempt';
import { MultipleChoiceAssessmentList } from '../../../features/assessments/components/multiple-choice-assessment-list/MultipleChoiceAssessmentList';

function MultipleChoiceCompetitiveAssessmentListPage() {
  const { subjectId } = useParams();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<AssessmentListFilterForm>({
    fromTime: new Date('2010-01-01'),
    toTime: new Date(),
    timeSort: 'releaseDate',
    order: 'desc',
    subject: {
      id: subjectId!,
      name: '',
    },
    perspectives: ['instructor'],
    statuses: ['published'],
    purposes: ['summative'],
    referenceStandards: ['norm-referenced'],
    methodologies: ['quantitative'],
    settings: ['standardized'],
    official: false,
  });

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      subject: {
        id: subjectId!,
        name: '',
      },
    }));
  }, [subjectId]);

  const [showAttempt, setShowAttempt] = useState(false);
  const [attempt, setAttempt] =
    useState<CreateMultipleChoiceAssessmentAttemptCommandResult>();

  const { mutate: createMultipleChoiceAssessmentAttempt } =
    useCreateMultipleChoiceAssessmentAttempt();

  return (
    <Page title="Simulacros competitivos">
      <MultipleChoiceAssessmentList
        filter={filter}
        onSelected={async (assessmentId: string) => {
          setShowAttempt(true);
          createMultipleChoiceAssessmentAttempt(
            {
              subjectId: subjectId!,
              assessmentId: assessmentId,
            },
            {
              onSuccess: (response) => {
                setAttempt(response);
              },
              onError: () => {
                setShowAttempt(false);
                setAttempt(undefined);
              },
            },
          );
        }}
      />
      <BackdropDialog open={showAttempt}>
        <MultipleChoiceAssessmentAttempt
          attempt={attempt}
          onFinish={() => {
            setShowAttempt(false);
            setAttempt(undefined);
            navigate(
              `/materia/${subjectId}/simulacros/mc/resultados/${attempt!.id}`,
            );
          }}
          onClose={() => {
            setShowAttempt(false);
            setAttempt(undefined);
          }}
        />
      </BackdropDialog>
    </Page>
  );
}

export { MultipleChoiceCompetitiveAssessmentListPage };
