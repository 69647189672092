import { useParams } from 'react-router-dom';

import { Page } from '../../../components/shared/page/Page';
import { DiagramTable } from '../../../features/diagrams/components/diagram-table/DiagramTable';

function DiagramsPage() {
  const { subjectId } = useParams();

  return (
    <Page title="Esquemas">
      <DiagramTable subjectId={subjectId!} />
    </Page>
  );
}

export { DiagramsPage };
