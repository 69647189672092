import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAxios } from '../../../context/AxiosContext';
import { useToasterActions } from '../../../state/toasterStore';
import {
  ErrorResponse,
  PaginatedRequest,
  PaginatedResponse,
  ValidationErrorResponse,
} from '../../../types/api.types';

export type Status = 'draft' | 'published' | 'removed';

export type AttemptStatus =
  | 'created'
  | 'in_progress'
  | 'paused'
  | 'submitted'
  | 'overdue';

export type Visibility = 'public' | 'private';

export type DifficultyLevel = 'easy' | 'medium' | 'hard';

export interface AuditViewModel {
  username: string;
  action: string;
  description?: string;
  date: Date;
}

export interface ChoiceViewModel {
  id: string;
  text: string;
  isCorrect: boolean;
  metrics: ChoiceMetricsViewModel;
}

export interface TagViewModel {
  id: string;
  name: string;
}

export interface SchoolViewModel {
  id: string;
  name: string;
}

export interface SubjectViewModel {
  id: string;
  name: string;
}

export interface UnitViewModel {
  id: string;
  name: string;
}

export interface LessonViewModel {
  id: string;
  name: string;
}

export interface AssessmentViewModel {
  id: string;
  name: string;
}

export interface MetricsViewModel {
  totalAttempts: number;
  correctPercentage: number;
  incorrectPercentage: number;
  skippedPercentage: number;
}

export interface ChoiceMetricsViewModel {
  selectedCount: number;
  selectedPercentage: number;
}

export interface QuestionPreviewModel {
  id: string;
  text: string;
  status: Status;
  difficultyLevel: DifficultyLevel;
  visibility: Visibility;
  subject: SubjectViewModel;
  unit?: UnitViewModel;
  lesson?: LessonViewModel;
  assessments?: AssessmentViewModel[];
}

export interface MultipleChoiceQuestionViewModel {
  id: string;
  text: string;
  timeLimit: number;
  choices: ChoiceViewModel[];
  maintainsChoiceOrder: boolean;
  status: Status;
  difficultyLevel: DifficultyLevel;
  visibility: Visibility;
  tags: TagViewModel[];
  subject: SubjectViewModel;
  unit?: UnitViewModel;
  lesson?: LessonViewModel;
  explanation?: string;
  imageUrl?: string;
  bookmarked?: boolean;
  missesCount: number;
  auditLog: AuditViewModel[];
  metrics?: MetricsViewModel;
}

export interface CreateChoiceRequest {
  text: string;
  isCorrect?: boolean;
}

export interface CreateMultipleChoiceQuestionRequest {
  subjectId: string;
  text: string;
  timeLimit: number;
  choices: CreateChoiceRequest[];
  visibility: Visibility;
  difficultyLevel: DifficultyLevel;
  tagIds?: string[];
  maintainsChoiceOrder?: boolean;
  unitId?: string;
  lessonId?: string;
  explanation?: string;
  base64Image?: string;
  status?: Status;
}

export interface CreateMultipleChoiceQuestionResponse {
  questionId: string;
}

export const useCreateMultipleChoiceQuestion = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(
    request: CreateMultipleChoiceQuestionRequest,
  ): Promise<CreateMultipleChoiceQuestionResponse> {
    const response = await instance.post<CreateMultipleChoiceQuestionResponse>(
      `/v2/subjects/${request.subjectId}/questions/mc`,
      request,
    );

    return response.data;
  }

  return useMutation<
    CreateMultipleChoiceQuestionResponse,
    AxiosError<ValidationErrorResponse>,
    CreateMultipleChoiceQuestionRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceQuestions'],
      });
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'invalid_operation') {
        sendMessage(
          'Operación inválida',
          e.response?.data.message ?? '',
          'danger',
        );
      }
    },
  });
};

export interface UpdateChoiceRequest {
  id: string;
  text: string;
  isCorrect?: boolean;
}

export interface UpdateMultipleChoiceQuestionRequest {
  id: string;
  subjectId: string;
  text?: string;
  timeLimit?: number;
  choices?: UpdateChoiceRequest[];
  visibility?: Visibility;
  difficultyLevel?: DifficultyLevel;
  tagIds?: string[];
  maintainsChoiceOrder?: boolean;
  unitId?: string;
  lessonId?: string;
  explanation?: string;
  base64Image?: string;
  status?: Status;
}

export const useUpdateMultipleChoiceQuestion = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(request: UpdateMultipleChoiceQuestionRequest) {
    const response = await instance.patch(
      `/v2/subjects/${request.subjectId}/questions/mc/${request.id}`,
      request,
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ValidationErrorResponse>,
    UpdateMultipleChoiceQuestionRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceQuestion'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceQuestions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceMissedQuestions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceBookmarkedQuestions'],
      });
    },
    onError: (e: AxiosError<ValidationErrorResponse>) => {
      if (e.response?.data.code === 'invalid_operation') {
        sendMessage(
          'Operación inválida',
          e.response?.data.message ?? '',
          'danger',
        );
      }
    },
  });
};

export type GetMultipleChoiceQuestionsRequest = PaginatedRequest & {
  subjectId: string;
  params?: {
    text?: string;
    statuses?: Status[];
    visibilities?: Visibility[];
    difficultyLevels?: DifficultyLevel[];
    tagIds?: string[];
    userIds?: string[];
    unitId?: string;
    lessonId?: string;
    explanation?: string;
  };
};

export const useGetMultipleChoiceQuestions = (
  request: GetMultipleChoiceQuestionsRequest,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<PaginatedResponse<QuestionPreviewModel>> {
    const response = await instance.get<
      PaginatedResponse<QuestionPreviewModel>
    >(`/v2/subjects/${request.subjectId}/questions/mc`, {
      params: {
        fromTime: request.fromTime,
        toTime: request.toTime,
        pageNumber: request.pageNumber,
        pageSize: request.pageSize,
        timeSort: request.timeSort,
        order: request.order,
        text: request.params?.text,
        status: request.params?.statuses,
        visibility: request.params?.visibilities,
        difficultyLevel: request.params?.difficultyLevels,
        tagId: request.params?.tagIds,
        userId: request.params?.userIds,
        unitId: request.params?.unitId,
        lessonId: request.params?.lessonId,
        explanation: request.params?.explanation,
      },
      paramsSerializer: {
        indexes: null,
      },
    });

    return response.data;
  }

  return useQuery({
    queryKey: ['getMultipleChoiceQuestions', request],
    queryFn,
  });
};

export type GetMultipleChoiceQuestionRequest = {
  subjectId: string;
  questionId: string;
};

export const useGetMultipleChoiceQuestion = (
  request: GetMultipleChoiceQuestionRequest,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<MultipleChoiceQuestionViewModel> {
    const response = await instance.get<MultipleChoiceQuestionViewModel>(
      `/v2/subjects/${request.subjectId}/questions/mc/${request.questionId}`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: [
      'getMultipleChoiceQuestion',
      request.subjectId,
      request.questionId,
    ],
    queryFn,
  });
};

export type BookmarkMultipleChoiceQuestionRequest = {
  subjectId: string;
  questionId: string;
};

export const useBookmarkMultipleChoiceQuestion = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();

  async function mutationFn(request: BookmarkMultipleChoiceQuestionRequest) {
    const response = await instance.post(
      `/v2/subjects/${request.subjectId}/questions/mc/${request.questionId}/bookmark`,
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    BookmarkMultipleChoiceQuestionRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceQuestion'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceQuestions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceBookmarkedQuestions'],
      });
    },
  });
};

export const useUnbookmarkMultipleChoiceQuestion = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();

  async function mutationFn(request: BookmarkMultipleChoiceQuestionRequest) {
    const response = await instance.delete(
      `/v2/subjects/${request.subjectId}/questions/mc/${request.questionId}/bookmark`,
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    BookmarkMultipleChoiceQuestionRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceQuestion'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceQuestions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceBookmarkedQuestions'],
      });
    },
  });
};

export type CreateMultipleChoiceQuestionErrorReportRequest = {
  subjectId: string;
  questionId: string;
  message: string;
};

export const useCreateMultipleChoiceQuestionErrorReport = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();
  const { sendMessage } = useToasterActions();

  async function mutationFn(
    request: CreateMultipleChoiceQuestionErrorReportRequest,
  ) {
    const response = await instance.post(
      `/v2/subjects/${request.subjectId}/questions/mc/${request.questionId}/report`,
      {
        message: request.message,
      },
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    CreateMultipleChoiceQuestionErrorReportRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getErrorReports'],
      });

      sendMessage('¡Hecho!', 'Error reportado', 'success');
    },
  });
};

export type DeleteMultipleChoiceQuestionRequest = {
  subjectId: string;
  id: string;
};

export const useDeleteMultipleChoiceQuestion = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();

  async function mutationFn(request: DeleteMultipleChoiceQuestionRequest) {
    const response = await instance.delete(
      `/v2/subjects/${request.subjectId}/questions/mc/${request.id}`,
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    DeleteMultipleChoiceQuestionRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceQuestion'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceQuestions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceMissedQuestions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceBookmarkedQuestions'],
      });
    },
  });
};

export type CreateRandomMultipleChoiceQuestionAttemptRequest = {
  subjectId: string;
  numberOfChoices?: number;
  unitIds?: string[];
  lessonIds?: string[];
  difficultyLevels?: DifficultyLevel[];
};

export type CreateMultipleChoiceQuestionAttemptCommandResult = {
  id: string;
  questionId: string;
  text: string;
  timeLimit: number;
  choices: {
    id: string;
    text: string;
  }[];
  imageUrl?: string;
  difficultyLevel: DifficultyLevel;
  subject: SubjectViewModel;
  unit?: UnitViewModel;
  lesson?: LessonViewModel;
  bookmarked?: boolean;
};

export const useCreateRandomMultipleChoiceQuestionAttempt = () => {
  const { instance } = useAxios();
  const { sendMessage } = useToasterActions();

  async function mutationFn(
    request: CreateRandomMultipleChoiceQuestionAttemptRequest,
  ) {
    const response = await instance.post(
      `/v2/subjects/${request.subjectId}/questions/mc/random/attempt`,
      {
        numberOfChoices: request.numberOfChoices,
        unitIds: request.unitIds,
        lessonIds: request.lessonIds,
        difficultyLevels: request.difficultyLevels,
      },
    );

    return response.data;
  }

  return useMutation<
    CreateMultipleChoiceQuestionAttemptCommandResult,
    AxiosError<ErrorResponse>,
    CreateRandomMultipleChoiceQuestionAttemptRequest
  >({
    mutationFn,
    onError: (e: AxiosError<ErrorResponse>) => {
      if (e.response?.data.code === 'not_found') {
        sendMessage(
          '¡Ups!',
          'No se han encontrado preguntas para los temas seleccionados',
          'danger',
        );
      }
    },
  });
};

export type CreateMultipleChoiceQuestionAttemptRequest = {
  subjectId: string;
  questionId: string;
};

export const useCreateMultipleChoiceQuestionAttempt = () => {
  const { instance } = useAxios();

  async function mutationFn(
    request: CreateMultipleChoiceQuestionAttemptRequest,
  ) {
    const response = await instance.post(
      `/v2/subjects/${request.subjectId}/questions/mc/${request.questionId}/attempt`,
    );

    return response.data;
  }

  return useMutation<
    CreateMultipleChoiceQuestionAttemptCommandResult,
    AxiosError<ErrorResponse>,
    CreateMultipleChoiceQuestionAttemptRequest
  >({
    mutationFn,
  });
};

export type SubmitQuestionAttemptRequest = {
  subjectId: string;
  questionAttemptId: string;
  answer: string;
};

export type SubmitQuestionAttemptCommandResult<T> = {
  elapsedTime: number;
  questionAttempt: QuestionAttemptViewModel<T>;
};

export type QuestionAttemptViewModel<T> = {
  id: string;
  question: T;
  answer: string;
  correction: CorrectionViewModel;
};

export type CorrectionViewModel = {
  correctness: number;
  correctAnswer: string;
  feedback?: string;
};

export const useSubmitMultipleChoiceQuestionAttempt = () => {
  const { instance } = useAxios();

  async function mutationFn(request: SubmitQuestionAttemptRequest) {
    const response = await instance.post(
      `/v2/subjects/${request.subjectId}/questions/mc/attempts/${request.questionAttemptId}/submit`,
      {
        answer: request.answer,
      },
    );

    return response.data;
  }

  return useMutation<
    SubmitQuestionAttemptCommandResult<MultipleChoiceQuestionViewModel>,
    AxiosError<ErrorResponse>,
    SubmitQuestionAttemptRequest
  >({
    mutationFn,
  });
};

export type GetMissedQuestionsRequest = {
  subjectId: string;
};

export type MissViewModel = {
  question: QuestionPreviewModel;
  createdAt: Date;
  providedAnswer: string;
  feedback?: string;
};

export const useGetMultipleChoiceMissedQuestions = (
  request: GetMissedQuestionsRequest,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<MissViewModel[]> {
    const response = await instance.get<MissViewModel[]>(
      `/v2/subjects/${request.subjectId}/questions/mc/misses`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: ['getMultipleChoiceMissedQuestions', request.subjectId],
    queryFn,
  });
};

export type RemoveMissedQuestionsRequest = {
  subjectId: string;
};

export const useRemoveMultipleChoiceMissedQuestions = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();

  async function mutationFn(request: RemoveMissedQuestionsRequest) {
    const response = await instance.delete(
      `/v2/subjects/${request.subjectId}/questions/mc/misses`,
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    RemoveMissedQuestionsRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceMissedQuestions'],
      });
    },
  });
};

export type GetBookmarkedQuestionsRequest = {
  subjectId: string;
};

export type BookmarkViewModel = {
  question: QuestionPreviewModel;
  createdAt: Date;
};

export const useGetMultipleChoiceBookmarkedQuestions = (
  request: GetBookmarkedQuestionsRequest,
) => {
  const { instance } = useAxios();

  async function queryFn(): Promise<BookmarkViewModel[]> {
    const response = await instance.get<BookmarkViewModel[]>(
      `/v2/subjects/${request.subjectId}/questions/mc/bookmarks`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: ['getMultipleChoiceBookmarkedQuestions', request.subjectId],
    queryFn,
  });
};

export type RemoveBookmarkedQuestionsRequest = {
  subjectId: string;
};

export const useRemoveMultipleChoiceBookmarkedQuestions = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();

  async function mutationFn(request: RemoveBookmarkedQuestionsRequest) {
    const response = await instance.delete(
      `/v2/subjects/${request.subjectId}/questions/mc/bookmarks`,
    );

    return response.data;
  }

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    RemoveBookmarkedQuestionsRequest
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getMultipleChoiceBookmarkedQuestions'],
      });
    },
  });
};
