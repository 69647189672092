import { Route, Routes } from 'react-router-dom';

import { Layout } from './components/core/layout/Layout';
import { useAppHeight } from './hooks/useAppHeight';
import { AboutPage } from './pages/AboutPage';
import { HomePage } from './pages/HomePage';
import { AssessmentListPage } from './pages/admin/AssessmentListPage';
import { DiagramListPage } from './pages/admin/DiagramListPage';
import { ErrorReportListPage } from './pages/admin/ErrorReportListPage';
import { QuestionListPage } from './pages/admin/QuestionListPage';
import { StatisticsPage } from './pages/statistics/StatisticsPage';
import { MultipleChoiceAssessmentAttemptListPage } from './pages/subject/attempts/assessment-attempt-list/MultipleChoiceAssessmentAttemptListPage';
import { MultipleChoiceAssessmentAttemptResultPage } from './pages/subject/attempts/assessment-attempt-result/MultipleChoiceAssessmentAttemptResultPage';
import { MultipleChoiceBookmarkedQuestionsPage } from './pages/subject/bookmarks/MultipleChoiceBookmarkedQuestionsPage';
import { MultipleChoiceCompetitiveAssessmentListPage } from './pages/subject/competitive-assessments/MultipleChoiceCompetitiveAssessmentListPage';
import { DiagramsPage } from './pages/subject/diagrams/DiagramsPage';
import { MultipleChoiceFormativeAssessmentListPage } from './pages/subject/formative-assessments/MultipleChoiceFormativeAssessmentListPage';
import { MultipleChoiceMissedQuestionsPage } from './pages/subject/misses/MultipleChoiceMissedQuestionsPage';
import { MultipleChoiceOfficialAssessmentListPage } from './pages/subject/official-exams/MultipleChoiceOfficialAssessmentListPage';
import { MultipleChoiceQuestionnairesPage } from './pages/subject/questionnaires/MultipleChoiceQuestionnairesPage';
import { ResourcesPage } from './pages/subject/resources/ResourcesPage';

function App() {
  useAppHeight();

  return (
    <Layout>
      <Routes>
        {/* Index */}
        <Route path="/" element={<HomePage />} />

        {/* Subjects */}
        <Route
          path="/materia/:subjectId/mc"
          element={<MultipleChoiceQuestionnairesPage />}
        />
        <Route path="/materia/:subjectId/esquemas" element={<DiagramsPage />} />
        <Route
          path="/materia/:subjectId/preguntas/mc/guardadas"
          element={<MultipleChoiceBookmarkedQuestionsPage />}
        />
        <Route
          path="/materia/:subjectId/preguntas/mc/falladas"
          element={<MultipleChoiceMissedQuestionsPage />}
        />
        <Route
          path="/materia/:subjectId/simulacros/mc/formativos"
          element={<MultipleChoiceFormativeAssessmentListPage />}
        />
        <Route
          path="/materia/:subjectId/simulacros/mc/competitivos"
          element={<MultipleChoiceCompetitiveAssessmentListPage />}
        />
        <Route
          path="/materia/:subjectId/simulacros/mc/resultados"
          element={<MultipleChoiceAssessmentAttemptListPage />}
        />
        <Route
          path="/materia/:subjectId/simulacros/mc/resultados/:id"
          element={<MultipleChoiceAssessmentAttemptResultPage />}
        />
        <Route
          path="/materia/:subjectId/simulacros/mc/oficiales"
          element={<MultipleChoiceOfficialAssessmentListPage />}
        />
        <Route
          path="/materia/:subjectId/recursos"
          element={<ResourcesPage />}
        />

        {/* Statistics */}
        <Route path="/progreso" element={<StatisticsPage />} />

        {/* Admin */}
        <Route path="/admin/esquemas" element={<DiagramListPage />} />
        <Route path="/admin/preguntas" element={<QuestionListPage />} />
        <Route path="/admin/simulacros" element={<AssessmentListPage />} />
        <Route path="/admin/reportes" element={<ErrorReportListPage />} />

        {/* Other */}
        <Route path="/info" element={<AboutPage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </Layout>
  );
}

export { App };
