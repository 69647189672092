import { useParams } from 'react-router-dom';

import { Page } from '../../../components/shared/page/Page';
import { MultipleChoiceBookmarkedQuestionsList } from '../../../features/questions/components/multiple-choice-bookmarked-questions-list/MultipleChoiceBookmarkedQuestionsList';

function MultipleChoiceBookmarkedQuestionsPage() {
  const { subjectId } = useParams();

  return (
    <Page title="Mis preguntas guardadas">
      <MultipleChoiceBookmarkedQuestionsList subjectId={subjectId!} />
    </Page>
  );
}

export { MultipleChoiceBookmarkedQuestionsPage };
