import { Logo } from '@sealfye/ui-components';

import { Version } from '../components/core/Version';

import styles from './AboutPage.module.scss';

function AboutPage() {
  return (
    <div className={styles['main']}>
      <div className={styles['about']}>
        <Logo className={styles['logo']} variant="company-logo" />
        <p>OpositandoPN es un producto de Sealfye</p>
        <p>
          ¿Necesitas ayuda? Mándanos un mail a{' '}
          <a href="mailto:info@opositandopn.com">info@opositandopn.com</a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.opositandopn.com/terminos-y-condiciones"
          >
            <u>Aviso legal y sus términos y condiciones</u>
          </a>{' '}
          y{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.opositandopn.com/politica-de-privacidad"
          >
            <u>Política de privacidad</u>
          </a>
        </p>
        <span>{new Date().getFullYear()}</span>
        <Version />
      </div>
    </div>
  );
}

export { AboutPage };
