import { useEffect } from 'react';

import classnames from 'classnames';

import { useCountDownTimer } from '../../../../hooks/useCountDownTimer';
import { BaseComponentProps } from '../../../../types/base-component.types';

import styles from './Countdown.module.scss';

export type ContainerProps = BaseComponentProps & {
  seconds: number;
  onTimeout: () => void;
};

function Countdown({
  className,
  seconds,
  onTimeout,
  testId = 'ui-challenge-countdown',
}: ContainerProps) {
  const [, , _s] = useCountDownTimer(0, 0, seconds);

  useEffect(() => {
    if (_s === 0) {
      onTimeout();
    }
  }, [_s]);

  return (
    <div
      className={classnames(styles['main'], className)}
      data-test-id={testId}
    >
      {_s === 0 ? (
        <span className={styles['timer']}>Esperando...</span>
      ) : (
        <span className={styles['timer']}>{_s}</span>
      )}
    </div>
  );
}

export { Countdown };
