import { useState } from 'react';

import {
  Accordion,
  ActionDialogButtons,
  ActionDialogContent,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Dialog,
  DialogContent,
  DialogTrigger,
  IconButton,
  ListSkeleton,
} from '@sealfye/ui-components';
import {
  IoClipboardOutline,
  IoReaderOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { Empty } from '../../../../components/core/Empty';
import { BackdropDialog } from '../../../../components/shared/backdrop/BackdropDialog';
import { ResourceDialog } from '../../../../components/shared/resource-dialog/ResourceDialog';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { formatLongHourDate } from '../../../../utils/date';
import {
  CreateMultipleChoiceAssessmentAttemptCommandResult,
  useCreateMultipleChoiceAssessmentAttemptFromMissedQuestions,
} from '../../../assessments/api/useMultipleChoiceAssessments';
import { MultipleChoiceAssessmentAttempt } from '../../../assessments/components/multiple-choice-assessment-attempt/MultipleChoiceAssessmentAttempt';
import { useGetSubject } from '../../../subjects/api/useSubjects';
import { colorPalette } from '../../../subjects/components/subject-selectable-list/SubjectSelectableList';
import {
  MissViewModel,
  useGetMultipleChoiceMissedQuestions,
  useRemoveMultipleChoiceMissedQuestions,
} from '../../api/useMultipleChoiceQuestions';
import { MultipleChoiceQuestionView } from '../multiple-choice-question-view/MultipleChoiceQuestionView';

import styles from './MultipleChoiceMissedQuestionsList.module.scss';

type ContainerProps = BaseComponentProps & {
  subjectId: string;
};

function MultipleChoiceMissedQuestionsList({
  className,
  subjectId,
  testId = 'ui-multiple-choice-missed-questions-list',
}: ContainerProps) {
  const navigate = useNavigate();
  const [current, setCurrent] = useState<MissViewModel>();
  const { data: response, isLoading } = useGetMultipleChoiceMissedQuestions({
    subjectId: subjectId,
  });

  const { data: subjectResponse, isLoading: isSubjectLoading } = useGetSubject({
    subjectId,
  });

  const [attempt, setAttempt] =
    useState<CreateMultipleChoiceAssessmentAttemptCommandResult>();

  const [numberOfItems, setNumberOfItems] = useState(15);
  const [open, setOpen] = useState(false);

  const { mutate: createMultipleChoiceAssessmentAttempt, isPending } =
    useCreateMultipleChoiceAssessmentAttemptFromMissedQuestions();

  const {
    mutate: removeMultipleChoiceMissedQuestions,
    isPending: isRemovePending,
  } = useRemoveMultipleChoiceMissedQuestions();

  return (
    <>
      {response?.length === 0 ? (
        <Empty title="No tienes preguntas falladas" />
      ) : (
        <>
          <Card
            title="Falladas recientemente"
            className={className}
            testId={testId}
          >
            {isLoading || isSubjectLoading || !response ? (
              <ListSkeleton items={25} />
            ) : (
              <>
                {subjectResponse?.units.map((unit, index) => (
                  <div key={unit.id}>
                    {response.some(
                      (item) => item.question.unit?.id === unit.id,
                    ) && (
                      <>
                        <div className={styles['title']}>{unit.name}</div>
                        <Accordion
                          items={unit.lessons
                            .filter((lesson) =>
                              response.some(
                                (item) =>
                                  item.question.lesson?.id === lesson.id,
                              ),
                            )
                            .map((lesson) => ({
                              label: {
                                value: (
                                  '0' +
                                  (subjectResponse.units
                                    .filter((unit) => unit.index <= index)
                                    .flatMap((unit) => unit.lessons).length +
                                    lesson.index)
                                ).slice(-2),
                                backgroundColor:
                                  colorPalette[index % colorPalette.length],
                              },
                              text: lesson.name,
                              items: response
                                .filter(
                                  (item) =>
                                    item.question.lesson?.id === lesson.id,
                                )
                                .map((item) => ({
                                  text:
                                    item.question.text.length > 120
                                      ? `${item.question.text.substring(0, 120)}…`
                                      : `${item.question.text}`,
                                  info: `Fallada el ${formatLongHourDate(item.createdAt)}`,
                                  tag:
                                    item.question.status === 'draft' ? (
                                      <Badge bg="warning">Borrador</Badge>
                                    ) : item.question.status === 'removed' ? (
                                      <Badge bg="danger">Eliminada</Badge>
                                    ) : undefined,
                                  icon: (
                                    <IconButton
                                      size="sm"
                                      icon={<IoReaderOutline />}
                                      onClick={() => {
                                        setCurrent(item);
                                      }}
                                    />
                                  ),
                                })),
                            }))}
                        />
                      </>
                    )}
                  </div>
                ))}
              </>
            )}
          </Card>
          {!isLoading && response && response?.length > 0 && (
            <div className={styles['attempt']}>
              <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild={true}>
                  <IconButton
                    icon={<IoTrashOutline />}
                    className={styles['trash']}
                    onClick={() => setOpen((v) => !v)}
                    disabled={isRemovePending}
                  />
                </DialogTrigger>
                <DialogContent>
                  <ActionDialogContent title="¿Estás segurx de limpiar todas las preguntas falladas?">
                    <ActionDialogButtons>
                      <Button
                        variant="outline-primary"
                        onClick={() => setOpen((v) => !v)}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          removeMultipleChoiceMissedQuestions(
                            {
                              subjectId: subjectId,
                            },
                            {
                              onSuccess: () => {
                                setOpen((v) => !v);
                              },
                            },
                          );
                        }}
                      >
                        Eliminar
                      </Button>
                    </ActionDialogButtons>
                  </ActionDialogContent>
                </DialogContent>
              </Dialog>
              <div className={styles['new']}>
                <ButtonGroup
                  disabled={isPending}
                  onSelect={(value) => setNumberOfItems(+value)}
                  options={[
                    {
                      value: 15,
                      label: '15',
                      selected: numberOfItems === 15,
                    },
                    {
                      value: 25,
                      label: '25',
                      selected: numberOfItems === 25,
                    },
                    {
                      value: 50,
                      label: '50',
                      selected: numberOfItems === 50,
                    },
                    {
                      value: 100,
                      label: '100',
                      selected: numberOfItems === 100,
                    },
                  ]}
                />
                <Button
                  disabled={isPending}
                  loading={isPending}
                  onClick={() => {
                    createMultipleChoiceAssessmentAttempt(
                      {
                        subjectId: subjectId,
                        numberOfQuestions: numberOfItems,
                      },
                      {
                        onSuccess: (response) => {
                          setAttempt(response);
                        },
                        onError: () => {
                          setAttempt(undefined);
                        },
                      },
                    );
                  }}
                  suffixIcon={<IoClipboardOutline />}
                >
                  Crear simulacro
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      <ResourceDialog open={current !== undefined}>
        {current && (
          <MultipleChoiceQuestionView
            subjectId={subjectId}
            questionId={current.question.id}
            selectedChoiceId={current.providedAnswer}
            onClose={() => {
              setCurrent(undefined);
            }}
          />
        )}
      </ResourceDialog>
      <BackdropDialog open={attempt !== undefined}>
        <MultipleChoiceAssessmentAttempt
          attempt={attempt}
          onFinish={() => {
            setAttempt(undefined);
            navigate(
              `/materia/${subjectId}/simulacros/mc/resultados/${attempt!.id}`,
            );
          }}
          onClose={() => {
            setAttempt(undefined);
          }}
        />
      </BackdropDialog>
    </>
  );
}

export { MultipleChoiceMissedQuestionsList };
