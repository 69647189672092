import { PropsWithChildren, useEffect, useState } from 'react';

import {
  AssessmentPreview,
  AssessmentPreviewType,
  DifficultyLevel,
} from '@sealfye/ui-components';
import {
  FcAcceptDatabase,
  FcAddDatabase,
  FcDataBackup,
  FcDeleteDatabase,
} from 'react-icons/fc';

import { Loading } from '../../../../components/core/Loading';
import { AdminWidthResolution } from '../../../../constants/app';
import { useProfile } from '../../../../context/ProfileContext';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { BaseComponentProps } from '../../../../types/base-component.types';
import { useGetMultipleChoiceAssessment } from '../../api/useMultipleChoiceAssessments';
import { MultipleChoiceAssessmentEditor } from '../multiple-choice-assessment-editor/MultipleChoiceAssessmentEditor';
import { MultipleChoiceAssessmentQuestionsPreviewList } from '../multiple-choice-assessment-questions-preview-list/MultipleChoiceAssessmentQuestionsPreviewList';

export type ContainerProps = BaseComponentProps & {
  subjectId: string;
  assessmentId: string;
  onClose: () => void;
};

function MultipleChoiceAssessmentPreview({
  subjectId,
  assessmentId,
  onClose,
  children,
}: PropsWithChildren<ContainerProps>) {
  const { profile } = useProfile();
  const { isWidthGreaterThan } = useWindowSize();

  const [assessment, setAssessment] = useState<AssessmentPreviewType>();
  const [showEdit, setShowEdit] = useState(false);
  const [showViewQuestions, setShowViewQuestions] = useState(false);

  const {
    data: response,
    isLoading,
    isError,
  } = useGetMultipleChoiceAssessment({
    subjectId,
    assessmentId,
  });

  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError]);

  useEffect(() => {
    if (response) {
      setAssessment({
        id: response.id,
        name: response.name,
        description: response.description,
        timeInSeconds: response.timeLimit,
        numberOfQuestions: response.questions.length,
        cutOffScore: response.cutOffScore,
        official: response.official,
        imageUrl: response.imageUrl,
        tags: response.tags.map((tag) => tag.name),
        difficulty:
          response.difficultyLevel === 'easy'
            ? DifficultyLevel.EASY
            : response.difficultyLevel === 'medium'
              ? DifficultyLevel.MEDIUM
              : DifficultyLevel.HARD,
        auditLog:
          profile.role === 'admin'
            ? response.auditLog.map((log) => ({
                icon:
                  log.action === 'created' ? (
                    <FcAddDatabase />
                  ) : log.action === 'updated' ? (
                    <FcDataBackup />
                  ) : log.action === 'deleted' ? (
                    <FcDeleteDatabase />
                  ) : (
                    <FcAcceptDatabase />
                  ),
                title:
                  log.action === 'created'
                    ? 'Simulacro creado'
                    : log.action === 'updated'
                      ? 'Simulacro actualizado'
                      : log.action === 'deleted'
                        ? 'Simulacro eliminado'
                        : 'Simulacro verificado',

                description: `Por ${log.username}`,
                date: new Date(log.date),
              }))
            : undefined,
      });
    }
  }, [response]);

  return (
    <>
      {isLoading || !assessment ? (
        <Loading />
      ) : (
        <AssessmentPreview
          assessment={assessment}
          onEdit={
            profile.role === 'admin' &&
            response?.status !== 'removed' &&
            isWidthGreaterThan(AdminWidthResolution)
              ? () => {
                  setShowEdit(true);
                }
              : undefined
          }
          onViewQuestions={
            profile.role === 'admin' &&
            response?.status !== 'removed' &&
            isWidthGreaterThan(AdminWidthResolution) &&
            (response?.questions.length ?? 0) > 0
              ? () => {
                  setShowViewQuestions(true);
                }
              : undefined
          }
          onClose={onClose}
        >
          {children}
        </AssessmentPreview>
      )}
      {response && (
        <MultipleChoiceAssessmentEditor
          open={showEdit}
          assessment={response}
          onClose={() => setShowEdit(false)}
        />
      )}
      {response && (
        <MultipleChoiceAssessmentQuestionsPreviewList
          open={showViewQuestions}
          subjectId={subjectId}
          assessmentId={assessmentId}
          questions={response.questions}
          onClose={() => setShowViewQuestions(false)}
        />
      )}
    </>
  );
}

export { MultipleChoiceAssessmentPreview };
