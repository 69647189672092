import { useQuery } from '@tanstack/react-query';

import { useAxios } from '../../../context/AxiosContext';

export interface SubjectsDto {
  subjects: SubjectDto[];
}

export interface SubjectDto {
  id: string;
  name: string;
  units: UnitDto[];
}

export interface UnitDto {
  id: string;
  index: number;
  name: string;
  lessons: LessonDto[];
}

export interface LessonDto {
  id: string;
  index: number;
  name: string;
  outline?: string;
  resources: ResourceDto[];
}

export interface ResourceDto {
  id: string;
  name: string;
  url: string;
}

export const useGetSubjects = () => {
  const { instance } = useAxios();

  async function getSubjects(): Promise<SubjectsDto> {
    const response = await instance.get<SubjectsDto>(`/v2/subjects`);

    return response.data;
  }

  return useQuery({
    queryKey: ['getSubjects'],
    queryFn: getSubjects,
  });
};

export type GetSubjectRequest = {
  subjectId: string;
};

export const useGetSubject = (request: GetSubjectRequest) => {
  const { instance } = useAxios();

  async function getSubject(): Promise<SubjectDto> {
    const response = await instance.get<SubjectDto>(
      `/v2/subjects/${request.subjectId}`,
    );

    return response.data;
  }

  return useQuery({
    queryKey: ['getSubject', request.subjectId],
    queryFn: getSubject,
  });
};
