import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAxios } from '../../../context/AxiosContext';
import { ValidationErrorResponse } from '../../../types/api.types';

export interface CreateTagRequest {
  name: string;
}

export interface CreateTagResponse {
  tagId: string;
}

export interface UpdateTagRequest {
  name: string;
}

export interface TagViewModel {
  id: string;
  name: string;
}

export interface TagsViewModel {
  tags: TagViewModel[];
}

export const useCreateTag = () => {
  const { instance } = useAxios();
  const queryClient = useQueryClient();

  async function createTag(tag: CreateTagRequest): Promise<CreateTagResponse> {
    const response = await instance.post<CreateTagResponse>(`/v2/tags`, tag);

    return response.data;
  }

  return useMutation<
    CreateTagResponse,
    AxiosError<ValidationErrorResponse>,
    CreateTagRequest
  >({
    mutationFn: createTag,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getTags'],
      });
    },
  });
};

export const useGetTags = () => {
  const { instance } = useAxios();

  async function getTags(): Promise<TagsViewModel> {
    const response = await instance.get<TagsViewModel>(`/v2/tags`);

    return response.data;
  }

  return useQuery({
    queryKey: ['getTags'],
    queryFn: getTags,
  });
};
