import { useState } from 'react';

import {
  ActionDialogButtons,
  ActionDialogContent,
  AssessmentPreview,
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  DifficultyLevel,
  SingleDatePicker,
  SwitchCheck,
} from '@sealfye/ui-components';
import { useFormikContext } from 'formik';

import { StepperBody } from '../../../../../components/shared/admin/layout/StepperBody';
import { StepperFooter } from '../../../../../components/shared/admin/layout/StepperFooter';
import { BaseComponentProps } from '../../../../../types/base-component.types';
import { equal } from '../../../../../utils/equality';
import { MultipleChoiceAssessmentFormValues } from '../MultipleChoiceAssessmentEditor';

import styles from './MultipleChoiceAssessmentReviewStep.module.scss';

type ContainerProps = BaseComponentProps & {
  numberOfQuestions: number;
  onCancel: () => void;
};

function MultipleChoiceAssessmentReviewStep({
  numberOfQuestions,
  onCancel,
}: ContainerProps) {
  const { initialValues, values, isSubmitting, handleChange, handleSubmit } =
    useFormikContext<MultipleChoiceAssessmentFormValues>();

  const [open, setOpen] = useState(false);

  return (
    <>
      <StepperBody className={styles['body']}>
        <AssessmentPreview
          className={styles['answer']}
          assessment={{
            id: '',
            name: values.name,
            description: values.description,
            timeInSeconds: values.timeLimit,
            numberOfQuestions: numberOfQuestions,
            official: values.official,
            tags: values.tags.map((tag) => tag.name),
            difficulty: DifficultyLevel.MEDIUM,
            imageUrl: values.base64Image,
          }}
        />
      </StepperBody>
      <StepperFooter className={styles['footer']}>
        <div className={styles['footer__left']}>
          <SingleDatePicker
            initDate={values.releaseDate}
            onChange={(date) => {
              if (date !== values.releaseDate) {
                handleChange({
                  target: { name: 'releaseDate', value: date },
                });
              }
            }}
            locale="es"
          />
          <SwitchCheck
            label="Simulacro abierto a usuarios gratuitos"
            checked={values.visibility === 'public'}
            onChange={() => {
              handleChange({
                target: {
                  name: 'visibility',
                  value: values.visibility === 'public' ? 'private' : 'public',
                },
              });
            }}
          />
        </div>
        <div className={styles['footer__right']}>
          <Button
            type="button"
            variant="outline-danger"
            disabled={isSubmitting}
            onClick={onCancel}
          >
            Cancelar
          </Button>

          {!values.id && (
            <Button
              type="submit"
              variant="outline-primary"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Crear borrador
            </Button>
          )}

          {values.id && values.status === 'draft' && (
            <Button
              type="submit"
              variant="outline-primary"
              loading={isSubmitting}
              disabled={equal(values, initialValues) || isSubmitting}
            >
              Guardar cambios
            </Button>
          )}

          {values.id && values.status === 'draft' && (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger asChild={true}>
                <Button
                  type="button"
                  variant="primary"
                  loading={isSubmitting}
                  onClick={() => {
                    setOpen((v) => !v);
                  }}
                  disabled={numberOfQuestions === 0 || isSubmitting}
                >
                  Publicar
                </Button>
              </DialogTrigger>
              <DialogContent>
                <ActionDialogContent
                  title="¿Estás segurx que deseas publicar el simulacro?"
                  subtitle="Una vez publicado no podrás añadir ni quitar preguntas"
                >
                  <ActionDialogButtons>
                    <Button
                      variant="outline-danger"
                      disabled={isSubmitting}
                      onClick={() => setOpen((v) => !v)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="primary"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => {
                        handleChange({
                          target: {
                            name: 'status',
                            value: 'published',
                          },
                        });
                        setOpen((v) => !v);
                        handleSubmit();
                      }}
                    >
                      Publicar
                    </Button>
                  </ActionDialogButtons>
                </ActionDialogContent>
              </DialogContent>
            </Dialog>
          )}

          {values.id && values.status === 'published' && (
            <Button
              type="submit"
              variant="primary"
              loading={isSubmitting}
              disabled={equal(values, initialValues) || isSubmitting}
            >
              Publicar cambios
            </Button>
          )}
        </div>
      </StepperFooter>
    </>
  );
}

export { MultipleChoiceAssessmentReviewStep };
