import { useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  FormFeedback,
  FormGroup,
  FormLabel,
  Input,
  Loading,
  Ranking,
} from '@sealfye/ui-components';
import classnames from 'classnames';
import {
  Form,
  Formik,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { IoArrowUpCircleOutline } from 'react-icons/io5';
import * as Yup from 'yup';

import { BaseComponentProps } from '../../../../types/base-component.types';
import {
  useAddResponseToAssessmentSurvey,
  useGetAssessmentSurvey,
  useGetAssessmentSurveyResponse,
  useUpdateResponseFromAssessmentSurvey,
} from '../../api/useSurveys';

import styles from './AssessmentSurveyView.module.scss';

export interface AssessmentSurveyFormValues {
  estimatedGrade: number;
}

type ContainerProps = BaseComponentProps & {
  subjectId: string;
  surveyId: string;
  open?: boolean;
  onClose: () => void;
};

function AssessmentSurveyView({
  className,
  subjectId,
  surveyId,
  open,
  onClose,
  testId = 'ui-assessment-survey-view',
}: ContainerProps) {
  const {
    data: assessmentSurvey,
    isLoading: surveyIsLoading,
    isError: surveyIsError,
  } = useGetAssessmentSurvey(
    {
      subjectId,
      surveyId,
    },
    open,
  );

  const { data: response } = useGetAssessmentSurveyResponse(
    {
      subjectId,
      surveyId,
    },
    open,
  );

  const { mutateAsync: addResponse } = useAddResponseToAssessmentSurvey();

  const { mutateAsync: updateResponse } =
    useUpdateResponseFromAssessmentSurvey();

  useEffect(() => {
    if (surveyIsError) {
      onClose();
    }
  }, [surveyIsError]);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className={classnames(styles['main'], className)}
        data-testid={testId}
      >
        {surveyIsLoading || !assessmentSurvey ? (
          <Loading />
        ) : (
          <div className={styles['edit']}>
            <h2 className={styles['heading']}>{assessmentSurvey.name}</h2>
            <div>
              <div className={styles['heading']}>
                <p className={styles['heading__text']}>
                  Nota media estimada:{' '}
                  <strong>{assessmentSurvey.averageEstimatedGrade}</strong>
                </p>
              </div>
              <Ranking
                className={styles['ranking']}
                columns={['Usuario', 'Nota']}
                rows={assessmentSurvey.topResponses?.map((userScore) => {
                  return {
                    cells: [
                      userScore.username === undefined ||
                      userScore.username === '' ||
                      userScore.username === 'unknown'
                        ? 'Desconocido'
                        : userScore.username.length > 20
                          ? userScore.username.substring(0, 20) + '…'
                          : userScore.username,
                      userScore.estimatedGrade.toString(),
                    ],
                  };
                })}
              />
              {response && (
                <h6 className={styles['heading']}>
                  Posición en la encuesta: <strong>{response.position}</strong>{' '}
                  de <strong>{assessmentSurvey.totalResponses}</strong>
                </h6>
              )}
            </div>
            <Formik
              validate={async (values: AssessmentSurveyFormValues) => {
                const errors: FormikErrors<AssessmentSurveyFormValues> = {};

                const validationSchema = Yup.object().shape({
                  estimatedGrade: Yup.number()
                    .min(0, 'La nota no puede ser menor de 0. Ejemplo: 7.5')
                    .max(10, 'La nota no puede ser mayor de 10. Ejemplo: 7.5')
                    .required('La nota es requerida. Ejemplo: 7.5'),
                });

                try {
                  validateYupSchema<AssessmentSurveyFormValues>(
                    values,
                    validationSchema,
                    true,
                  );
                } catch (err) {
                  return yupToFormErrors(err);
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                if (response) {
                  await updateResponse({
                    subjectId,
                    surveyId,
                    responseId: response.id,
                    estimatedGrade: values.estimatedGrade,
                  });
                } else {
                  await addResponse({
                    subjectId,
                    surveyId,
                    estimatedGrade: values.estimatedGrade,
                  });
                }

                setSubmitting(false);
              }}
              initialValues={{
                estimatedGrade: response?.estimatedGrade ?? 0,
              }}
            >
              {({ values, touched, errors, handleChange, isSubmitting }) => (
                <Form className={styles['form']}>
                  <FormGroup>
                    <FormLabel>Nota estimada</FormLabel>
                    <Input
                      id="estimatedGrade"
                      type="number"
                      value={values.estimatedGrade}
                      onChange={handleChange}
                      error={touched.estimatedGrade && !!errors.estimatedGrade}
                    />
                    {touched.estimatedGrade && errors.estimatedGrade && (
                      <FormFeedback variant="danger">
                        {errors.estimatedGrade}
                      </FormFeedback>
                    )}
                    <FormFeedback>
                      Recuerda que esto es una nota estimada basada en tu propio
                      criterio. Intenta ser lo más preciso y sincero posible,
                      con esto ayudas a otros compañeros 💙
                    </FormFeedback>
                  </FormGroup>
                  <Button
                    type="submit"
                    variant="primary"
                    loading={isSubmitting}
                    disabled={
                      response?.estimatedGrade === values.estimatedGrade ||
                      0 === values.estimatedGrade ||
                      isSubmitting
                    }
                    suffixIcon={<IoArrowUpCircleOutline />}
                  >
                    Publicar nota
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export { AssessmentSurveyView };
