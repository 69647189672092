import { useState } from 'react';

import { Page } from '../../components/shared/page/Page';
import {
  ErrorReportListFilter,
  ErrorReportListFilterForm,
} from '../../features/reports/components/error-report-list-filter/ErrorReportListFilter';
import { ErrorReportList } from '../../features/reports/components/error-report-list/ErrorReportList';

import styles from './ErrorReportListPage.module.scss';

function ErrorReportListPage() {
  const [filter, setFilter] = useState<ErrorReportListFilterForm>({
    fromTime: new Date(new Date().setMonth(new Date().getMonth() - 12)),
    toTime: new Date(new Date().setHours(23, 59, 59, 999)),
    timeSort: 'createdAt',
    order: 'desc',
    subject: {
      id: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
      name: '',
    },
    statuses: ['open'],
  });

  return (
    <Page className={styles['page']}>
      <ErrorReportListFilter
        values={filter}
        onSubmit={(values) => {
          setFilter(values);
        }}
        onClear={() => {
          setFilter({
            fromTime: new Date(new Date().setMonth(new Date().getMonth() - 12)),
            toTime: new Date(new Date().setHours(23, 59, 59, 999)),
            timeSort: 'createdAt',
            order: 'desc',
            subject: {
              id: 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737',
              name: '',
            },
            statuses: ['open'],
          });
        }}
      />
      <ErrorReportList filter={filter} />
    </Page>
  );
}

export { ErrorReportListPage };
