import { PropsWithChildren } from 'react';

import { Dialog, DialogContent } from '@sealfye/ui-components';
import classnames from 'classnames';

import { BaseComponentProps } from '../../../types/base-component.types';

import styles from './ResourceDialog.module.scss';

type ContainerProps = BaseComponentProps & {
  open: boolean;
};

function ResourceDialog({
  open,
  children,
  className,
  testId = 'ui-resource-dialog',
}: PropsWithChildren<ContainerProps>) {
  return (
    <Dialog open={open} openAnimation="zoomIn" closeAnimation="zoomOut">
      <DialogContent
        className={classnames(styles['content'], className)}
        testId={testId}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}

export { ResourceDialog };
