import {
  Button,
  DatePicker,
  FormFeedback,
  FormGroup,
  FormLabel,
  FormRow,
  MultiselectDropdown,
  Textarea,
} from '@sealfye/ui-components';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import { IoSearch } from 'react-icons/io5';
import * as Yup from 'yup';

import { BaseComponentProps } from '../../../../types/base-component.types';
import { FormEntity } from '../../../../types/form.types';
import {
  DifficultyLevel,
  Status,
  Visibility,
} from '../../../questions/api/useMultipleChoiceQuestions';
import { SubjectsForm } from '../../../subjects/components/subjects-form/SubjectsForm';
import { TagsForm } from '../../../tags/components/tags-form/TagsForm';
import {
  Methodology,
  Perspective,
  Purpose,
  ReferenceStandard,
  Setting,
} from '../../api/useMultipleChoiceAssessments';

import styles from './AssessmentListFilter.module.scss';

export interface AssessmentListFilterForm {
  fromTime: Date;
  toTime: Date;
  timeSort: string;
  order: 'asc' | 'desc';
  subject: FormEntity;
  text?: string;
  statuses?: Status[];
  visibilities?: Visibility[];
  purposes?: Purpose[];
  referenceStandards?: ReferenceStandard[];
  methodologies?: Methodology[];
  perspectives?: Perspective[];
  settings?: Setting[];
  difficultyLevels?: DifficultyLevel[];
  tags?: FormEntity[];
  questionIds?: string[];
  numberOfChoices?: number;
  official?: boolean;
}

type ContainerProps = BaseComponentProps & {
  values: AssessmentListFilterForm;
  onSubmit: (values: AssessmentListFilterForm) => void;
  onClear: () => void;
};

function AssessmentListFilter({
  className,
  values,
  onSubmit,
  onClear,
  testId = 'ui-assessment-list-filter',
}: ContainerProps) {
  return (
    <Formik
      enableReinitialize
      validationSchema={Yup.object().shape({
        subject: Yup.object().required(),
        text: Yup.string().max(
          2000,
          'El texto no debe superar los 2000 caracteres.',
        ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        onSubmit(values);

        setSubmitting(false);
      }}
      initialValues={{
        fromTime: values.fromTime ?? new Date(),
        toTime: values.toTime ?? new Date(),
        timeSort: values.timeSort ?? 'createdAt',
        order: values.order ?? 'desc',
        subject: values.subject ?? {
          id: '',
          name: '',
        },
        text: values.text ?? undefined,
        statuses: values.statuses ?? undefined,
        visibilities: values.visibilities ?? undefined,
        purposes: values.purposes ?? undefined,
        referenceStandards: values.referenceStandards ?? undefined,
        methodologies: values.methodologies ?? undefined,
        perspectives: values.perspectives ?? undefined,
        settings: values.settings ?? undefined,
        difficultyLevels: values.difficultyLevels ?? undefined,
        tags: values.tags ?? [],
        questionIds: values.questionIds ?? [],
        numberOfChoices: values.numberOfChoices ?? undefined,
        official: values.official ?? undefined,
      }}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit,
      }) => (
        <Form
          className={classnames(styles['form'], className)}
          noValidate
          onSubmit={handleSubmit}
          data-testid={testId}
        >
          <FormRow>
            <FormGroup>
              <FormLabel
                info={
                  <>
                    <p>
                      El contenido de este campo puede ser cualquier texto que
                      pueda contener la pregunta.
                    </p>
                    <p>Ejemplos:</p>
                    <ul>
                      <li>Cualquier campo con valor de tipo texto.</li>
                      <li>
                        Ids (de tipo GUID) que pueda contener la pregunta.
                      </li>
                    </ul>
                    <p>
                      Ten en cuenta que estas búsquedas son sensibles a
                      caracteres especiales como las tildes.
                    </p>
                  </>
                }
              >
                Texto libre
              </FormLabel>
              <Textarea
                placeholder="Escribe cualquier texto que pueda contener el simulacro..."
                id="text"
                value={values.text ?? ''}
                onChange={handleChange}
                rows={2}
                error={touched.text && !!errors.text}
              />
              {touched.text && errors.text && (
                <FormFeedback variant="danger">{errors.text}</FormFeedback>
              )}
              <FormFeedback>
                Texto que puede contener el simulacro.
              </FormFeedback>
            </FormGroup>
            <TagsForm />
            <FormGroup>
              <FormLabel>Fecha de creación</FormLabel>
              <DatePicker
                className={styles['date-picker']}
                startDate={values.fromTime}
                endDate={values.toTime}
                onChange={(fromTime, toTime) => {
                  handleChange({
                    target: {
                      name: 'fromTime',
                      value: fromTime,
                    },
                  });

                  handleChange({
                    target: {
                      name: 'toTime',
                      value: toTime,
                    },
                  });
                }}
                size="lg"
                shortcuts={[
                  'today',
                  'yesterday',
                  'lastSevenDays',
                  'monthToDate',
                  'yearToDate',
                ]}
                locale="es"
                labels={{
                  today: 'Hoy',
                  yesterday: 'Ayer',
                  lastSevenDays: 'Últimos 7 días',
                  monthToDate: 'Mes en curso',
                  monthFromDate: 'Fin de mes en curso',
                  nextMonthFromDate: 'Siguiente mes',
                  yearToDate: 'Año en curso',
                  yearFromDate: 'Fin de año en curso',
                  nextYearFromDate: 'Siguiente año',
                  clear: 'Limpiar',
                  submit: 'Aceptar',
                }}
              />
              <FormFeedback>Fecha en la que se creó el simulacro.</FormFeedback>
            </FormGroup>
          </FormRow>

          <SubjectsForm unitEnabled={false} lessonEnabled={false} />

          <FormRow>
            <FormGroup>
              <FormLabel
                info={
                  <>
                    <p>
                      El estado del simulacro se refiere a la situación actual
                      del simulacro.
                    </p>
                    <p>Tipos:</p>
                    <ul>
                      <li>
                        El estado <strong>borrador</strong> indica que el
                        simulacro aún no ha sido publicado.
                      </li>
                      <li>
                        El estado <strong>publicado</strong> indica que el
                        simulacro ha sido publicado y es visible para los
                        participantes.
                      </li>
                      <li>
                        El estado <strong>eliminado</strong> indica que el
                        simulacro ha sido eliminado y no es visible para los
                        participantes.
                      </li>
                    </ul>
                  </>
                }
              >
                Estado del simulacro
              </FormLabel>
              <MultiselectDropdown
                inputLabel="Selecciona uno o varios..."
                onSelect={(values) => {
                  handleChange({
                    target: {
                      name: 'statuses',
                      value: values,
                    },
                  });
                }}
                options={[
                  {
                    value: 'draft',
                    label: 'Borrador',
                    selected: values.statuses?.includes('draft'),
                  },
                  {
                    value: 'published',
                    label: 'Publicado',
                    selected: values.statuses?.includes('published'),
                  },
                  {
                    value: 'removed',
                    label: 'Eliminado',
                    selected: values.statuses?.includes('removed'),
                  },
                ]}
              />
              <FormFeedback></FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel info="El nivel de dificultad se refiere a la complejidad del simulacro. La dificultad de un simulacro puede ser fácil, media o difícil y se calcula en función de la complejidad de las preguntas">
                Nivel de dificultad
              </FormLabel>
              <MultiselectDropdown
                inputLabel="Selecciona uno o varios..."
                onSelect={(values) => {
                  handleChange({
                    target: {
                      name: 'difficultyLevels',
                      value: values,
                    },
                  });
                }}
                options={[
                  {
                    value: 'easy',
                    label: 'Fácil',
                    selected: values.difficultyLevels?.includes('easy'),
                  },
                  {
                    value: 'medium',
                    label: 'Medio',
                    selected: values.difficultyLevels?.includes('medium'),
                  },
                  {
                    value: 'hard',
                    label: 'Difícil',
                    selected: values.difficultyLevels?.includes('hard'),
                  },
                ]}
              />
              <FormFeedback></FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel
                info={
                  <>
                    <p>
                      La visibilidad del simulacro se refiere a quién puede ver
                      el simulacro.
                    </p>
                    <p>Tipos:</p>
                    <ul>
                      <li>
                        La visibilidad <strong>pública</strong> indica que el
                        simulacro es visible para todos los usuarios, tanto los
                        Premium como los gratuitos.
                      </li>
                      <li>
                        La visibilidad <strong>privada</strong> indica que el
                        simulacro es visible solo para los usuarios Premium.
                      </li>
                    </ul>
                  </>
                }
              >
                Visibilidad del simulacro
              </FormLabel>
              <MultiselectDropdown
                inputLabel="Selecciona uno o varios..."
                onSelect={(values) => {
                  handleChange({
                    target: {
                      name: 'visibilities',
                      value: values,
                    },
                  });
                }}
                options={[
                  {
                    value: 'public',
                    label: 'Pública',
                    selected: values.visibilities?.includes('public'),
                  },
                  {
                    value: 'private',
                    label: 'Privada',
                    selected: values.visibilities?.includes('private'),
                  },
                ]}
              />
              <FormFeedback></FormFeedback>
            </FormGroup>
          </FormRow>

          <FormRow>
            <FormGroup>
              <FormLabel
                info={
                  <>
                    <p>
                      El tipo de evaluación se refiere a la finalidad de la
                      evaluación.
                    </p>
                    <p>Tipos:</p>
                    <ul>
                      <li>
                        La <strong>evaluación formativa</strong> se realiza
                        durante el proceso de aprendizaje, con el propósito de
                        monitorear el progreso y proporcionar retroalimentación
                        continua. (Ej. Simulacros sobre áreas específicas o
                        acotados a un tema)
                      </li>
                      <li>
                        La <strong>evaluación sumativa</strong> se lleva a cabo
                        al final de un proceso de aprendizaje, curso o programa.
                        Su objetivo principal es determinar el nivel de logro o
                        competencia alcanzado por los participantes. Se utiliza
                        para tomar decisiones de calificación, certificación o
                        acreditación. (Ej. Exámenes finales, simulacros
                        competitivos o exámenes oficiales)
                      </li>
                      <li>
                        La <strong>evaluación diagnóstica</strong> se lleva a
                        cabo al comienzo de un proceso de aprendizaje, con el
                        fin de recopilar información sobre el nivel de
                        conocimientos, habilidades y competencias de los
                        participantes.
                      </li>
                    </ul>
                  </>
                }
              >
                Tipo de evaluación
              </FormLabel>
              <MultiselectDropdown
                inputLabel="Selecciona uno o varios..."
                onSelect={(values) => {
                  handleChange({
                    target: {
                      name: 'purposes',
                      value: values,
                    },
                  });
                }}
                options={[
                  {
                    value: 'formative',
                    label: 'Formativa',
                    selected: values.purposes?.includes('formative'),
                  },
                  {
                    value: 'summative',
                    label: 'Sumativa',
                    selected: values.purposes?.includes('summative'),
                  },
                  {
                    value: 'diagnostic',
                    label: 'Diagnóstica',
                    selected: values.purposes?.includes('diagnostic'),
                  },
                ]}
              />
              <FormFeedback></FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel
                info={
                  <>
                    <p>
                      El marco de referencia se refiere a los criterios o normas
                      con los que se compara el desempeño de los participantes.
                    </p>
                    <p>Tipos:</p>
                    <ul>
                      <li>
                        La evaluación <strong>referenciada al criterio</strong>{' '}
                        se basa en los criterios de desempeño establecidos
                        previamente. (Ej. Rubricas)
                      </li>
                      <li>
                        La evaluación <strong>referenciada a la norma</strong>{' '}
                        compara el desempeño de los participantes con el de un
                        grupo de referencia. (Ej. Exámenes estandarizados)
                      </li>
                    </ul>
                  </>
                }
              >
                Marco de referencia
              </FormLabel>
              <MultiselectDropdown
                inputLabel="Selecciona uno o varios..."
                onSelect={(values) => {
                  handleChange({
                    target: {
                      name: 'referenceStandards',
                      value: values,
                    },
                  });
                }}
                options={[
                  {
                    value: 'criterion-referenced',
                    label: 'Referenciada al criterio',
                    selected: values.referenceStandards?.includes(
                      'criterion-referenced',
                    ),
                  },
                  {
                    value: 'norm-referenced',
                    label: 'Referenciada a la norma',
                    selected:
                      values.referenceStandards?.includes('norm-referenced'),
                  },
                ]}
              />
              <FormFeedback></FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel
                info={
                  <>
                    <p>
                      La configuración se refiere a las condiciones en las que
                      se lleva a cabo la evaluación.
                    </p>
                    <p>Tipos:</p>
                    <ul>
                      <li>
                        La evaluación <strong>estandarizada</strong> se realiza
                        en condiciones controladas y uniformes para todos los
                        participantes. (Ej. Exámenes finales, simulacros
                        competitivos o exámenes oficiales)
                      </li>
                      <li>
                        La evaluación <strong>informal</strong> se lleva a cabo
                        en condiciones flexibles y adaptadas a las necesidades
                        de los participantes. (Ej. Simulacros con preguntas
                        aleatorias generadas por el sistema)
                      </li>
                      <li>
                        La evaluación <strong>formal</strong> se realiza en
                        condiciones específicas y con un propósito determinado.
                        (Ej. Simulacros sobre áreas específicas o acotados a un
                        tema)
                      </li>
                    </ul>
                  </>
                }
              >
                Configuración
              </FormLabel>
              <MultiselectDropdown
                inputLabel="Selecciona uno o varios..."
                onSelect={(values) => {
                  handleChange({
                    target: {
                      name: 'settings',
                      value: values,
                    },
                  });
                }}
                options={[
                  {
                    value: 'standardized',
                    label: 'Estandarizada',
                    selected: values.settings?.includes('standardized'),
                  },
                  {
                    value: 'informal',
                    label: 'Informal',
                    selected: values.settings?.includes('informal'),
                  },
                  {
                    value: 'formal',
                    label: 'Formal',
                    selected: values.settings?.includes('formal'),
                  },
                ]}
              />
              <FormFeedback></FormFeedback>
            </FormGroup>
          </FormRow>

          <div className={styles['footer']}>
            <Button
              className={styles['button']}
              variant="outline-primary"
              onClick={onClear}
            >
              Limpiar
            </Button>
            <Button
              className={styles['button']}
              loading={isSubmitting}
              disabled={isSubmitting}
              variant="primary"
              type="submit"
              suffixIcon={<IoSearch />}
            >
              Buscar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export { AssessmentListFilter };
