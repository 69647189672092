import { useEffect, useState } from 'react';

import {
  ActionDialogButtons,
  ActionDialogContent,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTrigger,
  ErrorsType,
  FormFeedback,
  FormGroup,
  FormLabel,
  FormRow,
  FormSection,
  IconButton,
  ImageUpload,
  Input,
  SwitchCheck,
  Textarea,
} from '@sealfye/ui-components';
import { useFormikContext } from 'formik';
import {
  IoAddCircleOutline,
  IoRemoveCircleOutline,
  IoTrashOutline,
} from 'react-icons/io5';

import { StepperBody } from '../../../../../components/shared/admin/layout/StepperBody';
import { StepperFooter } from '../../../../../components/shared/admin/layout/StepperFooter';
import withSwitchButton from '../../../../../components/shared/admin/layout/withSwitchButton';
import { Editor } from '../../../../../components/shared/editor/Editor';
import { BaseComponentProps } from '../../../../../types/base-component.types';
import { SubjectsForm } from '../../../../subjects/components/subjects-form/SubjectsForm';
import { TagsForm } from '../../../../tags/components/tags-form/TagsForm';
import { MultipleChoiceQuestionFormValues } from '../MultipleChoiceQuestionEditor';

import styles from './MultipleChoiceQuestionStep.module.scss';

const alphabet = (index: number) => {
  return String.fromCharCode(65 + index); // Maps 0 to 'A', 1 to 'B', etc.
};

const TextareaWithSelectButton = withSwitchButton(Textarea);

type ContainerProps = BaseComponentProps & {
  onNext: () => void;
  onCancel: () => void;
  onDelete: () => void;
};

function MultipleChoiceQuestionStep({
  onNext,
  onCancel,
  onDelete,
}: ContainerProps) {
  const {
    setTouched,
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    setFieldTouched,
    setErrors,
  } = useFormikContext<MultipleChoiceQuestionFormValues>();

  const [numberOfChoices, setNumberOfChoices] = useState<number>(
    values.choices.length,
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (values.id) {
      setTouched(
        Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
      );
    }
  }, [values]);

  return (
    <>
      <StepperBody>
        <FormSection title={values.id ? 'Editar pregunta' : 'Nueva pregunta'}>
          <SubjectsForm />
          <FormGroup>
            <FormLabel>Enunciado</FormLabel>
            <Textarea
              id="text"
              value={values.text}
              rows={5}
              onChange={handleChange}
              error={touched.text && !!errors.text}
            />
            {touched.text && errors.text && (
              <FormFeedback variant="danger">{errors.text}</FormFeedback>
            )}
          </FormGroup>
          <TagsForm />
          <FormGroup>
            <FormLabel>Imagen asociada</FormLabel>
            <ImageUpload
              dataURL={values.base64Image}
              onChange={(image?: string) => {
                handleChange({
                  target: {
                    name: 'base64Image',
                    value: image ?? '',
                  },
                });
              }}
              onError={(error?: ErrorsType) => {
                setFieldTouched('base64Image', true, false);

                if (error?.maxFileSize) {
                  setErrors({
                    ...errors,
                    base64Image: 'La imagen no puede superar los 5MB',
                  });
                }

                if (error?.maxNumber) {
                  setErrors({
                    ...errors,
                    base64Image: 'No puedes subir más de 1 imagen',
                  });
                }

                if (error?.acceptType) {
                  setErrors({
                    ...errors,
                    base64Image: 'El formato de la imagen no es válido',
                  });
                }

                if (error?.resolution) {
                  setErrors({
                    ...errors,
                    base64Image: 'La resolución de la imagen es muy alta',
                  });
                }
              }}
            />
            {touched.base64Image && errors.base64Image && (
              <FormFeedback variant="danger">{errors.base64Image}</FormFeedback>
            )}
            <FormFeedback>
              La imagen no puede superar los 5MB, y debe estar en un formato
              válido (jpg, jpeg, png).
            </FormFeedback>
          </FormGroup>
        </FormSection>

        <FormSection title="Respuestas">
          {[...Array(numberOfChoices)].map((_, index) => (
            <FormGroup key={index}>
              <FormLabel>Opción {alphabet(index)}</FormLabel>
              <TextareaWithSelectButton
                id={`choices.${index}`}
                value={values.choices[index] ? values.choices[index].text : ''}
                rows={4}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleChange({
                    target: {
                      name: `choices.${index}.text`,
                      value: e.target.value,
                    },
                  });
                }}
                selected={values.choices[index]?.isCorrect || false}
                onSwitch={() => {
                  handleChange({
                    target: {
                      name: `choices.${index}.isCorrect`,
                      value: !values.choices[index].isCorrect,
                    },
                  });
                }}
                error={
                  touched.choices &&
                  Array.isArray(errors.choices) &&
                  errors.choices[index] !== undefined
                }
              />
              {touched.choices &&
                errors.choices &&
                Array.isArray(errors.choices) &&
                errors.choices[index] !== undefined &&
                typeof errors.choices[index] === 'object' && (
                  <FormFeedback variant="danger">
                    {errors.choices[index].text}
                  </FormFeedback>
                )}
            </FormGroup>
          ))}
          {touched.choices &&
            errors.choices &&
            typeof errors.choices === 'string' && (
              <FormFeedback variant="danger">{errors.choices}</FormFeedback>
            )}
          {!values.id && (
            <div className={styles['add-remove-buttons']}>
              <IconButton
                disabled={numberOfChoices >= 6}
                icon={<IoAddCircleOutline />}
                onClick={() => {
                  handleChange({
                    target: {
                      name: `choices`,
                      value: [
                        ...values.choices,
                        {
                          text: '',
                          isCorrect: false,
                        },
                      ],
                    },
                  });
                  setNumberOfChoices(numberOfChoices + 1);
                }}
              />
              <IconButton
                disabled={numberOfChoices <= 3}
                icon={<IoRemoveCircleOutline />}
                onClick={() => {
                  handleChange({
                    target: {
                      name: `choices`,
                      value: values.choices.slice(0, -1),
                    },
                  });
                  setNumberOfChoices(numberOfChoices - 1);
                }}
              />
            </div>
          )}
          <FormGroup>
            <SwitchCheck
              label="Mantener el orden de las opciones"
              checked={values.maintainsChoiceOrder}
              onChange={() => {
                handleChange({
                  target: {
                    name: 'maintainsChoiceOrder',
                    value: !values.maintainsChoiceOrder,
                  },
                });
              }}
            />
            {touched.maintainsChoiceOrder && errors.maintainsChoiceOrder && (
              <FormFeedback variant="danger">
                {errors.maintainsChoiceOrder}
              </FormFeedback>
            )}
          </FormGroup>
        </FormSection>

        <FormSection title="Metadatos">
          <FormGroup>
            <FormLabel>Retroalimentación</FormLabel>
            <Editor
              content={values.explanation}
              onContentChange={(value) => {
                handleChange({
                  target: {
                    name: 'explanation',
                    value,
                  },
                });
              }}
              error={touched.explanation && !!errors.explanation}
            />
            {touched.explanation && errors.explanation && (
              <FormFeedback variant="danger">{errors.explanation}</FormFeedback>
            )}
            <FormFeedback>
              Cada retroalimentación ayuda a entender y memorizar la respuesta,
              siempre que puedas elabora una buena descripción de los
              contenidos.
            </FormFeedback>
          </FormGroup>
          <FormRow>
            <FormGroup>
              <FormLabel>Nivel de dificultad</FormLabel>
              <ButtonGroup
                onSelect={(value) => {
                  handleChange({
                    target: {
                      name: 'difficultyLevel',
                      value,
                    },
                  });
                }}
                error={touched.difficultyLevel && !!errors.difficultyLevel}
                options={[
                  {
                    value: 'easy',
                    label: 'Fácil',
                    selected: values.difficultyLevel === 'easy',
                  },
                  {
                    value: 'medium',
                    label: 'Medio',
                    selected: values.difficultyLevel === 'medium',
                  },
                  {
                    value: 'hard',
                    label: 'Difícil',
                    selected: values.difficultyLevel === 'hard',
                  },
                ]}
              />
              {touched.difficultyLevel && errors.difficultyLevel && (
                <FormFeedback variant="danger">
                  {errors.difficultyLevel}
                </FormFeedback>
              )}
              <FormFeedback>Selecciona el nivel de dificultad.</FormFeedback>
            </FormGroup>

            <FormGroup>
              <FormLabel>Tiempo límite</FormLabel>
              <Input
                id="timeLimit"
                type="number"
                value={values.timeLimit}
                min={1}
                max={299}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: 'timeLimit',
                      value: +e.target.value,
                    },
                  });
                }}
                error={touched.timeLimit && !!errors.timeLimit}
              />
              {touched.timeLimit && errors.timeLimit && (
                <FormFeedback variant="danger">{errors.timeLimit}</FormFeedback>
              )}
              <FormFeedback>
                El tiempo límite en segundos para responder la pregunta.
              </FormFeedback>
            </FormGroup>
          </FormRow>
        </FormSection>
      </StepperBody>

      <StepperFooter className={styles['footer']}>
        <div className={styles['footer__left']}>
          {values.id && (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger asChild={true}>
                <IconButton
                  icon={<IoTrashOutline />}
                  className={styles['trash']}
                  onClick={() => setOpen((v) => !v)}
                  disabled={isSubmitting}
                />
              </DialogTrigger>
              <DialogContent>
                <ActionDialogContent
                  title="¿Estás segurx de que deseas eliminar esta pregunta?"
                  subtitle="Todos los simulacros publicados que contengan esta pregunta pasarán a estado borrador"
                >
                  <ActionDialogButtons>
                    <Button
                      variant="outline-primary"
                      onClick={() => setOpen((v) => !v)}
                    >
                      Cancelar
                    </Button>
                    <Button variant="danger" onClick={onDelete}>
                      Eliminar
                    </Button>
                  </ActionDialogButtons>
                </ActionDialogContent>
              </DialogContent>
            </Dialog>
          )}
        </div>
        <div className={styles['footer__right']}>
          <Button
            type="button"
            variant="outline-danger"
            disabled={isSubmitting}
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            variant="outline-primary"
            disabled={isSubmitting}
            onClick={() => {
              setTouched(
                Object.keys(values).reduce(
                  (acc, key) => ({ ...acc, [key]: true }),
                  {},
                ),
              );

              if (Object.keys(errors).length === 0) {
                onNext();
              }
            }}
          >
            Siguiente
          </Button>
        </div>
      </StepperFooter>
    </>
  );
}

export { MultipleChoiceQuestionStep };
