import { useEffect, useState } from 'react';

import { Button, SwitchCheck } from '@sealfye/ui-components';
import { IoReaderOutline } from 'react-icons/io5';

import TheoryImg from '../../../assets/images/_ce28c5a5-ddce-447a-81e9-8c9613c11517.png';
import { BackdropDialog } from '../../../components/shared/backdrop/BackdropDialog';
import { SectionCard } from '../../../components/shared/section-card/SectionCard';
import {
  CreateMultipleChoiceQuestionAttemptCommandResult,
  CreateRandomMultipleChoiceQuestionAttemptRequest,
  DifficultyLevel,
  useCreateRandomMultipleChoiceQuestionAttempt,
} from '../../../features/questions/api/useMultipleChoiceQuestions';
import { MultipleChoiceQuestionAttempt } from '../../../features/questions/components/multiple-choice-question-attempt/MultipleChoiceQuestionAttempt';
import { SubjectSelectableList } from '../../../features/subjects/components/subject-selectable-list/SubjectSelectableList';
import { BaseComponentProps } from '../../../types/base-component.types';

type ContainerProps = BaseComponentProps & {
  subjectId: string;
};

function MultipleChoiceQuestionAttemptCard({
  className,
  subjectId,
  testId,
}: ContainerProps) {
  const [showLessons, setShowLessons] = useState(false);
  const [countdownActive, setCountdownActive] = useState(false);
  const [showAttempt, setShowAttempt] = useState(false);
  const [attempt, setAttempt] =
    useState<CreateMultipleChoiceQuestionAttemptCommandResult>();
  const [attemptFilter, setAttemptFilter] = useState<{
    subjectId: string;
    numberOfChoices?: number;
    unitIds?: string[];
    lessonIds?: string[];
    difficultyLevels?: DifficultyLevel[];
  }>({
    subjectId: subjectId,
  });

  useEffect(() => {
    setAttemptFilter((prev) => ({
      ...prev,
      subjectId: subjectId,
    }));
  }, [subjectId]);

  const { mutate: createRandomMultipleChoiceQuestionAttempt } =
    useCreateRandomMultipleChoiceQuestionAttempt();

  const createAttempt = (
    request: CreateRandomMultipleChoiceQuestionAttemptRequest,
  ) => {
    createRandomMultipleChoiceQuestionAttempt(request, {
      onSuccess: (response) => {
        setAttempt(response);
      },
      onError: () => {
        setAttempt(undefined);
        setShowAttempt(false);
        setShowLessons(true);
      },
    });
  };

  return (
    <>
      <SectionCard
        title={
          subjectId === 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737'
            ? 'Preguntas de teoría sueltas'
            : subjectId === '63a0be75-65d1-4576-8925-191a546b3d34'
              ? 'Preguntas de psicotécnicos sueltas'
              : subjectId === 'fad6092b-5a08-4542-bc9a-f3a118b99041'
                ? 'Preguntas de inglés sueltas'
                : undefined
        }
        image={TheoryImg}
        button={
          <Button
            variant="outline-primary"
            onClick={() => {
              setShowLessons(true);
            }}
            suffixIcon={<IoReaderOutline />}
          >
            Preguntas sueltas
          </Button>
        }
        className={className}
        testId={testId}
      >
        <p>
          {subjectId === 'cb8a5eb4-4f7c-4cf6-b7fb-073bdfa76737'
            ? 'Responde preguntas sueltas con explicación al responder. Más de 25.000 preguntas disponibles.'
            : subjectId === '63a0be75-65d1-4576-8925-191a546b3d34'
              ? 'Estos test evaluarán tus capacidades y aptitudes intelectuales y profesionales de distinto tipo, como el cálculo mental, la memoria verbal y visual o la visión espacial.'
              : subjectId === 'fad6092b-5a08-4542-bc9a-f3a118b99041'
                ? 'Estos test evaluarán tu nivel de inglés con preguntas específicas sobre comprensión de texto y gramática inglesa.'
                : undefined}
        </p>
        <SwitchCheck
          label="Cronómetro por pregunta"
          checked={countdownActive}
          onChange={() => {
            setCountdownActive((prev) => !prev);
          }}
        />
      </SectionCard>
      <SubjectSelectableList
        open={showLessons}
        subjectId={subjectId}
        onSelect={(ids) => {
          setAttemptFilter((prev) => ({
            ...prev,
            lessonIds: ids.map((id) => id.toString()),
          }));

          setShowLessons(false);
          setShowAttempt(true);
          createAttempt({
            ...attemptFilter,
            lessonIds: ids.map((id) => id.toString()),
          });
        }}
        onClose={() => {
          setShowLessons(false);
        }}
      />
      <BackdropDialog open={showAttempt}>
        <MultipleChoiceQuestionAttempt
          countdown={countdownActive}
          attempt={attempt}
          onNext={() => {
            setAttempt(undefined);
            createAttempt(attemptFilter);
          }}
          onClose={() => {
            setShowAttempt(false);
          }}
        />
      </BackdropDialog>
    </>
  );
}

export { MultipleChoiceQuestionAttemptCard };
